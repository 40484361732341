<template>
  <div class="bar-group flex-box flex-d-center flex-h-ce">
    <div class="title flex-box flex-v-ce">
      <slot name="left-options"></slot>{{ title }}<span class="tips" v-if="tips">{{ tips }}</span>
    </div>
    <div class="right-option flex-box flex-h-ce">
      <slot></slot>
      <!-- <el-button class="reload" size="small" @click="newpay" icon="el-icon-refresh-right">

      </el-button> -->
      <div class="reload flex" @click="newpay">
            <img class="refresh-img" src="../assets/image/refresh.png" alt="">
            <div class="link">刷新页面</div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ 
import { mapState } from "vuex";
export default {
  name: "page-title-bar",
  props: {
    title: String,
    tips: String,
  },
  inject: ["reload"],
  data() {
    return {
      timer: null,
      navList: []
    };
  },
  methods:{
        //局部刷新
    newpay() {
      this.reload();
      this._events.resetForm;
      this.$emit("resetForm", { reform: "SearchForm" });
    },
  }
};
</script>

<style scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;
}

.bar-group {
  /* margin: 0 0 5px; */
}

.title {
  font-size: 20px;
  letter-spacing: 2px;
  margin: 10px 0;
}

.tips {
  font-size: 14px;
  color: #666666;
  margin: 0 0 0 10px;
}

.reload {
  font-size: 26px;
  padding: 2px 4px;
}
.link {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1890FF;
}
.refresh-img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    padding-right: 4px;
}
.flex {
   display: flex;
   align-items: center;
}
</style>
