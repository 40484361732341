<template>
  <div class="page_box">
    <page-title-bar :title="title">
      <el-button
        @click.stop="newDemand"
        type="primary"
        size="small"
        style="margin-right: 10px"
        >新增需求</el-button
      >
    </page-title-bar>
    <div class="bd-bar">
      <el-form
        ref="SearchForm"
        :inline="true"
        :model="form"
        label-width="80px"
        class="search-bar_n"
      >
        <el-tabs v-model="tabStatus" @tab-click="handleClick">
          <el-tab-pane
            :label="`${item.lable} ${item.num}`"
            :name="item.value + ''"
            v-for="(item, index) in cumulativeList"
            :key="index"
          >
          </el-tab-pane>
        </el-tabs>
        <div class="bd-bar2">
          <span class="bd-name">搜索：</span>

          <el-form-item label="" prop="productId">
            <el-select
              v-model="form.productId"
              style="width: 150px"
              placeholder="需求产品"
              size="small"
            >
              <el-option
                v-for="(item, index) in Product"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="intentionlevel">
            <el-select
              v-model="form.intentionlevel"
              style="width: 150px"
              placeholder="紧急程度"
              size="small"
            >
              <el-option
                v-for="(item, index) in intentionlevelList"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="department">
            <el-cascader
              :props="{ checkStrictly: true }"
              v-model="form.department"
              :options="roleid"
              :key="countindex"
              @change="optionlist"
              style="width: 150px"
              size="small"
              :show-all-levels="false"
              placeholder="负责部门"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="" prop="clientName">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.clientName"
              placeholder="客户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="details">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.details"
              placeholder="需求关键字"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="chargeName">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.chargeName"
              placeholder="负责人"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="88px" label="创建时间段" prop="auditDate">
            <el-date-picker
              v-model="form.auditDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 240px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getData(1)"
              >查询</el-button
            >
            <el-button size="small" @click="reset('SearchForm')"
              >重置</el-button
            >
            <el-button size="small" @click="adrentExport(1)">导出</el-button>
          </el-form-item>
        </div>
      </el-form>
      <div class="table_page" v-loading="tabLoading">
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-if="advanceDataList.length > 0"
          @selection-change="handleSelectionChange"
        >
          <ex-table-column
            key="1"
            prop="clientName"
            label="所属客户"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="link fllowup-content" @click="details(scope.row)">
                {{ scope.row.clientName }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="2"
            prop="productId"
            label="需求产品"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ selectDictlabel(Product, scope.row.productId) }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="3"
            prop=""
            label="需求详情"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <!-- <div class="details-text">{{ scope.row.details }}</div> -->
              <el-tooltip placement="top" v-if="scope.row.details.length>13">
                <div slot="content" style="width:600px;">{{scope.row.details}}</div>
                <div class="details-text">{{ scope.row.details }}</div>
              </el-tooltip>
               <div v-else class="details-text">{{ scope.row.details }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="4"
            prop=""
            label="紧急程度"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div>
                {{
                  selectDictlabel(intentionlevelList, scope.row.intentionlevel)
                }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="5"
            prop="contactName"
            label="联系人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.contactName }} {{ scope.row.contactPhone }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="6"
            prop="ownership"
            label="归属"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="7"
            prop="chargeName"
            label="负责人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.departmentName }}-{{ scope.row.chargeName }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="8"
            prop=""
            label="跟进状态"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ selectDictlabel(FollowupStatus, scope.row.followupStatus) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="9"
            prop="order_sum_reward"
            label="最近跟进"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="details-text">
                {{ scope.row.followTime }}
                {{ selectDictlabel(followupMethod, scope.row.followupMethod) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="10"
            prop="order_sum_reward"
            label="最近跟进内容"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="details-text">
                {{ scope.row.followupContent }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="11"
            prop="followupDateStr"
            label="下次跟进日期"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="12"
            prop="remark"
            label="创建人/时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                <div>
                  {{ scope.row.createdName }} {{ scope.row.createTime }}
                </div>
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="13"
            label="操作"
            align="center"
            fixed="right"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div class="flex-box table-fixed f-center">
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="demandDetails(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="followUp(scope.row)"
                  >写跟进</el-button
                >
                <el-dropdown
                  style="padding-left: 10px"
                  trigger="click"
                  @command="MenuMore"
                >
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="beforeHandleCommand('redact', scope.row)"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('transferTohim', scope.row)"
                      >转给他人</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('delete', scope.row)"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </ex-table-column>
        </el-table>
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-else
        >
          <el-table-column
            key="16"
            prop=""
            label="需求产品"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="17"
            prop="title"
            label="需求详情"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="type"
            label="紧急程度"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="reward"
            label="所属客户"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="20"
            label="联系人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="orderNum"
            label="归属"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="22"
            prop="checkNum"
            label="负责人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="23"
            prop="order_sum_reward"
            label="跟进状态"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="24"
            prop="create_id.username"
            label="最近跟进"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="25"
            prop=""
            label="下次跟进日期"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="26"
            prop=""
            label="创建人/时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column key="27" label="操作" align="left" fixed="right">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="this.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 写跟进 -->
    <el-dialog
      class="batch-dialog n3"
      title="写跟进"
      :visible.sync="writeFollowUpDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('batchForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="batchForm"
          :inline="true"
          :model="batchForm"
          :rules="batchRules"
          class="batchForm3"
        >
          <el-form-item label="跟进客户" prop="" label-width="130px">
            <div>{{ commonlist.clientName }}</div>
          </el-form-item>
          <el-form-item
            label="跟进方式"
            prop="followupValue"
            label-width="130px"
          >
            <el-select
              v-model="batchForm.followupValue"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in followupMethod"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="跟进内容"
            prop="followupContent"
            label-width="130px"
          >
            <el-input
              type="textarea"
              :rows="3"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.followupContent"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="跟进状态"
            prop="clueFollowup"
            label-width="130px"
          >
            <el-select
              v-model="batchForm.clueFollowup"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in FollowupStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="下次跟进日期"
            prop="entryDate"
            label-width="130px"
          >
            <el-date-picker
              v-model="batchForm.entryDate"
              align="center"
              placeholder="请选择"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 434px"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 新增需求 -->
    <el-dialog
      :title="applytype == 1 ? '新增需求' : '编辑需求'"
      :visible.sync="newDemandDialog"
      width="624px"
      @close="closeCheck2('demandForms')"
    >
      <div class="dialog-cont">
        <el-form
          ref="demandForms"
          :inline="true"
          :model="demandForms"
          :rules="demandRules"
          class="demandForms"
        >
          <el-form-item label="所属客户" prop="clientId" label-width="100px">
            <el-select
              v-model="demandForms.clientId"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in customerList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="需求产品" prop="productId" label-width="100px">
            <el-select
              v-model="demandForms.productId"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in Product"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人" prop="principal" label-width="100px">
            <el-select
              v-model="demandForms.principal"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="紧急程度"
            prop="intentionlevel"
            label-width="100px"
          >
            <el-select
              v-model="demandForms.intentionlevel"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in intentionlevelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="跟进状态"
            prop="followupStatus"
            label-width="100px"
          >
            <el-select
              v-model="demandForms.followupStatus"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in FollowupStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="需求详情" prop="details" label-width="100px">
            <el-input
              type="textarea"
              :rows="3"
              :maxlength="500"
              style="width: 434px"
              autocomplete="new-password"
              v-model="demandForms.details"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="newDemandDialog = false">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirm2('demandForms')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 需求负责人转移 -->
    <el-dialog
      title="需求负责人转移"
      :visible.sync="demandTransferDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('demandForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="demandForm"
          :inline="true"
          :model="demandForm"
          :rules="demandRules"
          class="demandForm"
        >
          <el-form-item label="新负责人" prop="principal" label-width="100px">
            <el-select
              v-model="demandForm.principal"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="转移说明"
            prop="transferInstruction"
            label-width="100px"
          >
            <el-input
              type="text"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="demandForm.transferInstruction"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel2('demandForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirm('demandForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 需求详情 -->
    <el-dialog
      title="需求详情"
      :visible.sync="dialogDetail"
      :close-on-click-modal="false"
      width="980px"
    >
      <div class="page_box2">
        <div class="glue-details">
          <div class="flex flex-center">
            <div class="company-name">
              需求：{{ selectDictlabel(Product, cluelist.productId) }}
            </div>
            <div>
              <el-dropdown
                style="padding-left: 10px; margin-right: 14px"
                trigger="click"
                @command="MenuMore"
              >
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="beforeHandleCommand('transferTohim', cluelist)"
                    >转给他人</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="beforeHandleCommand('delete', cluelist)"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button size="small" type="primary" @click.stop="compile"
                >编辑</el-button
              >
              <el-select
                v-model="form.followupStatus"
                style="width: 100px; margin-left: 10px"
                placeholder="请选择"
                size="small"
                @change="getFollowup"
              >
                <el-option
                  v-for="(item, index) in FollowupStatus"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="flex flex-center information">
            <div style="margin-right: 8px" class="flex">
              <div class="flex info-list">
                <div>所属客户：</div>
                <div class="link" @click="details(cluelist)">
                  {{ cluelist.clientName }}
                </div>
              </div>
              <div class="flex info-list">
                <div>归属：</div>
                <div>{{ cluelist.ownership }}</div>
              </div>
              <div class="flex info-list">
                <div>负责人：</div>
                <div>
                  {{ cluelist.departmentName }}-{{ cluelist.chargeName }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex info-list">
              <div>紧急程度：</div>
              <div>
                {{
                  selectDictlabel(intentionlevelList, cluelist.intentionlevel)
                }}
              </div>
            </div>
            <div class="flex info-list">
              <div>下次跟进：</div>
              <div>{{ cluelist.followupDateStr }}</div>
            </div>
            <div class="flex info-list">
              <div>最近更新：</div>
              <div>{{ cluelist.updateTime }}</div>
            </div>
          </div>
          <div class="cluelist-details">
            <p style="white-space: pre-line;">{{ detailsText(cluelist.details) }}</p>
          </div>
        </div>
        <div style="padding-bottom: 10px">
          <div class="company-name">跟进动态</div>
        </div>
        <div>
          <div class="label-button">
            <button :class="{ active: number == -1 }" @click="labelBut(-1)">
              全部
            </button>
            <button :class="{ active: number == 2 }" @click="labelBut(2)">
              跟进记录
            </button>
            <button :class="{ active: number == 1 }" @click="labelBut(1)">
              系统动态
            </button>
            <button
              style="float: right"
              class="write-follow-up"
              v-preventReClick="3000"
              @click.stop="followUp(cluelist)"
            >
              写跟进
            </button>
          </div>
        </div>
        <div class="followUp-dynamics" v-if="detalist.length != 0">
          <div v-for="(item, index) in detalist" :key="index">
            <div>
              {{ item.createTime }} {{ item.departmentName }} -
              {{ item.createdName }}
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 1 && item.followAs"
                >【{{ selectDictlabel(FollowAs, item.followAs) }}】</em
              >
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 2 && item.followupMethod"
                >【{{
                  selectDictlabel(followupMethod, item.followupMethod)
                }}】</em
              >
            </div>
            <div v-if="item.followupContent">
              跟进内容：{{ item.followupContent }}
            </div>
            <div v-if="item.followupStatusRecord">
              跟进状态：{{ item.followupStatusRecord }}
            </div>
            <div v-if="item.chargeRecord">负责人：{{ item.chargeRecord }}</div>
          </div>
        </div>
        <el-empty description="没有数据了~" v-else></el-empty>
      </div>
    </el-dialog>
    <!-- 客户详情 -->
    <el-dialog
      title="客户详情"
      :visible.sync="dialogDetail2"
      :close-on-click-modal="false"
      width="980px"
    >
      <div class="page_box2">
        <div class="glue-details">
          <div class="flex flex-center">
            <div class="flex flex-center">
              <div class="company-name">{{ cluelist2.clientName }}</div>
              <div class="company-but" v-if="cluelist2.grade">
                {{ selectDictlabel(Grade, cluelist2.grade) }}类
              </div>
              <div
                class="company-but"
                v-if="cluelist2.category"
                style="margin-left: 10px"
              >
                {{ selectDictlabel(Category, cluelist2.category) }}
              </div>
            </div>

            <!-- <div>
              <el-dropdown
                style="padding-left: 10px; margin-right: 14px"
                trigger="click"
                @command="MenuMore"
              >
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="beforeHandleCommand('transferTohim', cluelist)"
                    >转给他人</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="beforeHandleCommand('delete', cluelist)"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button size="small" @click.stop="compile" type="primary"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click.stop="followUp(cluelist)"
                >写跟进</el-button
              >
            </div> -->
          </div>
          <div class="flex flex-center information">
            <div style="margin-right: 8px">
              <div class="flex info-list">
                <div>来源：</div>
                <div>{{ selectDictlabel(Source, cluelist2.source) }}</div>
              </div>
              <div class="flex info-list">
                <div>需求产品：</div>
                <div>
                  {{ selectCirclation(Product, cluelist2.productDemand) }}
                </div>
              </div>
            </div>
            <div>
              <div class="flex info-list">
                <div>归属：</div>
                <div>{{ cluelist2.ownership }}</div>
              </div>
              <div class="flex info-list">
                <div>下次跟进：</div>
                <div>{{ cluelist2.nextFollowTime }}</div>
              </div>
            </div>
            <div>
              <div class="flex info-list">
                <div>负责人：</div>
                <div>
                  {{ cluelist2.departmentName }}-{{ cluelist2.chargeName }}
                </div>
              </div>
              <div class="flex info-list">
                <div>最近更新：</div>
                <div>{{ cluelist2.updateTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <el-radio-group
            v-model="radio"
            style="margin-bottom: 12px"
            size="small"
          >
            <el-radio-button :label="1">跟进动态</el-radio-button>
            <el-radio-button :label="2">详细</el-radio-button>
          </el-radio-group>
          <div class="label-button" v-if="radio == 1">
            <button :class="{ active: number == -1 }" @click="labelBut2(-1)">
              全部
            </button>
            <button :class="{ active: number == 2 }" @click="labelBut2(2)">
              跟进记录
            </button>
            <button :class="{ active: number == 1 }" @click="labelBut2(1)">
              系统动态
            </button>
            <!-- <button style="float: right" class="write-follow-up">写跟进</button> -->
          </div>
        </div>
        <div
          class="followUp-dynamics"
          v-if="detalist2.length != 0 && radio == 1"
        >
          <div v-for="(item, index) in detalist2" :key="index">
            <div>
              {{ item.createTime }} {{ item.departmentName }} -
              {{ item.createdName }}
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 1 && item.followAs"
                >【{{ selectDictlabel(FollowAs, item.followAs) }}】</em
              >
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 2 && item.followupMethod"
                >【{{
                  selectDictlabel(followupMethod, item.followupMethod)
                }}】</em
              >
            </div>
            <div v-if="item.followupContent">
              跟进内容：{{ item.followupContent }}
            </div>
            <div v-if="item.followupStatusRecord">
              跟进状态：{{ item.followupStatusRecord }}
            </div>
            <div v-if="item.chargeRecord">负责人：{{ item.chargeRecord }}</div>
          </div>
        </div>
        <el-empty
          description="没有数据了~"
          v-if="detalist2.length == 0 && radio == 1"
        ></el-empty>
        <el-descriptions title="基础信息" v-if="radio == 2">
          <el-descriptions-item label="公司名称">{{
            cluelist2.companyName
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人姓名">{{
            cluelist2.contactName
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            cluelist2.contactPhone
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人职务">{{
            cluelist2.contactJob
          }}</el-descriptions-item>
          <el-descriptions-item label="所属行业">{{
            selectDictlabel(Industry, cluelist2.industry)
          }}</el-descriptions-item>
          <el-descriptions-item label="年营收">{{
            cluelist2.annualRevenue
          }}</el-descriptions-item>
          <el-descriptions-item label="所在地区"></el-descriptions-item>
          <el-descriptions-item label="详细地址">{{
            cluelist2.address
          }}</el-descriptions-item>
          <el-descriptions-item label="备注">{{
            cluelist2.remark
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="基础信息" v-if="radio == 2">
          <el-descriptions-item label="领取/分配人">{{
            cluelist2.assignerName
          }}</el-descriptions-item>
          <el-descriptions-item label="领取/分配时间">{{
            cluelist2.assignerDate
          }}</el-descriptions-item>
          <el-descriptions-item label="最近更新人">{{
            cluelist2.updatedName
          }}</el-descriptions-item>
          <el-descriptions-item label="最近更新时间">{{
            cluelist2.updateTime
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import pageTitleBar from "@/components/page-title-bar.vue";
import AddTask from "@/components/AddTask";
import {
  assetsDemandList,
  commonType,
  asstesDemandFollow,
  departmentDepManagerList,
  assetsDemandCharge,
  depClientList,
  assetsDemandDemand,
  editDemandDemand,
  assetsDemandDelete,
  assetsDemandDetails,
  assetsDemandFollow,
  departmentList,
  assetsDemandFollowupStatus,
  clientDetail,
  clientAssetFollow,
} from "@/api/report";
export default {
  inject: ["reload"],
  components: { pageTitleBar, AddTask },
  data() {
    return {
      radio: 1,
      title: "需求",
      tabStatus: "null",
      detalist: [],
      detalist2: [],
      dialogDetail: false,
      dialogDetail2: false,
      page: 1,
      perPage: 10,
      tabLoading: false,
      writeFollowUpDialog: false,
      demandTransferDialog: false,
      newDemandDialog: false,
      count: 0,
      isApply: false,
      loading: false,
      batchForm: {
        followupContent: "",
        followupValue: 1092,
        clueFollowup: "",
        entryDate: "",
      },
      demandForm: {
        principal: "",
        transferInstruction: "",
      },
      demandForms: {
        clientId: "",
        productId: "",
        intentionlevel: "",
        details: "",
        principal: "",
        followupStatus: "",
        Id: "",
      },
      demandRules: {
        clientId: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        principal: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        productId: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        intentionlevel: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        followupStatus: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        details: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
      },
      batchRules: {
        followupValue: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        followupContent: [
          {
            required: true,
            message: "请输入跟进内容",
            trigger: "blur",
          },
        ],
        clueFollowup: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
      statusType: [
        {
          label: "跟进中",
          value: "1",
        },
        {
          label: "报备中",
          value: "2",
        },
        {
          label: "报备无效",
          value: "3",
        },
        {
          label: "已转客户",
          value: "4",
        },
      ],
      form: {
        productId: "",
        intentionlevel: "",
        clientName: "",
        details: "",
        chargeName: "",
        auditDate: [],
        department: "",
        followupStatus: "",
      },
      advanceDataList: [],
      selectionItem: [],
      clueFollowupStatus: "",
      FollowupStatus: "",
      followupMethod: "",
      Grade: "",
      commonlist: "",
      Industry: "",
      Product: "",
      AnnualTax: "",
      FollowAs: "",
      Source: "",
      intentionlevelList: [],
      cumulativeList: [],
      list: [],
      options: [],
      demandId: "",
      customerList: [],
      applytype: 1,
      number: -1,
      cluelist: "",
      cluelist2: "",
      roleid: [],
      countindex: 0,
      Category: "",
      clientId: "",
    };
  },
  mounted() {
    let _this = this;
    _this.getData();
    _this.getCommon();
    _this.getDepartmentList();
    _this.depClient();
    _this.getDeparmentList();
  },
  watch: {
    cluelist(val) {
      console.log(val);
      if (val.id) {
        this.getFollow();
      }
    },
    cluelist2(val) {
      if (val.id) {
        this.getFollow2();
      }
    },
  },
  methods: {
    detailsText(val){
      let str;
      if(val){
         str = val.replace(/,/g , '\n');
      }
       return str;
        // return  val.replace(',' , '\n');
    },
    // 跟进动态
    labelBut2(val) {
      console.log(1);
      this.number = val;
      this.getFollow2();
    },
    // 跟进记录
    async getFollow2() {
      let _this = this;
      // try {
      console.log(_this.cluelist2);
      let params = {
        Id: _this.clientId,
      };
      if (_this.number != -1) {
        params.FollowupType = _this.number;
      }
      // let response = await clientAssetFollow(params);
      // if (response.statusCode == 200) {
      //   let { data } = response.data;
      //   _this.detalist2 = data;
      // }
      // console.log(response);

      _this.$ajax({
        url: _this.globalData.orgHost + "client/asset-follow",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data } = res.data;
            console.log(data);
            _this.detalist2 = data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 客户详情
    async details(row) {
      let _this = this;
      _this.dialogDetail2 = true;
      _this.applytype == 2;
      _this.clientId = row.clientId;
      let params = {
        id: row.clientId,
      };

      _this.$ajax({
        url: _this.globalData.orgHost + "client/client",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.cluelist2 = res.data;
            // console.log(_this.clueDetails);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
      // try {
      //   let response = await clientDetail(params);
      //   if (response.statusCode == 200) {
      //     _this.cluelist2 = response.data;
      //   } else {
      //     _this.$message({
      //       type: "error",
      //       message: response.errors,
      //     });
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    optionlist() {},
    // 获取部门
    async getDeparmentList() {
      let _this = this;
      let params = {
        pageIndex: 1,
        pageSize: 100000,
      };
      _this.loading = true;
      let response = await departmentList(params);
      if (response.statusCode == 200) {
        // _this.newDatalist(response.data);
        let new_data = [];
        for (let item of response.data) {
          item.value = item.id;
          item.label = item.departmentName;
          item.children = item.children;
          if (item.children) {
            for (let it of item.children) {
              it.value = it.id;
              it.label = it.departmentName;
              it.children = it.children;
              if (it.children) {
                for (let qu of it.children) {
                  qu.value = qu.id;
                  qu.label = qu.departmentName;
                  qu.children = "";
                }
              }
            }
          }
          new_data.push(item);
        }
        console.log(new_data, "这是上级部门");
        _this.roleid = new_data;
      } else {
        _this.$message({
          type: "error",
          message: response.errors,
        });
      }
      setTimeout(() => {
        _this.loading = false;
      }, 1000);
    },
    labelBut(val) {
      console.log(1);
      this.number = val;
      this.getFollow();
    },
    cancel2() {
      this.demandTransferDialog = false;
    },
    async confirm(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            id: _this.demandId,
            chargeId: _this.demandForm.principal,
            followupContent: _this.demandForm.transferInstruction,
          };
          try {
            let response = await assetsDemandCharge(params);
            if (response.statusCode == 200) {
              _this.getData();
              _this.$message({
                type: "success",
                message: "转移成功",
              });
              _this.demandTransferDialog = false;
              if (_this.applytype == 2) {
                _this.demandDetails(_this.cluelist);
              }
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 新增/编辑需求
    async confirm2(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          try {
            let params = {
              chargeId: _this.demandForms.principal || "",
              clientId: _this.demandForms.clientId || "",
              productId: _this.demandForms.productId || "",
              intentionlevel: _this.demandForms.intentionlevel || "",
              details: _this.demandForms.details.replace(/\n/g, ',') || "",
              followupStatus: _this.demandForms.followupStatus || "",
            };
            if (_this.applytype == 2 && _this.demandForms.Id) {
              params.id = _this.demandForms.Id;
            }
            console.log();
            _this.isApply = true;
            let response;
            if (_this.applytype == 2) {
              response = await editDemandDemand(params);
            } else {
              response = await assetsDemandDemand(params);
            }
            if (response.statusCode == 200) {
              _this.newDemandDialog = false;
              _this.getData(1);
              _this.$message({
                type: "success",
                message: _this.applytype == 1 ? "新增需求成功" : "编辑需求成功",
              });
              if (_this.applytype == 2 && this.dialogDetail) {
                _this.demandDetails(_this.cluelist);
              }
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
            _this.isApply = false;
          } catch (error) {
            new Error(error);
          }
        }
      });
    },
    beforeHandleCommand(flag, command) {
      return {
        flag: flag,
        command: command,
      };
    },
    // 详情
    particulars(row) {
      this.dialogDetail = true;
    },
    // 获取部门负责人
    async getDepartmentList() {
      let _this = this;
      let params = {};
      try {
        let response = await departmentDepManagerList(params);
        console.log(response.data, "获取");
        if (response.statusCode == 200) {
          _this.list = response.data;
          _this.list.map((item, index) => {
            let obj = {};
            obj.label = item.userName;
            obj.value = item.userId;
            _this.options.push(obj);
          });
          console.log(_this.options);
        }
      } catch (error) {
        new Error(error);
      }
    },
    // 所属客户
    async depClient() {
      let _this = this;
      let params = {
        KeyWord: "",
      };
      try {
        let response = await depClientList(params);
        if (response.statusCode == 200) {
          let list = response.data;
          list.map((item, index) => {
            let obj = {};
            obj.label = item.userName;
            obj.value = item.userId;
            _this.customerList.push(obj);
          });
          console.log(_this.customerList);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取跟进动态 assetsDemandFollow assets-demand/demand-follow
    async getFollow() {
      let _this = this;
      // try {
      console.log(_this.cluelist.id);
      let params = {
        id: _this.cluelist.id,
      };
      if (_this.number != -1) {
        params.FollowupType = _this.number;
      }
      //   let response = await assetsDemandFollow(params);
      //   if (response.statusCode == 200) {
      //     let { data } = response;
      //     _this.detalist = data;
      //   }
      //   console.log(response);
      // } catch (error) {
      //   console.log(error);
      // }
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-demand/demand-follow",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data } = res;
            console.log(data);
            _this.detalist = data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    // 跟进状态快捷编辑
    async getFollowup() {
      let _this = this;
      try {
        let params = {
          id: _this.cluelist.id,
          followupStatus: _this.form.followupStatus,
        };
        let response = await assetsDemandFollowupStatus(params);
        if (response.statusCode == 200) {
          if (_this.applytype) {
            _this.demandDetails(_this.cluelist);
          }
        }
      } catch (error) {}
    },
    // 循环请求产品
    selectCirclation(lab, value) {
      let _this = this;
      let arr = [];
      console.log(value);
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          console.log(item);
          let lable = _this.selectDictlabel(lab, item);
          arr.push(lable);
        });
      }
      return arr.join(",");
    },
    selectDictlabel(lab, value) {
      let _this = this;
      let label = this.ll.selectDictLabel(lab, value);
      return label;
    },
    cancel(form) {
      this.writeFollowUpDialog = false;
    },
    closeCheck(formName) {
      console.log(formName);
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
    },
    closeCheck2(formName) {
      console.log(formName);
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.demandForms = {
        clientId: "",
        productId: "",
        intentionlevel: "",
        details: "",
        principal: "",
        followupStatus: "",
        Id: "",
      };
    },

    // 重置
    reset(formName) {
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.getData(1);
    },
    // 确定跟进记录
    async applyBatch(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            demandId: _this.commonlist.id,
            followupMethod: _this.batchForm.followupValue,
            followupContent: _this.batchForm.followupContent,
            followupStatus: _this.batchForm.clueFollowup,
            followupDate: _this.batchForm.entryDate,
          };
          _this.isApply = true;
          try {
            let response = await asstesDemandFollow(params);
            if (response.statusCode == 200) {
              _this.writeFollowUpDialog = false;
              _this.advanceDataList = [];
              _this.getData();
              _this.$message({
                type: "success",
                message: "写跟进成功",
              });
              if (_this.applytype == 2) {
                _this.demandDetails(_this.cluelist);
              }
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
            _this.isApply = false;
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // // 公共类型
    // async getCommon() {
    //   let _this = this;
    //   let params = {};
    //   try {
    //     let response = await commonType();
    //     console.log(response);
    //     if (response.statusCode == 200) {
    //       let {
    //         FollowupMethod,
    //         ClueFollowupStatus,
    //         Grade,
    //         Industry,
    //         Product,
    //         AnnualTax,
    //         FollowupStatus,
    //         FollowAs,
    //         Intentionlevel,
    //         Source,
    //         Category,
    //       } = response.data;
    //       _this.clueFollowupStatus = ClueFollowupStatus;
    //       _this.followupMethod = FollowupMethod;
    //       _this.Grade = Grade;
    //       _this.Industry = Industry;
    //       _this.Product = Product;
    //       _this.AnnualTax = AnnualTax;
    //       _this.FollowupStatus = FollowupStatus;
    //       _this.FollowAs = FollowAs;
    //       _this.intentionlevelList = Intentionlevel;
    //       _this.Source = Source;
    //       _this.Category = Category;
    //       // console.log(FollowupMethod,ClueFollowupStatus);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async getCommon() {
      let _this = this;
      let params = {};
      _this.$ajax({
        url: _this.globalData.orgCommonHost + "system/common-type",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let {
              FollowupMethod,
              ClueFollowupStatus,
              Grade,
              Industry,
              Product,
              AnnualTax,
              FollowupStatus,
              Source,
              FollowAs,
              Intentionlevel,
              Category,
            } = res.data;
            _this.clueFollowupStatus = ClueFollowupStatus;
            _this.followupMethod = FollowupMethod;
            _this.Grade = Grade;
            _this.Industry = Industry;
            _this.Category = Category;
            _this.Product = Product;
            _this.AnnualTax = AnnualTax;
            _this.FollowupStatus = FollowupStatus;
            _this.Source = Source;
            console.log(_this.Source, "skhgkshdgkhskdjghksdhgk");
            _this.FollowAs = FollowAs;
            _this.intentionlevelList = Intentionlevel;
          }
        },
      });
    },
    // 写跟进
    followUp(row) {
      let _this = this;
      console.log(row);
      _this.commonlist = row;
      _this.batchForm.clueFollowup = row.followupStatus;
      _this.writeFollowUpDialog = true;
    },
    MenuMore(flag) {
      console.log(flag);
      if (flag.flag == "transferTohim") {
        this.demandTransferDialog = true;
        this.demandId = flag.command.id;
      } else if (flag.flag == "redact") {
        this.applytype = 2;
        this.newDemandDialog = true;
        this.demandForms.Id = flag.command.id;
        this.demandForms.principal = flag.command.chargeId;
        this.demandForms.intentionlevel = flag.command.intentionlevel;
        this.demandForms.details = flag.command.details;
        this.demandForms.followupStatus = flag.command.followupStatus;
        this.demandForms.clientId = flag.command.clientId;
        this.demandForms.productId = flag.command.productId;
      } else if (flag.flag == "delete") {
        this.deleteItem(flag.command);
      }
    },
    // 详情编辑
    compile() {
      this.applytype = 2;
      this.newDemandDialog = true;
      this.demandForms.Id = this.cluelist.id;
      this.demandForms.principal = this.cluelist.chargeId;
      this.demandForms.intentionlevel = this.cluelist.intentionlevel;
      this.demandForms.details = this.cluelist.details;
      this.demandForms.followupStatus = this.cluelist.followupStatus;
      this.demandForms.clientId = this.cluelist.clientId;
      this.demandForms.productId = this.cluelist.productId;
    },
    // 删除需求
    deleteItem(row) {
      let _this = this;
      let params = {
        id: row.id,
      };
      this.$confirm("确认删除此需求？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let response = await assetsDemandDelete(params);
          if (response.statusCode == 200) {
            _this.$message({
              type: "success",
              message: "删除成功",
            });
            _this.getData();
            if (_this.applytype == 2) {
              _this.dialogDetail = false;
              _this.demandDetails(_this.cluelist);
            }
          } else {
            _this.$message({
              type: "error",
              message: response.errors,
            });
          }
          console.log(response);
        })
        .catch(() => {});
    },
    handleClick() {
      this.getData(1);
    },
    // 新增需求
    newDemand() {
      let _this = this;
      _this.newDemandDialog = true;
      _this.applytype = 1;
      _this.demandForms.Id = "";
      try {
        let params = {};
      } catch (error) {
        console.log(error);
      }
    },
    // 需求详情
    async demandDetails(row) {
      let _this = this;
      console.log(row);
      this.dialogDetail = true;
      this.applytype == 2;
      let params = {
        Id: row.id,
      };
      // assets-demand/demand
      // let response = await assetsDemandDetails(params);
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-demand/demand",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.cluelist = res.data;
            _this.form.followupStatus = _this.cluelist.followupStatus;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    // 导出
    adrentExport(page, perPage) {
      let _this = this;
      let access_token = localStorage.getItem("Authorization");
      if (page == 1) {
        _this.page = 1;
      }
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      console.log(
        _this.form.department[_this.form.department.length - 1] == undefined
      );

      window.location.href = `${
        this.globalData.orgHost
      }assets-demand/export-assets-demand-list?pageIndex=${index}&pageSize=${size}&ProductId=${
        _this.form.productId
      }&Intentionlevel=${_this.form.intentionlevel}&ClientName=${
        _this.form.clientName
      }&Details=${_this.form.details}&ChargeName=${
        _this.form.chargeName
      }&CreateTimeStart=${
        _this.form.auditDate[0] ? _this.form.auditDate[0] : ""
      }&CreateTimeEnd=${
        _this.form.auditDate[1] ? _this.form.auditDate[1] : ""
      }&DepartmentId=${
        _this.form.department[_this.form.department.length - 1] == undefined
          ? ""
          : _this.form.department[_this.form.department.length - 1]
      }&Authorization=${access_token}&FollowupStatus=${
        _this.tabStatus == "null" ? "" : Number(_this.tabStatus)
      }`;
    },
    // 需求列表数据
    async getData(page, perPage) {
      let _this = this;
      if (page == 1) {
        _this.page = 1;
      }
      // try {
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      let params = {
        pageIndex: index,
        pageSize: size,
        ProductId: _this.form.productId,
        Intentionlevel: _this.form.intentionlevel,
        ClientName: _this.form.clientName,
        Details: _this.form.details,
        ChargeName: _this.form.chargeName,
        CreateTimeStart: _this.form.auditDate[0] || "",
        CreateTimeEnd: _this.form.auditDate[1] || "",
        DepartmentId:
          _this.form.department[_this.form.department.length - 1] || "",
        FollowupStatus:
          _this.tabStatus == "null" ? "" : Number(_this.tabStatus),
      };
      _this.tabLoading = true;
      // assets-demand/list
      // let response = await assetsDemandList(params);
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-demand/list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data, cumulativeData, totalCount } = res.data;
            _this.advanceDataList = data;
            _this.count = totalCount;
            let m;
            _this.cumulativeList = cumulativeData.cumulative;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });

      // } catch (error) {
      //   console.log(error);
      // }
    },
    //每页条数变更
    handleSizeChange: function (val) {
      this.perPage = val;
      this.page = 1;
      this.advanceDataList = [];
      this.getData(this.page, this.perPage);
    },
    //页码变更
    handleCurrentChange: function (val) {
      this.page = val;
      this.advanceDataList = [];
      this.getData(this.page, this.perPage);
    },
    // 多选项目
    handleSelectionChange(val) {
      this.selectionItem = val;
      console.log(val);
    },
  },
};
</script>

<style scoped>
.fllowup-content {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info-list div:first-child {
  width: 85px;
  text-align: left;
}
.info-list div:last-child {
  width: 160px;
}
.company-but {
  margin-left: 20px;
  background: #f9a91e;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  text-align: center;
}
.cluelist-details {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px;
  background: #e7f4ff;
  margin-bottom: 12px;
}
.write-follow-up {
  background: #1890ff !important;
  color: #fff !important;
}
.followUp-dynamics {
  margin-top: 12px;

  width: 884px;
  height: 400px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #f1f1f1;
  overflow-y: auto;
}
.followUp-dynamics > div {
  padding: 16px 24px;
  line-height: 22px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #333333;
  border-bottom: 1px solid #f1f1f1;
}
.label-button button {
  margin-right: 10px;
  width: 70px;
  height: 24px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #666666;
  border: none;
  outline: none;
  background: #f5f5f5;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  cursor: pointer;
}
.label-button .active {
  background: #e5f3ff;
  border-radius: 2px 2px 2px 2px;
  color: #1890ff;
  opacity: 1;
  border: 1px solid #1890ff !important;
}
.info-list > div {
  line-height: 20px;
  padding-bottom: 8px;
}
.info-list div:first-child {
  color: #999999;
}
.information {
  padding: 10px 0;
  width: 75%;
}
.company-name {
  width: 202px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
  line-height: 35px;
}
.flex-center {
  justify-content: space-between;
  align-items: center;
}
.page_box2 {
  padding: 26px 48px !important;
  background: #fff;
}
.el-dropdown-link {
  color: #1890ff;
}

.bd-bar {
  padding: 0 20px;
  background-color: #fff;
}
.dialog-cont {
  padding: 30px 21px;
}
.table_page .page {
  padding-bottom: 10px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.search-bar_n {
  padding: 10px 0;
  overflow: visible !important;
}
.details-text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style>
.details-text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page_box .el-table th.el-table__cell {
  background: #f5f7fa;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-table .cell {
  white-space: nowrap !important;
  width: fit-content !important;
}
.dotted-line {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  margin: 0 auto;
  background: url("../../assets/image/dotted-line.png") no-repeat center;
  background-size: 100%;
  /* margin: -40px 20px 22.5px 2px; */
}
.el-form-item {
  margin-bottom: 10px;
}
.link {
  color: #409eff;
}
</style>