<template>
  <!-- :style="rightStyle" -->
  <div class="tags-nav flex-box">
    <div class="tags-nav flex-box">
      <!--closable这里说明home是不能关闭的-->
      <el-button
        class="turn-btn turn-left"
        size="small"
        icon="el-icon-d-arrow-left"
        @click="handleScroll(260)"
      ></el-button>
      <div class="scroll-outer" ref="scrollOuter">
        <div
          class="scroll-body flex-box"
          ref="scrollBody"
          :style="{ left: tagBodyLeft + 'px' }"
        >
          <el-tag
            :title="item.menuName"
            class="pageTags"
            type="info"
            ref="tagsPageOpened"
            :key="index"
            size="small"
            :data-route-item="item"
            v-for="(item, index) in tags"
            :closable="item.menuName !== 'home'"
            :disable-transitions="false"
            @close="handleClose(item, index)"
            @click.native="changeMenu(item)"
           :effect="item.pcUrl == thatPath ? 'dark' : 'plain'"
          >
          <!--   -->
            <span class="tagName">{{ item.menuName }}</span>
          </el-tag>
        </div>
      </div>
    </div>
     <el-button
        class="turn-btn turn-right"
        size="small"
        icon="el-icon-d-arrow-right"
        @click="handleScroll(-100)"
      ></el-button>
  </div>
</template>

<script>
// eslint-disable-next-line
import { mapState, mapMutations } from "vuex";

export default {
  name: "commonTab",
  props: {
    rightStyle: Object,
    thatPath: String,
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabsList,
    }),
  },
  data() {
    return {
      tagBodyLeft: 0,
      outerPadding: 78,
    };
  },
  watch: {
    $route(to) {
      this.getTagElementByRoute(to);
    },
  },
  mounted() {
    console.log( this.thatPath)
    setTimeout(() => {
      this.getTagElementByRoute(this.$route);
    }, 200);
  },
  methods: {
    ...mapMutations({
      close: "closeTab",
    }),
    //关闭标签
    handleClose(tag, index) {
      console.log("this.tags", this.tags);
      let length = this.tags.length - 1;
      //vuex调方法的另一种写法
      this.close(tag);
      // 如果关闭的标签不是当前路由的话，就不跳转
      if (tag.pcUrl !== this.$route.path) {
        return;
      }

      // 关闭的标签是最右边的话，往左边跳转一个
      if (index === length) {
        this.$router.push({ path: this.tags[index - 1].pcUrl });
      } else {
        // 否则往右边跳转
        this.$router.push({ path: this.tags[index].pcUrl });
      }
    },
    //选择标签跳转路由
    changeMenu(item) {
      //this.$emit('input', item);
      console.log(item);
      if (item.query) {
        this.$router.push({ path: item.pcUrl, query: item.query });
      } else {
        this.$router.push({ path: item.pcUrl });
      }
      this.tags.map((itemC, index) => {
        itemC.active = false;
      });
      item.active = true;
      this.$store.commit("selectMenu", item);
    },
    //点击滚动标签页
    handleScroll(offset) {
      const outerWidth = this.$refs.scrollOuter.offsetWidth;
      const bodyWidth = this.$refs.scrollBody.offsetWidth;
      if (offset > 0) {
        this.tagBodyLeft = Math.min(0, this.tagBodyLeft + offset);
      } else {
        if (outerWidth < bodyWidth) {
          if (this.tagBodyLeft < -(bodyWidth - outerWidth)) {
            this.tagBodyLeft = this.tagBodyLeft;
          } else {
            // this.tagBodyLeft = Math.max(this.tagBodyLeft + offset, outerWidth - bodyWidth)
            this.tagBodyLeft = this.tagBodyLeft + offset;
          }
        } else {
          this.tagBodyLeft = 0;
        }
      }
    },
    moveToView(tag) {
      const outerWidth = this.$refs.scrollOuter.offsetWidth;
      const bodyWidth = this.$refs.scrollBody.offsetWidth;
      if (bodyWidth < outerWidth) {
        this.tagBodyLeft = 0;
      } else if (tag.offsetLeft < -this.tagBodyLeft) {
        // 标签在可视区域左侧
        this.tagBodyLeft = -tag.offsetLeft + this.outerPadding;
      } else if (
        tag.offsetLeft > -this.tagBodyLeft &&
        tag.offsetLeft + tag.offsetWidth < -this.tagBodyLeft + outerWidth
      ) {
        // 标签在可视区域
        this.tagBodyLeft = Math.min(
          0,
          outerWidth - tag.offsetWidth - tag.offsetLeft - this.outerPadding
        );
      } else {
        // 标签在可视区域右侧
        this.tagBodyLeft = -(
          tag.offsetLeft -
          (outerWidth - this.outerPadding - tag.offsetWidth)
        );
      }
    },
    getTagElementByRoute(route) {
      let that = this;
      this.$nextTick(() => {
        this.refsTag = this.$refs.tagsPageOpened || [];
        this.refsTag.forEach((item, index) => {
          if (this.routeEqual(route, item.$attrs["data-route-item"])) {
            let tag = this.refsTag[index].$el;
            this.moveToView(tag);
          }
        });
      });
    },
    /**
     * @description 根据name/params/query判断两个路由对象是否相等
     * @param {*} route1 路由对象
     * @param {*} route2 路由对象
     */
    routeEqual(route1, route2) {
      let that = this;
      const params1 = route1.params || {};
      const params2 = route2.params || {};
      const query1 = route1.query || {};
      const query2 = route2.query || {};
      // return (route1.name === route2.name) && that.objEqual(params1, params2) && that.objEqual(query1, query2);
      return route1.name === route2.name;
    },
    /**
     * @param {*} obj1 对象
     * @param {*} obj2 对象
     * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
     */
    objEqual(obj1, obj2) {
      const keysArr1 = Object.keys(obj1);
      const keysArr2 = Object.keys(obj2);
      if (keysArr1.length !== keysArr2.length) return false;
      else if (keysArr1.length === 0 && keysArr2.length === 0) return true;
      /* eslint-disable-next-line */ else
        return !keysArr1.some((key) => obj1[key] != obj2[key]);
    },
    //获取url参数值
    GetQueryValue(queryName) {
      var query = decodeURI(window.location.search.substring(1));
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == queryName) {
          return pair[1];
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
.tags-nav {
  /* position: fixed;
    top: 57px; */
  width: 100%;
  height: 40px;
  background: #ededed;
  -webkit-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;
  z-index: 12;
  overflow: hidden;
}
.tags-nav .pageTags {
  width: 110px;
  padding: 11px 10px;
  height: auto;
  text-align: center;
  line-height: 1;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
  border-radius: 0;
  border: none;
  border-right: 1px solid #eee;
}
.pageTags .tagName {
  display: inline-block;
  width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tags-nav .pageTags .el-tag--plain .el-icon-close {
  transform: scale(1.2) !important;
  right: -12px;
}
.tags-nav .pageTags .el-tag--plain .el-tag__close {
  color: #666666;
}
.tags-nav .pageTags.el-tag--dark {
  color: #707273;
  border-top: 2px solid #1890ff;
  background-color: #e0ecf8;
}

.turn-btn {
  /* position: absolute;
    top: 0; */
  width: 40px;
  height: 40px;
  z-index: 11;
}
.turn-left {
  left: 0;
}
.turn-right {
  right: -6px;
}

.tags-nav .scroll-outer {
  /* position: absolute; */
  left: 8.2%;
  right: 34px;
  top: 0;
  bottom: 0;
}

.tags-nav .scroll-outer .scroll-body {
  /* height: calc(100% - 1px); */
  display: inline-block;
  /* padding: 0 4px 0 0; */
  /* position: absolute; */
  overflow: visible;
  white-space: nowrap;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
}
</style>
<style>
.tabs .pageTags.el-tag--dark.el-tag--info .el-tag__close {
  color: #9c9d9d;
}
.tabs .pageTags.el-tag--dark.el-tag--info .el-tag__close:hover {
  color: #fff;
}
</style>
