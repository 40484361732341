<template>
  <div class="page_box">
    <div class="page2 flex-box flex-center-center">
      <img src="../assets/image/left.png" class="leftImg" />
      <img src="../assets/image/shouyet.png" class="contenImg" />
      <img src="../assets/image/right.png" class="rightImg" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */ 
export default {};
</script>

<style scoped>
 .contenImg{
    width: 46rem;
  }
  .leftImg{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 500px;
    border-radius:8px 0px 0px 0px;
  }
  .rightImg{
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 500px;
    border-radius:0px 0px 8px 0px;
  }
  .page2{
    height: 730px;
    position: relative;
    /*box-sizing: border-box;*/
    /*border-radius: 8px;*/
    /*left: 220px;*/
    /*right: 20px;*/
    /*bottom: 40px;*/
    /*top: 126px;*/
    background-color: #fff;
    text-align: center;
    min-width: 900px;
  }
  .page{
    margin: 20px;
    color:#333;
    position: relative;
  }
  .con_a{
    overflow: hidden;
    padding:15px;
    background: #fff;
    border-radius: 5px;
  }
  .con_a_item{
    float:left;
    width:16.66%;
    overflow: hidden;
  }
  .con_a_item>div{
    float:left;
  }
  .con_a_item>ul{
    float:left;
    padding:5px 0 0 15px;
  }
  .con_a_item>ul li:nth-child(1){
      color:#999;
      font-size:12px;
  }
  .con_a_item>ul li:nth-child(2) span{
    font-weight: bold;
    font-size:25px;
  }


  .con_b{
      height:320px;
      margin-top:20px;
  }

  .con_b_left{
    float:left;
    width:33.5%;
    height:100%;
  }
  .con_b_left_t{
    background: #fff;
    overflow: hidden;
    height:90px;
    padding:60px 0 85px 0;
    padding-left:30px;
  }
  .con_b_left_t>div{
    float:left;
    width:90px;
    height:90px;
    border:1px solid #EFEDEE;
    border-radius: 50%;
    overflow: hidden;
  }
  .con_b_left_t>div img{
    width:100%;
    height:100%;
  }
  .con_b_left_t>ul{
    float:left;
    padding-top:5px;
    padding-left:15px;
  }
  .con_b_left_t>ul li:nth-child(1){
    width:150px;
    font-size:18px;
    font-weight: bold;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .con_b_left_t>ul li:nth-child(2){
    color:#666;
    margin-top:10px;
  }
  .con_b_left_t>ul li:nth-child(2) span{
    margin-right:15px;
  }
  .con_b_left_t>ul li:nth-child(3){
      margin-top:10px;
  }
  .con_b_left_t>ul li:nth-child(3) span{
    display: block;
    text-align: center;
    width:68px;
    height:20px;
    background:rgba(255,212,3,1);
    border-radius:10px;
  }


  .con_b_left_b{
    height:65px;
    line-height: 65px;
    background: #fff;
    margin-top:20px;
    border-left:3px solid #FF730F;
    border-radius: 5px;
    overflow: hidden;
    /*padding-left:100px;*/
  }
  .con_b_left_b>p{
    float:left;
    color:#FF730F;
    font-weight: bold;
    font-size:18px;
    padding-left:15px;
  }
.con_b_left_b_c{
  /*float:left;*/
  padding-right:10px;
  padding-left:100px;
}
  /* .con_b_left_b_c ul{
   animation: moveTop 5s linear infinite;
  } */
  .con_b_left_b_c ul li{
    overflow: hidden;
  }
  .con_b_left_b_c ul li span {
    display: inline-block;
    float:left;
  }
  .con_b_left_b_c ul li span:nth-child(1){
    width:50%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    /*overflow: hidden;*/
  }
.con_b_left_b_c ul li span:nth-child(3){
  float:right;
  color:#4F93FE;
  cursor: pointer;
}

@keyframes moveTop {
  0%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,-100%);
  }
}

    .con_b_right_tit{
      padding:15px;
    }
  .con_b_right_tit>span{
    display: block;
    float:left;
    border-left:3px solid #FFCD00;
    height:16px;
    line-height: 16px;
    padding-left:10px;
  }
    .con_b_right_tit>p{
      float:right;
    }
  .con_b_right_tit>p span{
    margin-left:25px;
  }
  .con_b_right_tit>p span i{
    display: inline-block;
    width:10px;
    height:10px;
    background: #FFCD00;
  }
  .con_b_right{
    float:right;
    width:65%;
    height:100%;
    background: #fff;
  }

  /* .con_b_right_tit{

  } */


  .con_c{
    margin-top:20px;
    overflow: hidden;
  }
  .con_c_left{
    float:left;
    width:49.2%;
    background: #fff;
  }
  .con_c_left:nth-child(2){
    margin-left:1.6%;
  }
  .con_c_tit{
      padding:15px;
      overflow: hidden;
  }

  .con_c_tit>p{
    float:left;
    border-left:3px solid #FFCD00;
    height:16px;
    line-height: 16px;
    padding-left:5px;
  }
  .con_c_tit>span{
    float:right;
    color:#4F93FE;
    cursor: pointer;
  }

  .con_c_content{
      /*padding:0 15px;*/
      height:300px;
      overflow-y: hidden;
  }

  .con_c_content table{
    width:100%;
  }
  .con_c_content table thead{
      color:#999;
    line-height: 40px;
  }
  .con_c_content table tr td:nth-child(1){
    padding-left:15px;
  }
  .con_c_content table tbody tr{
    line-height: 40px;

  }
  .con_c_content table tbody tr td:last-child{
    color:#4F93FE;
    cursor: pointer;
  }
  .con_c_content table tbody tr:nth-of-type(odd){
    background: #F7FAFF;
  }
</style>