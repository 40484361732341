 <template>
  <label class="select-tree" @click="showTree = true">
    <el-input size="small" v-model="inputVal" style="width:100%" placeholder="请下拉选择树形结构数据"/>
    <el-collapse-transition>
      <div class="tree-modal" v-show="showTree" >
        <!-- show-checkbox -->
        <el-tree :data="filterList" @node-click="nodeClick" ref="selectTreeData" node-key="id"/>
        <div class="popper-arrow" style="left: 35px;"></div>
      </div>
    </el-collapse-transition>
  </label>
</template>
 
<script>
  export default {
    name: "test",
    data() {
      return {
        inputVal:"",
        showTree:false,
        filterList: [{
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      };
    },
    methods:{
        nodeClick(lab){
              console.log(lab);
        }
    }
  }
</script>
 
<style >
  .select-tree {
    flex: 1;
    /* margin-right: 10px;
    max-width: 300px;
    height: 30px; */
    background: #FFFFFF;
    /* line-height: 30px; */
  }
    .el-input__inner{
      height: 30px;
      border-radius: 1px;
      line-height: 30px;
    }
    .tree-modal{
      position: absolute;
      max-width: 300px;
      overflow:auto;
      z-index: 888;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
      margin-top: 10px;
    }
      .el-tree {
        width: auto;
        max-height: 300px;
        z-index: 999;
        padding-right: 10px;
        padding-bottom: 10px;
        overflow: auto;
        min-width: 200px;
        max-width: inherit;
        margin-top: 5px;
      }
 
      .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
        background-color: rgba(87, 163, 243, 0.8);
        color: #FFFFFF;
      }
      .popper-arrow {
        top: 28px;
        margin-right: 3px;
        border-top-width: 0;
        position: absolute;
        border-width: 6px;
        border-color: #FFFFFF;
        animation: mymove 2s;
        opacity: 1
      }
      @keyframes mymove {
        0% {
          opacity: 0
        }
        100% {
          opacity: 1
        }
      }
      .popper-arrow::after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        top: 1px;
        margin-left: -6px;
        border-top-width: 0;
        border-bottom-color: #FFFFFF;
        border-width: 6px;
      }
</style>