import Vue from 'vue';
import { orgGwy, put, tkExport, post, Delete,orgQyw } from '@/utils/api';

/**
 * @param {Object} data
 * @returns 
 */
/**
 * 
 * @param {Object} params 
 * @returns 
 */
// 用户登录
export function getLogin(data) {
      return post(
            'login/login-submit',
            data)
}
// 获取公共类型 
export function commonType(params) {
      return orgQyw({
            url: 'system/common-type',
            method: 'get',
            params
      })
}
// 获取公共省市区
export function commonProvices(params) {
      return orgQyw({
            url: 'system/provices',
            method: 'post',
            params
      })
}
// 菜单列表
export function permissionList(params) {
      return orgGwy({
            url: 'permission/list',
            method: 'get',
            params
      })
}
// 下载错误结果
 export function exportAssetsClueVerification(params) {
      return orgGwy({
            url: 'assets-clue/export-assets-clue-verification',
            method: 'get',
            params
      })
} 
// 负责人列表 
export function assetsClueDepManagerList(params) {
      return orgGwy({
            url: 'assets-clue/dep-manager-list',
            method: 'get',
            params
      })
} 
// 批量分配线索数据
export function assetsClueBatchllocation(data) {
      return orgGwy({
            url: 'assets-clue/batchallocation',
            method: 'post',
            data
      })
}
// 导入线索数据 
export function exportAssetsClue(data) {
      return orgGwy({
            url: 'assets-clue/export-assets-clue',
            method: 'post',
            data
      })
}
// 新增菜单
export function addPermission(data) {
      return orgGwy({
            url: 'permission/permission',
            method: 'post',
            data
      })
}
// 编辑菜单
export function editPermission(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'permission/permission',
            data)
}
// 编辑角色
export function editRole(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'role/role',
            data
      )
}
// 新增部门
export function addDepartment(data) {
      return orgGwy({
            url: 'department/department',
            method: 'post',
            data
      })
}
// 新增客户 
export function addClientClient(data) {
      return orgGwy({
            url: 'client/client',
            method: 'post',
            data
      })
}
// 线索写跟进
export function assetsClueFollow(data){
      return orgGwy({
            url: 'assets-clue/follow',
            method: 'post',
            data
      })
}
// 需求写跟进
export function asstesDemandFollow(data){
      return orgGwy({
            url: 'assets-demand/follow',
            method: 'post',
            data
      })  
}
// 客户写跟进 
export function clientFollow(data){
      return orgGwy({
            url: 'client/follow',
            method: 'post',
            data
      })  
}
// 已转客户
export function clueClientList(params) {
      return orgGwy({
            url: 'assets-clue/clue-client-list',
            method: 'get',
            params
      })
}
// 客户负责人转移 
export function clientCharge(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'client/charge',
            data
      )
}
// 线索转客户 assets-clue/clue-client
export function clueClient(data){
      return orgGwy({
             url:"assets-clue/clue-client",
             method: 'post',
             data
      })
}
// 客户列表
export function clientList(params){
      return orgGwy({
             url:"client/list",
             method: 'get',
             params
      })
}

// 需求列表
export function assetsDemandList(params){
      return orgGwy({
             url:"assets-demand/list",
             method: 'get',
             params
      })
}
// 获取菜单
export function adminUserMenu(params) {
      return orgGwy({
            url: 'admin/user-menu',
            method: 'get',
            params
      })
}
// 获取角色权限
export function rolePermission(params) {
      return orgGwy({
            url: 'role/role-permission',
            method: 'get',
            params
      })
}
// 部门负责人
export function departmentDepManagerList(params) {
      return orgGwy({
            url: 'department/dep-manager-list',
            method: 'get',
            params
      })
}
// 获取附件列表
export function clientAnnexList(params) {
      return orgGwy({
            url: 'client/annex-list',
            method: 'get',
            params
      })
}
// 删除菜单
export function munetDelete(data) {
      return Delete(
            Vue.prototype.globalData.orgHost,
            'permission/permission',
            data)
}
// 删除客户 
export function clientDelete(data) {
      return Delete(
            Vue.prototype.globalData.orgHost,
            'client/client',
            data)
}
// 删除附件 
export function clientAnnexDelete(data) {
      return Delete(
            Vue.prototype.globalData.orgHost,
            'client/annex',
            data)
}
// 更新客户
export function clientEdit(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'client/client',
            data
      )
}

// 获取部门列表
export function departmentList(params) {
      return orgGwy({
            url: 'department/list',
            method: 'get',
            params
      })
}
// 跟进记录列表
export function clientAssetFollow(params) {
      return orgGwy({
            url: 'client/asset-follow',
            method: 'get',
            params
      })
}
// 自动分配设置 
export function  assetsClueSetting(data) {
      return orgGwy({
            url: 'assets-clue/set-assets-clue-setting',
            method: 'post',
            data
      })
}
// 获取自动分配设置
export function assetsClueSettingList(params) {
      return orgGwy({
            url: 'assets-clue/assets-clue-setting',
            method: 'get',
            params
      })
}
// 禁用/启用
export function  managementDisableManagement(data) {
      return orgGwy({
            url: 'management/disable-management',
            method: 'post',
            data
      })
}
// 更新部门
export function departmentEdit(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'department/department',
            data
      )
}

// 删除部门
export function departmentDelete(data) {
      return Delete(
            Vue.prototype.globalData.orgHost,
            'department/department',
            data)
}
// 权限角色下拉列表 management/role-select-list
export function roleSelectList(params) {
      return orgGwy({
            url: 'management/role-select-list',
            method: 'get',
            params
      })
}
// 获取角色列表
export function roleList(params) {
      return orgGwy({
            url: 'role/list',
            method: 'get',
            params
      })
}
// 新增角色
export function newRole(data) {
      return orgGwy({
            url: 'role/role',
            method: 'post',
            data
      })
}
// 删除角色
export function roleDelete(data) {
      return Delete(
            Vue.prototype.globalData.orgHost,
            'role/role',
            data)
}
// 编辑角色权限
export function editrolePermission(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'role/role-permission',
            data)
}
// 获取成员管理列表
export function managementList(params) {
      return orgGwy({
            url: 'management/list',
            method: 'get',
            params
      })
}
// 新增成员
export function newManagement(data) {
      return orgGwy({
            url: 'management/management',
            method: 'post',
            data
      })
}
// 更新成员
export function editManagement(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'management/management',
            data
      )
}
// 删除成员
export function managementDelete(data) {
      return Delete(
            Vue.prototype.globalData.orgHost,
            'management/management',
            data)
}
// 线索列表
export function assetsClueList(params) {
      return orgGwy({
            url: 'assets-clue/list',
            method: 'get',
            params
      })
}
// 线索详情 
export function assetsClueassetsClue(params) {
      return orgGwy({
            url: 'assets-clue/assets-clue',
            method: 'get',
            params
      })
}
// 退回线索池 
export function assetsClueReturn(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'assets-clue/clue-return',
            data)
}
// 跟进动态 
export function assetsClueAssetClueFollow(params) {
       return orgGwy({
              url: 'assets-clue/asset-clue-follow',
              method: 'get',
              params
       })
}
// 需求跟进记录
export function assetsDemandFollow(params) {
      return orgGwy({
             url: 'assets-demand/demand-follow',
             method: 'get',
             params
      })
}
// 线索列表导出 
export function assetsClueExportCluelist(params) {
      return orgGwy({
             url: 'assets-clue/export-assets-clue-list',
             method: 'get',
             params
      })
}
// 公共类型 

// 线索分配列表 
export function importAssetsClueList(params){
      return orgGwy({
            url: 'assets-clue/import-assets-clue-list',
            method: 'get',
            params
      })
}
// 线索详情
export function asstesClueAssetsClue(params){
      return orgGwy({
            url: 'assets-clue/assets-clue',
            method: 'get',
            params
      })
}
// 线索转移 
export function assetsClueCharge(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'assets-clue/charge',
            data)
}
// 编辑线索 assets-clue/assets-clue
export function editAssetsClue(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'assets-clue/assets-clue',
            data)
}
// 修改密码 admin/admin/password clientForm.industry
export function editPassword(data) {
      return orgGwy({
            url: 'login/change-password',
            method: 'post',
            data
      })
}
// 所属客户 assets-demand/dep-client-list
export function depClientList(params){
      return orgGwy({
            url: 'assets-demand/dep-client-list',
            method: 'get',
            params
      })
}
// 需求转移 assets-demand/charge
export function assetsDemandCharge(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'assets-demand/charge',
            data)
}
// 新增需求
export function assetsDemandDemand(data) {
      return orgGwy({
            url: 'assets-demand/demand',
            method: 'post',
            data
      })
}
// 编辑需求
export function editDemandDemand(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'assets-demand/demand',
            data)
}
// 需求详情
export function  assetsDemandDetails(params){
      return orgGwy({
            url: 'assets-demand/demand',
            method: 'get',
            params
      })
}
// 客户详情 
export function clientDetail(params){
      return orgGwy({
            url: 'client/client',
            method: 'get',
            params
      })
}
// 快捷跟进状态 
export function assetsDemandFollowupStatus(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'assets-demand/followup-status',
            data)
}
// 线索选择快捷方式 
export function assetsClueFollowupStatus(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'assets-clue/followup-status',
            data)
}
// 删除需求
export function assetsDemandDelete(data) {
      return Delete(
            Vue.prototype.globalData.orgHost,
            'assets-demand/demand',
            data)
}
// 编辑代理商
export function editAgent(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'admin/agent/index',
            data)
}
// 用户关闭/开启
export function userSwitch(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'admin/user/status',
            data)
}
// 编辑用户

export function editUser(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'admin/user/index',
            data)
}
// 任务置顶
export function taskTop(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'admin/task/top',
            data)
}
// 任务关闭
export function taskStatus(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'admin/task/status',
            data)
}
// 任务类型列表
export function commonTaskType(params) {
      return orgGwy({
            url: 'common/taskType',
            method: 'get',
            params
      })
}
// 提现审批

export function reflectStatus(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'admin/reflect/batchStatus',
            data)
}
// // 代理商列表
// export function adminList(params){
//          return orgGwy({
//                  url:'admin/agent/list',
//                  method:'get',
//                  params
//          })
// }
// 提现审核
export function ReflectList(params) {
      return orgGwy({
            url: 'admin/reflect/list',
            method: 'get',
            params
      })
}
// 新增代理商
export function addAgent(data) {
      return orgGwy({
            url: 'admin/agent/index',
            method: 'post',
            data
      })
}
// 用户列表
export function userList(params) {
      return orgGwy({
            url: 'admin/user/list',
            method: 'get',
            params
      })
}
// 任务管理列表
export function taskList(params) {
      return orgGwy({
            url: 'admin/task/list',
            method: 'get',
            params
      })
}
// 删除任务
export function taskDelete(data) {
      return orgGwy({
            url: 'admin/task/index',
            method: 'delete',
            data
      })
}
// 删除附件
// 新增任务
export function addTask(data) {
      return orgGwy({
            url: 'admin/task/index',
            method: 'post',
            data
      })
}
// 编辑任务
export function editTask(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'admin/task/index',
            data)
}
// 任务详情
export function detaileTask(params) {
      return orgGwy({
            url: 'admin/task/index',
            method: 'get',
            params
      })
}
// 订单导出
export function orderExport(params) {
      return orgGwy({
            url: 'admin/order/export',
            method: 'get',
            params
      })
}

// 订单管理列表
export function orderList(params) {
      return orgGwy({
            url: 'admin/order/list',
            method: 'get',
            params
      })
}
// 订单审核

export function orderBatchStatus(data) {
      return put(
            Vue.prototype.globalData.orgHost,
            'admin/order/batchStatus',
            data)
}
// 提现审核列表
export function reflectList(params) {
      return orgGwy({
            url: 'admin/reflect/list',
            method: 'get',
            params
      })
}
// 账户流水明细列表
export function walletList(params) {
      return orgGwy({
            url: 'admin/wallet/list',
            method: 'get',
            params
      })
}

// 线索统计 assets-clue/clue-statistics
export function clueStatisticsList(data) {
    return orgGwy({
        url: 'assets-clue/clue-statistics',
        method: 'post',
        data
    })  
}

// 线索统计--每日跟进统计
export function dailyFollowupTotalData(params) {
    return orgGwy({
        url: 'assets-clue/daily-followup-total',
        method: 'get',
        params
    })
}

// 线索统计--人员跟进统计
export function userFollowupTotalData(params) {
    return orgGwy({
        url: 'assets-clue/user-followup-total',
        method: 'get',
        params
    })
}

