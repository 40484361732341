<template>
  <div class="page_box">
    <page-title-bar :title="title">
      <div>
        <el-button size="small" type="infor" @click="$router.go(-1)"
          >返回</el-button
        >
        <el-button
          @click="submit3"
          type="primary"
          size="small"
          style="margin-right: 10px"
          >保存</el-button
        >
      </div>
    </page-title-bar>
    <div class="bd-bar">
      <div class="hader-title">{{ roleName }}</div>
      <el-form
        ref="SearchForm"
        :inline="true"
        :model="form"
        class="search-bar_n"
      >
        <el-form-item label="数据权限范围" prop="type">
          <el-select
            v-model="form.type"
            style="width: 212px"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="hader-desc">功能权限配置</div>
      <div class="hader-border"></div>
      <div class="title">
        <div class="flex jcsb" style="margin-bottom: 20px">
          <div class="flex" style="align-items: center">
            <el-checkbox v-model="pcAllChecked" @change="allSelect('pc')"
              >全选</el-checkbox
            >
            <div style="color: #999" class="yxz">
              已选中<span style="color: #409eff">{{ selectedNum }}</span
              >项
            </div>
            <div class="clear-btn" @click="clear1">
              <img
                src="../../assets/image/clear@2x.png"
                class="clear-icon"
                alt=""
              />
              清空
            </div>
          </div>
        </div>
      </div>
      <div
        class="cus_content"
        :style="{ height: height }"
        style="overflow: auto"
      >
        <table
          id="table1"
          border="1px solid #E0E0E0"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <th class="first-col">目录</th>
            <th class="first-col">菜单</th>
            <!-- <th style="width: 10%; min-width: 160px">三级表单</th> -->
            <th style="width: 70%">操作</th>
          </thead>
          <tbody>
            <template v-for="(item, index) in list">
              <template v-if="item.child == 0">
                <tr :key="item.id">
                  <td class="first-col">
                    <el-checkbox
                      :indeterminate="item.SelectedState == 2 ? true : false"
                      v-model="item.isSelect"
                      @change="menuChange(item)"
                      >{{ item.menuName }}</el-checkbox
                    >
                    <el-tooltip
                      :content="item.remark"
                      placement="bottom"
                      effect="light"
                      v-if="item.remark"
                    >
                      <img
                        src="../../assets/image/tips@2x.png"
                        style="width: 16px; height: 16px"
                        alt=""
                      />
                    </el-tooltip>
                  </td>
                  <td class="first-col second-col"></td>
                  <td colspan="1">
                    <table
                      class="table2"
                      border="1px solid #E0E0E0"
                      cellspacing="0"
                      cellpadding="0"
                      style="
                        border-collapse: collapse;
                        border-width: 0px;
                        border-style: hidden;
                      "
                    >
                      <template>
                        <tr>
                          <td class="first-col third-col">&nbsp;</td>
                          <!-- <td class="w200">&nbsp;</td> -->
                        </tr>
                      </template>
                    </table>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(m, i) in item.child" :key="m.id">
                  <td
                    class="first-col second-col"
                    v-if="i == 0"
                    :rowspan="item.child.length"
                  >
                    <div class="changliner">
                      <el-checkbox
                        :indeterminate="
                          item.SelectedState == 2 ||
                          (item.SelectedState == 0 && item.isSelect)
                            ? true
                            : false
                        "
                        v-model="item.isSelect"
                        @change="menuChange(item)"
                        >{{ item.menuName }}
                      </el-checkbox>
                      <el-tooltip
                        :content="item.remark"
                        placement="bottom"
                        effect="light"
                        v-if="item.remark"
                      >
                        <img
                          src="../../assets/image/tips@2x.png"
                          style="width: 16px; height: 16px"
                          class="centerImg"
                          alt=""
                        />
                      </el-tooltip>
                    </div>
                  </td>
                  <td class="first-col second-col">
                    <div class="changliner">
                      <el-checkbox
                        :indeterminate="
                          m.SelectedState == 2 ||
                          (m.SelectedState == 0 && m.isSelect)
                            ? true
                            : false
                        "
                        v-model="m.isSelect"
                        @change="secondMenuChange(index, m, i)"
                        >{{ m.menuName }}</el-checkbox
                      >
                      <el-tooltip
                        :content="m.remark"
                        placement="bottom"
                        effect="light"
                        v-if="m.remark"
                      >
                        <img
                          src="../../assets/image/tips@2x.png"
                          style="width: 16px; height: 16px"
                          alt=""
                        />
                      </el-tooltip>
                    </div>
                  </td>
                  <td colspan="1">
                    <table
                      class="table2"
                      border="1px solid #E0E0E0"
                      cellspacing="0"
                      cellpadding="0"
                      style="
                        border-collapse: collapse;
                        border-width: 0px;
                        border-style: hidden;
                      "
                    >
                      <template v-if="m.child">
                        <template v-if="m.child[0].type == 1">
                          <tr>
                            <td class="first-col second-col third-col">
                              &nbsp;
                            </td>
                            <td class="w200">
                              <div
                                class="flex flex-wrap"
                                style="padding-left: 24px"
                              >
                                <div
                                  class="operator-item"
                                  v-for="(three, kk) in m.child"
                                  :key="three.id"
                                >
                                  <el-checkbox
                                    v-model="three.isSelect"
                                    @change="
                                      thirdMenuChange(index, m, i, three, kk)
                                    "
                                    >{{ three.menuName }}</el-checkbox
                                  >
                                  <el-tooltip
                                    :content="three.remark"
                                    placement="bottom"
                                    effect="light"
                                    v-if="three.remark"
                                  >
                                    <img
                                      src="../../assets/image/tips@2x.png"
                                      style="width: 16px; height: 16px"
                                      alt=""
                                    />
                                  </el-tooltip>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr >
                            <!-- first-col -->
                            <td class=" second-col  third-col flex flex-wrap" >
                              <div class="changliner " v-for="(three, kk) in m.child" :key="three.id">
                                <el-checkbox
                                  :indeterminate="
                                    three.SelectedState == 2 ||
                                    (three.SelectedState == 0 &&
                                      three.isSelect &&
                                      three.list.length > 0)
                                      ? false
                                      : false
                                  "
                                  v-model="three.isSelect"
                                  @change="
                                    thirdMenuChange(index, m, i, three, kk)
                                  "
                                  >{{ three.menuName }}</el-checkbox
                                >
                                <el-tooltip
                                  :content="three.remark"
                                  placement="bottom"
                                  effect="light"
                                  v-if="three.remark"
                                >
                                  <img
                                    src="../../assets/image/tips@2x.png"
                                    style="width: 16px; height: 16px"
                                    alt=""
                                  />
                                </el-tooltip>
                              </div>
                            </td>
                            <!-- <td class="w200">
                              <div
                                class="flex flex-wrap"
                                style="padding-left: 24px"
                              >
                                <div
                                  class="operator-item"
                                  v-for="(four, kkk) in three.child"
                                  :key="four.id"
                                >
                                  <el-checkbox
                                    v-model="four.isSelect"
                                    @change="
                                      fourthMenuChange(
                                        index,
                                        m,
                                        i,
                                        three,
                                        kk,
                                        four,
                                        kkk
                                      )
                                    "
                                    >{{ four.menuName }}</el-checkbox
                                  >
                                  <el-tooltip
                                    :content="four.remark"
                                    placement="bottom"
                                    effect="light"
                                    v-if="four.remark"
                                  >
                                    <img
                                      src="../../assets/image/tips@2x.png"
                                      style="width: 16px; height: 16px"
                                      alt=""
                                    />
                                  </el-tooltip>
                                </div>
                              </div>
                            </td> -->
                          </tr>
                        </template>
                      </template>
                      <template v-else>
                        <tr>
                          <!-- <td class="first-col third-col">&nbsp;</td> -->
                          <td class="w200">&nbsp;</td>
                        </tr>
                      </template>
                    </table>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import pageTitleBar from "@/components/page-title-bar.vue";
import {
  permissionList,
  rolePermission,
  editrolePermission,
} from "@/api/report";
export default {
  inject: ["reload"],
  components: { pageTitleBar },
  data() {
    return {
      title: "权限设置",
      form: {
        type: "",
      },
      list: [
        {
          list: 0,
          isSelect: false,
          name: "复选框",
          remark: "这是开始了",
        },
      ],
      options: [
        {
          label: "仅看自己",
          value: "1",
        },
        {
          label: "本部门",
          value: "2",
        },
        {
          label: "本部门及下属部门",
          value: "3",
        },
        {
          label: "全公司",
          value: "4",
        },
      ],
      height: this.$store.state.table_height(),
      //   全选
      pcAllChecked: false,
      roleName: "",
      roleId: "",
      scope: "",
      defaultChecked: [],
      checkStrictly: false,
    };
  },
  mounted() {},
  deactivated() {
    //清除keep-alive的缓存
    this.$destroy(true);
  },
  activated() {
    let _this = this;
    if (_this.$route.query.id) {
      _this.roleName = _this.$route.query.roleName;
      _this.roleId = _this.$route.query.id;
      _this.form.type = _this.$route.query.scope;
    }
    this.getData();
    console.log(this.$route.query.roleName);
  },
  computed: {
    selectedNum() {
      return this.defaultChecked.length;
    },
  },
  methods: {
    allSelect(ptype, flag) {
      let Checked;
      let type = ptype;
      let cl;
      if (flag === false) {
        this.pcAllChecked = false;
        Checked = false;
      } else {
        Checked = this.pcAllChecked;
      }
      cl = this.gwy.clone(this.list);
      if (Array.isArray(cl)) {
        for (var i = 0; i < cl.length; i++) {
          cl[i].isSelect = Checked;
          cl[i].SelectedState = Checked ? 1 : 0;
          console.log(cl[i].child);
          if (Array.isArray(cl[i].child)) {
            for (var j = 0; j < cl[i].child.length; j++) {
              cl[i].child[j].isSelect = Checked;
              cl[i].child[j].SelectedState = Checked ? 1 : 0;
              if (Array.isArray(cl[i].child[j].child)) {
                for (var k = 0; k < cl[i].child[j].child.length; k++) {
                  cl[i].child[j].child[k].isSelect = Checked;
                  cl[i].child[j].child[k].SelectedState = Checked ? 1 : 0;
                  // for (var l = 0; l < cl[i].child[j].child[k].length; l++) {
                  //   cl[i].child[j].child[k].child[l].isSelect = Checked;
                  //   cl[i].child[j].child[k].child[l].SelectedState = 0;
                  // }
                }
              }
            }
          }
        }
      }

      this.defaultChecked = [];
      this.list = cl;
      this.getCheck(this.list);
    },
    clear() {},
    async submit3() {
      console.log(this.defaultChecked);
      let _this = this;
      try {
        let params = {
          roleId: _this.roleId,
          permissionIds: _this.defaultChecked,
          scope: _this.form.type,
        };
        let response = await editrolePermission(params);
        if (response.statusCode == 200) {
          _this.$message({
            type: "success",
            message: "已保存",
          });
          _this.$router.go(-1);
        } else {
          _this.$message({
            type: "error",
            message: res.errors,
          });
        }
      } catch (error) {}
    },
    // 获取菜单角色
    async getData() {
      let _this = this;
      let params = {
        id: _this.roleId,
      };
      // try {
        _this.loading = true;
        // let response = await rolePermission(params);
        _this.$ajax({
        url: _this.globalData.orgHost + "role/role-permission",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.list = res.data;
          _this.getCheck(_this.list);
          let new_data = [];
          for (let item of res.data) {
            item.value = item.id;
            item.label = item.menuName;
            item.children = item.child;
            if (item.children) {
              for (let it of item.children) {
                it.value = it.id;
                it.label = it.menuName;
                it.children = "";
              }
            }
            new_data.push(item);
          }
          setTimeout(() => {
            _this.loading = false;
          }, 1000);
          _this.roleid = new_data;
          console.log("获取上级菜单", _this.roleid);
        }  else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
        // console.log(response);
      //   if (response.statusCode == 200) {
      //     _this.list = response.data;
      //     _this.getCheck(_this.list);
      //     let new_data = [];
      //     for (let item of response.data) {
      //       item.value = item.id;
      //       item.label = item.menuName;
      //       item.children = item.child;
      //       if (item.children) {
      //         for (let it of item.children) {
      //           it.value = it.id;
      //           it.label = it.menuName;
      //           it.children = "";
      //         }
      //       }
      //       new_data.push(item);
      //     }
      //     setTimeout(() => {
      //       _this.loading = false;
      //     }, 1000);
      //     _this.roleid = new_data;
      //     console.log("获取上级菜单", _this.roleid);
      //   } else {
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 一级菜单
    menuChange(targetItem) {
      let index;
      let clList = this.gwy.clone(targetItem);
      let isSelect = targetItem.isSelect;
      this.deepSelect(clList, isSelect, true);
      index = this.list.findIndex((item) => {
        return item.id == targetItem.id;
      });
      this.list.splice(index, 1, clList);
      this.defaultChecked = [];
      this.getCheck(this.list);
    },
    // 二级菜单
    secondMenuChange(index, item, i) {
      let isSelect = item.isSelect;
      let parentItem;
      parentItem = this.list[index];
      if (isSelect) {
        parentItem.isSelect = isSelect;
      } else {
        let flag = parentItem.child.some((item) => {
          return item.isSelect;
        });
        parentItem.isSelect = flag;
      }
      let clList = this.gwy.clone(item);
      this.deepSelect(clList, isSelect, true);
      parentItem.child.splice(i, 1, clList);
      this.list.splice(index, 1, parentItem);
      this.defaultChecked = [];
      this.getCheck(this.list);
    },
    // 三级菜单选择
    thirdMenuChange(
      index,
      Seconditem,
      SecondIndex,
      ThirdItem,
      ThirdIndex,
      ptype
    ) {
      let isSelect = ThirdItem.isSelect;
      let ppItem;
      ppItem = this.list[index];
      if (isSelect) {
        ppItem.isSelect = isSelect;
        Seconditem.isSelect = isSelect;
      } else {
        let flag = Seconditem.child.some((item) => {
          return item.isSelect;
        });
        Seconditem.isSelect = flag;
        let ppFlag = ppItem.child.some((item) => {
          return item.isSelect;
        });
        ppItem.isSelect = ppFlag;
      }
      let clList = this.gwy.clone(ThirdItem);
      this.deepSelect(clList, isSelect);
      let secondAll = this.checkIsAll(Seconditem);
      let thirdAll = this.checkIsAll(clList);
      clList.SelectedState = !ThirdItem.isSelect ? 0 : thirdAll ? 1 : 2;
      Seconditem.child.splice(ThirdIndex, 1, clList);
      this.list.splice(index, 1, ppItem);
      this.defaultChecked = [];
      this.getCheck(this.list);
    },
    // //检查是否下级选完
    checkIsAll(item) {
      if (item.child) {
        return item.child.every((i) => {
          return i.isSelect;
        });
      }
    },
    // 递归选择
    deepSelect(list, isSelect, isAll) {
      let all = isAll || false;
      console.log(list);
      if (Array.isArray(list)) {
        list.forEach((item) => {
          if (item.child) {
            item.SelectedState = all
              ? isSelect
                ? item.child.length > 0
                  ? 1
                  : 1
                : 0
              : item.SelectedState;
          }

          item.isSelect = isSelect;
          if (item.child) {
            this.deepSelect(item.child, isSelect, isAll);
          }
        });
      } else if (list.child) {
        if (all) {
          list.SelectedState = isSelect ? (list.child.length > 0 ? 1 : 0) : 0;
        }
        if (list.child.length > 0) {
          console.log(list);
          this.deepSelect(list.child, isSelect, isAll);
        }
      }
    },
    // 清空
    clear1() {
      this.allSelect("pc", false);
    },
    // 递归遍历选中的数据
    getCheck(source) {
      for (var key in source) {
        if (source[key].isSelect === true) {
          this.defaultChecked.push(source[key].id);
        }
        this.checkStrictly = false; //重点： 赋值完成后 设置为false
        if (source[key].child) {
          this.getCheck(source[key].child);
        }
      }
    },
  },
};
</script>

<style scoped>
.bd-bar {
  padding: 25px 28px;
}
.hader-title {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}
.hader-desc {
  font-size: 14px;
  color: #606266;
}
.search-bar_n {
  margin: 0;
  padding-bottom: 0;
}
.yxz {
  margin-left: 10px;
}
.clear-btn {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 4px;
  cursor: pointer;
  margin-left: 24px;
}
.clear-icon {
  width: 16px;
  height: 16px;
}
.hader-border {
  margin: 10px 0 18px;
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  border-radius: 1px 1px 1px 1px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.w200 {
  width: 87.6%;
}
.first-col {
  min-width: 160px;
}
.second-col {
  text-align: left;
  padding-left: 24px;
}
.flex {
  display: flex;
}
.jcsb {
  justify-content: space-between;
}
/deep/ .el-checkbox {
  margin-right: 0;
}
table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
table th,
td {
  border: 1px solid #e0e0e0;
}
thead {
  background: rgba(24, 144, 255, 0.1);
}
thead th {
  height: 40px;
}
tr {
  height: 32px;
}
.third-col {
  /* width: 80px; */
  box-sizing: border-box;
  border: none;
  word-wrap: normal;
}
.changliner {
 
    margin-right: 56px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.changliner img {
  vertical-align: middle;
}
</style>
<style>
.flex {
  display: flex;
}
</style>