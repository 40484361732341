<template>
    <div class="page_box staffManage custormerManage dataCente" style="background:#fff">
        <pageTitleBar title="客户统计" @resetForm="resetForm">
        </pageTitleBar>
        <div class="datasurvey">
            <!--<div style="font-size: 16px;font-weight: 900;"> 客户统计</div>-->
            <div class="datarecord">
                <div>
        <div class="data-total">客户总数</div>
        <div class="data-quantity">{{ TotalCumulativeData.assetTotal || 0 }}</div>
    </div>
    <div>
        <div class="data-total">本周新增客户</div>
        <div class="data-quantity">{{ TotalCumulativeData.thisWeekNum || 0 }}</div>
    </div>
    <div>
        <div class="data-total">上周新增客户</div>
        <div class="data-quantity">{{ TotalCumulativeData.lastWeekNum || 0 }}</div>
    </div>
    <div>
        <div class="data-total">本月新增客户</div>
        <div class="data-quantity">{{ TotalCumulativeData.thisMonthNum || 0 }}</div>
    </div>
    <div>
        <div class="data-total">上月新增客户</div>
        <div class="data-quantity">{{ TotalCumulativeData.lastMonthNum || 0 }}</div>
    </div>
    <div>
        <div class="data-total">本月签约</div>
        <div class="data-quantity">{{ TotalCumulativeData.thisMonthSignContractNum || 0 }}</div>
    </div>
    <div>
        <div class="data-total">上月签约</div>
        <div class="data-quantity">{{ TotalCumulativeData.lastMonthSignContractNum || 0 }}</div>
    </div>
            </div>
        </div>

        <div class="departure_box">

            <el-tabs class="tabs-bar" v-model="tabStatus" @tab-click="setTimeOutgetData('SearchForm')">
                <el-tab-pane label="每日统计" name="1">
                    <el-form ref="SearchForm" :inline="true" :model="form" class="searchform" label-width="72px"
                             style="padding: 0px 0px; height: 50px;">
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div style="display: flex;">
                                            <div>
                                                <el-radio-group size="small" v-model="radio1"
                                                                @change="datetselection(radio1)">

                                                    <el-radio-button label="1">本周</el-radio-button>
                                                    <el-radio-button label="2">上周</el-radio-button>
                                                    <el-radio-button label="3">本月</el-radio-button>
                                                    <el-radio-button label="4">上月</el-radio-button>
                                                    <el-radio-button label="5">全年</el-radio-button>
                                                </el-radio-group>
                                            </div>
                                            <!--<el-form-item prop="followDate">
                                <el-date-picker v-model="form.followDate" size="small" type="daterange"
                                                align="center" unlink-panels range-separator="至"
                                                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>-->
                                            <el-form-item label-width="120px"
                                                          label="跟进时间段"
                                                          prop="followDate">
                                                <el-date-picker v-model="form.followDate"
                                                                size="small"
                                                                type="daterange"
                                                                align="center"
                                                                unlink-panels
                                                                range-separator="至"
                                                                start-placeholder="开始日期"
                                                                end-placeholder="结束日期"
                                                                format="yyyy-MM-dd"
                                                                value-format="yyyy-MM-dd"
                                                                style="width: 220px">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item label="" prop="department">
                                                <el-cascader :props="{ checkStrictly: true }"
                                                             v-model="form.department"
                                                             :options="roleid2"
                                                             :key="countindex2"
                                                             style="width: 150px"
                                                             size="small"
                                                             :show-all-levels="false"
                                                             placeholder="所在部门"></el-cascader>
                                            </el-form-item>
                                            <el-form-item label="" prop="principal">
                                                <el-input size="small"
                                                          style="width: 200px"
                                                          v-model="form.principal"
                                                          placeholder="跟进人"></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="getFollowupTotalData(1)" size="small">查询</el-button>
                                                <el-button @click="resetForm('SearchForm')" size="small">重置</el-button>
                                            </el-form-item>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form>
                    <div class="hd-data flex-box flex-v-ce" style="margin-bottom: 13px">
                        <div class="hd-title1">合计</div>
                        <div class="data-item flex-box flex-v-ce" style="padding-left: 12px">
                            <strong class="data-name">新增客户:</strong>
                            <div class="data-val">{{ CumulativeData.addClientNum || 0 }}</div>
                        </div>
                        <div class="data-item flex-box flex-v-ce" style="padding-left: 12px">
                            <strong class="data-name">跟进人数:</strong>
                            <div class="data-val">{{ CumulativeData.followNum || 0 }}</div>
                        </div>
                        <div class="data-item flex-box flex-v-ce">
                            <strong class="data-name">跟进客户数:</strong>
                            <div class="data-val">{{ CumulativeData.followClientNum || 0 }}</div>
                        </div>
                        <div class="data-item flex-box flex-v-ce">
                            <strong class="data-name">跟进客户次数:</strong>
                            <div class="data-val">{{ CumulativeData.followClientTimeNum || 0 }}</div>
                        </div>
                        <div class="data-item flex-box flex-v-ce">
                            <strong class="data-name">签约数:</strong>
                            <div class="data-val">{{ CumulativeData.signContract || 0 }}</div>
                        </div>
                    </div>


                    <!-- <div class="dotted-line"></div> -->
                    <div>
                        <el-table id="statisticsTable" v-loading="loading" :data="dataList" style="width: 100%; background: #fff; "
                                  ref="dataList" tooltip-effect="dark" :row-style="tableRowStyle">
                            <el-table-column key="1" prop="dateStr" label="日期" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                            <el-table-column key="2" prop="addClientNum" label="新增客户" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                            <el-table-column key="3" prop="followNum" label="跟进人数" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                            <el-table-column key="4" prop="followClientNum" label="跟进客户数" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                            <el-table-column key="5" prop="followClientTimeNum" label="跟进客户次数" align="left" :autoFit="true"
                                             :fitHeader="true" width="255">
                            </el-table-column>
                            <el-table-column key="6" prop="signContract" label="签约数" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>

                <el-tab-pane label="人员跟进统计" name="2">
                    <el-form ref="SearchForm" :inline="true" :model="form"  class="searchform" label-width="72px"
                             style="padding: 0px 0px; height: 50px;">
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div style="display: flex;">
                                            <div>
                                                <el-radio-group size="small" v-model="radio1"
                                                                @change="datetselection(radio1)">

                                                    <el-radio-button label="1">本周</el-radio-button>
                                                    <el-radio-button label="2">上周</el-radio-button>
                                                    <el-radio-button label="3">本月</el-radio-button>
                                                    <el-radio-button label="4">上月</el-radio-button>
                                                    <el-radio-button label="5">全年</el-radio-button>

                                                </el-radio-group>
                                            </div>
                                            <el-form-item label-width="120px"
                                                          label="跟进时间段"
                                                          prop="followDate">
                                                <el-date-picker v-model="form.followDate"
                                                                size="small"
                                                                type="daterange"
                                                                align="center"
                                                                unlink-panels
                                                                range-separator="至"
                                                                start-placeholder="开始日期"
                                                                end-placeholder="结束日期"
                                                                format="yyyy/MM/dd"
                                                                value-format="yyyy-MM-dd"
                                                                style="width: 220px">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item label="" prop="department">
                                                <el-cascader :props="{ checkStrictly: true }"
                                                             v-model="form.department"
                                                             :options="roleid2"
                                                             :key="countindex2"
                                                             style="width: 150px"
                                                             size="small"
                                                             :show-all-levels="false"
                                                             placeholder="所在部门"></el-cascader>
                                            </el-form-item>
                                            <el-form-item label="" prop="principal">
                                                <el-input size="small"
                                                          style="width: 200px"
                                                          v-model="form.principal"
                                                          placeholder="负责人姓名"></el-input>
                                            </el-form-item>

                                            <el-form-item>
                                                <el-button type="primary" @click="getFollowupTotalData(1)" size="small">查询</el-button>
                                                <el-button @click="resetForm('SearchForm')" size="small">重置</el-button>
                                            </el-form-item>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form>
                    <div class="hd-data flex-box flex-v-ce" style="margin-bottom: 13px">
                        <div class="hd-title1">合计</div>
                        <div class="data-item flex-box flex-v-ce" style="padding-left: 12px">
                            <strong class="data-name">跟进人数:</strong>
                            <div class="data-val">{{ CumulativeData.followuPersonnelNum || 0 }}</div>
                        </div>
                        <div class="data-item flex-box flex-v-ce">
                            <strong class="data-name">跟进客户数:</strong>
                            <div class="data-val">{{ CumulativeData.accumulateFollowUpTotal || 0 }}</div>
                        </div>
                        <div class="data-item flex-box flex-v-ce">
                            <strong class="data-name">跟进客户次数:</strong>
                            <div class="data-val">{{ CumulativeData.accumulateFollowUpNum || 0 }}</div>
                        </div>
                        <div class="data-item flex-box flex-v-ce">
                            <strong class="data-name">签约数:</strong>
                            <div class="data-val">{{ CumulativeData.signContractTotal || 0 }}</div>
                        </div>
                    </div>

                    <div>
                        <el-table id="statisticsTable" v-loading="loading" :data="dataList" style="width: 100%"
                                  ref="dataList2" tooltip-effect="dark" :row-style="tableRowStyle">
                            <el-table-column key="1" prop="followUpName" label="姓名" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                            <el-table-column key="2" prop="departmentName" label="所在部门" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                            <el-table-column key="3" prop="followUpNum" label="跟进客户数" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                            <el-table-column key="4" prop="followUpLeadsNum" label="跟进客户次数" align="left" :autoFit="true"
                                             :fitHeader="true" width="255">
                            </el-table-column>
                            <el-table-column key="5" prop="signContract" label="签约数" align="left" :autoFit="true"
                                             :fitHeader="true">
                            </el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
                <div class="page flex-box flex-d-center" style="margin-bottom: 10px;margin-top: 10px;">
                    <div class="left-cont">
                    </div>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="this.page" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="this.perPage"
                        layout="total, sizes, prev, pager, next, jumper" :total="this.count">
                    </el-pagination>
                </div>
            </el-tabs>

        </div>

    </div>
</template>
<script>
    import pageTitleBar from "@/components/page-title-bar.vue";
    import {
        departmentList, clueStatisticsList, dailyFollowupTotalData, userFollowupTotalData
    } from "@/api/report";
    export default {
        name: "storeManagement",
        components: {
            pageTitleBar
        },
        inject: ["reload"],
        data() {
            return {
                tabStatus: '1',
                count: 0,
                radio1: '3',
                dataList: [],
                dataList2: [],
                roleid2: [],
                countindex2: 0,
                loading: false,
                filingjudge: false,
                filinggiveup: false,
                filingpointout: false,
                remarks: '',
                form: {
                    namenumber: '',
                    principal: '',
                    department: "",
                    followDate: [],

                },
                CumulativeData: {},
                TotalCumulativeData: {},
                page: 1,
                perPage: 10,
            }
        },
        mounted() {
            let startime = this.dd.currmonthStarttime();
            let endTime = this.the_same_day();
            this.form.followDate = [startime, endTime];
            this.getData();
            this.getFollowupTotalData();
            this.getDeparmentList();
            //this.datetselection(3)
        },
        methods: {

            // 查询统计数据
            async getData() {
                let _this = this;
                try {
                    //_this.tabLoading = true;
                    //let response = await clueStatisticsList();
                    //if (response.statusCode == 200) {
                    //    let data = response.data;
                    //    console.log(data);
                    //    _this.TotalCumulativeData = data;
                    //    _this.tabLoading = false;
                    //}
                    let response = "client/client-statistics";
                    _this.$ajax({
                        url: _this.globalData.orgHost + response,
                        method: "post",
                        data: null,
                        success(res) {
                            if (res.statusCode == 200) {
                                let data = res.data;
                                console.log(data);
                                _this.TotalCumulativeData = data;
                                _this.tabLoading = false;
                            } else {
                                _this.$message({
                                    type: "error",
                                    message: res.errors,
                                });
                            }
                            _this.tabLoading = false;
                        },
                    });


                } catch (error) {
                    console.log(error);
                }
            },
            // 获取部门
            async getDeparmentList() {
                let _this = this;
                let params = {
                    pageIndex: 1,
                    pageSize: 100000,
                };
                //_this.loading = true;
                let response = await departmentList(params);
                if (response.statusCode == 200) {
                    let new_data = [];
                    for (let item of response.data) {
                        item.value = item.id;
                        item.label = item.departmentName;
                        item.children = item.children;
                        if (item.children) {
                            for (let it of item.children) {
                                it.value = it.id;
                                it.label = it.departmentName;
                                it.children = it.children;
                                if (it.children) {
                                    for (let qu of it.children) {
                                        qu.value = qu.id;
                                        qu.label = qu.departmentName;
                                        qu.children = "";
                                    }
                                }
                            }
                        }
                        new_data.push(item);
                    }
                    _this.roleid2 = new_data;
                } else {
                    _this.$message({
                        type: "error",
                        message: response.errors,
                    });
                }
                setTimeout(() => {
                    //_this.loading = false;
                }, 1000);
            },
            setTimeOutgetData(reform) {
                
                let that = this;
                this.$refs[reform].resetFields();
                that.reloadTable = false;
                that.$nextTick(() => {
                    that.reloadTable = true;
                })
                that.radio1 = '3';
                this.page = 1;
                this.datetselection(3)
                // this.getData()
            },
            tableRowStyle({ row, rowIndex }) {
                if (rowIndex === 0) {
                    return { 'background': '#fff', 'font-weight': 'bold' };
                }
                return '';
            },
            datetselection(radio1) {
                let date = new Date()
                //if (radio1 == 1) {//本周
                //    //let nowDayOfWeek = date.getDay(); //今天本周的第几天
                //    //let nowDay = date.getDate();              //当前日
                //    //let nowMonth = date.getMonth();           //当前月
                //    //let nowYear = date.getFullYear();             //当前年
                //    //let weekStartDate = new Date(nowYear, nowMonth, nowDay - (nowDayOfWeek - 1));//获得本周的开始日期
                //    //let weekEndDate = new Date(nowYear, nowMonth, nowDay + (7 - nowDayOfWeek));///获得本周的结束日期
                //    //let startDate = this.defaultAdvancedDate2(weekStartDate, radio1)
                //    //let endDate = this.defaultAdvancedDate2(weekEndDate, radio1)
                //    //this.form.followDate = [startDate, endDate]
                //    this.form.followDate = this.defaultAdvancedDate2(date, radio1)
                //    console.log("weekStartDate3", this.form.followDate)
                //    //this.$set(this.form, "followDate", this.form.followDate);
                //}
                this.form.followDate = this.defaultAdvancedDate2(date, radio1)
                this.getFollowupTotalData();
            },
            //获取默认日期
            defaultAdvancedDate2(date, radio1) {
                // // //获取新的时间
                // let date = new Date();
                //获取当前时间的年份转为字符串
                let year = date.getFullYear().toString();
                //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
                let month =
                    date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1).toString()
                        : (date.getMonth() + 1).toString();
                //获取天，判断是否小于10，如果是在字符串前面拼接'0'
                let da =
                    date.getDate() < 10
                        ? "0" + date.getDate().toString()
                        : date.getDate().toString(); //'12'
                //字符串拼接，开始时间，结束时间
                if (radio1 == 2) {//上周
                    const now = new Date()
                    let nowDayOfWeek = now.getDay();
                    let beg = this.dd.GetDateStr(-(nowDayOfWeek - 1 + 7))
                    let end = this.dd.getTime(1);
                    let dateVals = [beg, end]; //将值设置给插件绑定的数据
                    return dateVals;
                }
                if (radio1 == 1) {//本周
                    let time = this.dd.getFirstDayOfWeek(new Date());
                    let year = time.getFullYear();
                    let month = time.getMonth() + 1;
                    let day = time.getDate();
                    month = month < 10 ? "0" + month : month;
                    day = day < 10 ? "0" + day : day;
                    let beg = year + "-" + month + "-" + day;
                    let end = this.the_same_day();
                    //let beg = [sameMonth, end];
                    let dateVals = [beg, end]; //将值设置给插件绑定的数据
                    return dateVals;

                }
                if (radio1 == 3) {//本月
                    //let end = year + "-" + month + "-" + da; //当天
                    //let beg = year + "-" + month + "-01"; //当月第一天
                    let beg = this.dd.currmonthStarttime();
                    let end = this.the_same_day();
                    let dateVals = [beg, end]; //将值设置给插件绑定的数据
                    return dateVals;
                }

                if (radio1 == 4) {//上月
                    let recentmonth = this.dd.getLastMonth_Date().split(",");
                    let beg = recentmonth[0];
                    let end = recentmonth[1];
                    // this.$set(this.form, "applyDate", beg);
                    let dateVals = [beg, end]; //将值设置给插件绑定的数据
                    return dateVals;
                }
                if (radio1 == 5) {//全年
                    let beg = year + "-" + "01" + "-" + "01";
                    let end = this.the_same_day();
                    let dateVals = [beg, end]; //将值设置给插件绑定的数据
                    return dateVals;
                }
                // this.dateVals = [beg, end]; //将值设置给插件绑定的数据
            },
            the_same_day() {
                let year = new Date().getFullYear();
                let month = new Date().getMonth() + 1;
                let day = new Date().getDate();
                console.log(day);
                month = month < 10 ? "0" + month : month;
                day = day < 10 ? "0" + day : day;
                let sameMonth = year + "-" + month + "-" + day;
                return sameMonth;
            },
            giveupsignin() {
                this.filinggiveup = true;
            },
            confirm() {
                this.filingjudge = true;
            },
            reportask() {
                this.filingpointout = true;
                this.$refs.followForm.validate((valid) => {
                    if (valid) {
                        let that = this;

                    }
                });
            },
            SelectTag(item) {
                this.remarks = item;
            },
            //页码变更
            handleCurrentChange(val) {
                this.page = val;
                this.getFollowupTotalData(this.page, this.perPage);
            },
            handleSizeChange(val) {
                this.perPage = val;
                this.page = 1;
                this.getFollowupTotalData(this.page, this.perPage);
            },
            closeFollowDialog() {
                this.filingjudge = false;
                this.filinggiveup = false;
                this.filingpointout = false;
                /* this.$refs.followForm.resetFields();*/
            },
            resetForm(reform) {
                console.log('强制刷新', reform);
                /* this.$refs[reform].resetFields();*/
                this.form.namenumber = '';
                this.radio1 = '3';
                this.$refs[reform].resetFields();
                this.form.followDate = null;
                this.getFollowupTotalData();
                //this.$nextTick(() => {
                //    this.$store.commit("clearClient");
                //    // this.$router.push({ query: {} });
                //    this.getFollowupTotalData();
                //});
            },
            // 查询跟进统计
            async getFollowupTotalData(page, perPage) {
                let _this = this;
                if (page == 1) {
                    _this.page = 1;
                }
                try {
                    let index = page ? page : _this.page;
                    let size = perPage ? perPage : _this.perPage;
                    let params = {
                        pageIndex: index,
                        pageSize: size,
                        FollowUpName: _this.form.principal,
                        CreateTimeStart: _this.form.followDate != null ? _this.form.followDate[0] : "" || "",
                        CreateTimeEnd: _this.form.followDate != null ? _this.form.followDate[1] : "" || "",
                        DepartmentId:
                            _this.form.department[_this.form.department.length - 1] || "",
                    };

                    _this.tabLoading = true;
                    let response;
                    if (_this.tabStatus == 1) {
                        // 查询每日跟进统计assets-clue/daily-followup-total
                        //response = await dailyFollowupTotalData(params);
                        response = "client/daily-followup-total";
                    }
                    else {
                        // 查询人员跟进统计assets-clue/user-followup-total
                        //response = await userFollowupTotalData(params);
                        response = "client/user-followup-total";
                    }

                    _this.$ajax({
                        url: _this.globalData.orgHost + response,
                        method: "get",
                        data: params,
                        success(res) {
                            if (res.statusCode == 200) {
                                let { data, totalCount, cumulativeData } = res.data;
                                console.log(data);
                                console.log("cumulativeData1", cumulativeData);
                                _this.dataList = data;
                                _this.count = totalCount;
                                if (cumulativeData) {
                                    _this.CumulativeData = cumulativeData;
                                }
                            } else {
                                _this.$message({
                                    type: "error",
                                    message: res.errors,
                                });
                            }
                            _this.tabLoading = false;
                        },
                    });

                } catch (error) {
                    console.log(error);
                }
            },
        }

    }
</script>
<style lang="scss">
    .dataCente {
        .tabs-bar .el-tabs__item {
            height: 43px;
        }
    }
</style>
<style lang="scss" scoped>
    .datasurvey {
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
       /* line-height: 40px;*/
        padding: 20px 0px;
        -webkit-box-shadow: 0 0 10px #eee;
        box-shadow: 0 0 10px #eee;
/*        margin: 0 0 20px;*/
        padding: 20px 20px;
    }

    .tabs-bar {
        padding: 12px 20px 0;
    }

    .tabs-bar .el-tabs__item {
        height: 43px;
    }

    .datarecord {
        display: flex;
        justify-content: space-between;
        padding: 0 35px;
    }

    .data-total {
        color: #909399;
        height: 29px;
    }

    .data-quantity {
        font-size: 18px;
        font-weight: 800;
    }

    .dialog-cont {
        padding: 20px 40px 15px 20px;
        overflow-y: auto;
    }

    .dialog .btn-group {
        padding: 15px 30px;
        box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.2);
        justify-content: flex-end;
    }

    .flex {
        display: flex;
    }

    .hd-data {
        background: #f3f4f7;
        border: 1px solid #cfd5de;
        /*margin: 0 24px 16px;*/
    }

    .hd-data .hd-title1 {
        padding: 9px 16px;
        font-size: 14px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        line-height: 1;
        color: #333333;
        border-right: 1px solid #ccc;
        background: #cfd5de;
        opacity: 1;
        border-radius: 2px;
        /* margin: 0 20px 0 0; */
    }

    .hd-data .data-item {
        padding: 0 16px 0;
        color: #666;
        /* margin: 0 80px 0 0; */
        border-right: 1px solid #ccc;
    }

    .hd-data .data-item:last-of-type {
        border: none;
    }

    .hd-data .data-val {
        padding: 0 0 0 2px;
        color: #ff730f;
    }

    .jcsb {
        justify-content: space-between;
    }

    .align-center {
        align-items: center;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    .tag-item {
        background: #dcdfe670;
        color: #000;
        margin-right: 6px;
        margin-top: 6px;
        box-sizing: border-box;
        padding: 0 6px;
        cursor: pointer;
        font-size: 11px
    }

    .employ-detail-dialog {
        box-sizing: border-box;
        padding: 24px 48px;
        max-height: 709px;
        overflow-y: scroll;
    }

    .infoName {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-right: 8px;
    }

    .followupsttd {
        background-color: rgb(249, 149, 0);
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin: 0px 10px; */
        padding: 0px 10px;
    }

    .info-btn {
        width: 74px;
        height: 32px;
        line-height: 32px;
        padding: 0;
        background: #ffffff;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #cfd5de;
    }

    .filingstd {
        font-size: 21px;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        /* align-content: center; */
        align-items: center;
    }

    .dialog-filing {
        display: flex;
    }

    .dialog-natd {
        width: 105px;
        margin: 10px 0px;
        font-size: 13px
    }

    .dialog-fliea {
        width: 160px;
        margin: 10px 0px;
        font-size: 13px;
        color: #000;
    }

    .liesta {
        color: #000;
        width: 100%;
    }

    .primary-btn {
        width: 74px;
        height: 32px;
        line-height: 32px;
        padding: 0;
        background: #1890ff;
        color: #fff;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
    }

    .info-label {
        color: #999;
    }

    .info-detail-desc {
        margin-bottom: 8px;
    }

    .status-txt {
        font-size: 20px;
        color: #1890ff;
    }

    .info-tl {
        font-size: 14px;
        color: #333;
        font-weight: bold;
        padding-left: 6px;
        border-left: 3px solid #1890ff;
    }

    .progress_box {
        margin-top: 20px;
        /* width: 360px; */
    }

    .progress_box li {
        position: relative;
        padding: 0 21px;
        list-style: none;
        height: 54px;
        border-left: 2px solid rgba(0, 0, 0, 0.06);
    }

    .progress_box li:before {
        content: "";
        display: block;
        position: absolute;
        /* margin-left: -6px; */
        top: 0;
        left: -6px;
        width: 10px;
        height: 10px;
        /* line-height: 22px; */
        text-align: center;
        background: #fff;
        border: 2px solid #cfd5de;
        box-sizing: border-box;
        /* color: #fff; */
        /* font-size: 14px; */
        border-radius: 50%;
    }

    .progress_content {
        position: absolute;
        top: -4px;
    }

    .progress_title {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
    }

    .progress_time {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        margin-right: 8px;
    }

    .searchform {
        border-radius: 8px;
        overflow: hidden;
        line-height: 40px;
        padding: 20px 0px;
    }

    .last_progress {
        border: none;
    }

    /* .last_progress::before{
       content: "●";
       display: table-cell;
       position: absolute;
       vertical-align: middle;
       /* margin-left: -6px; */
    /* top: 0;
       left: -6px;
       width: 14px;
       height: 14px;
       line-height: 11.5px;
       text-align: center;
       background: rgba(75, 172, 253, .3);
       color: #4BACFD;
       font-size: 18px;
       border-radius: 50%;
     } */
    .desc {
        color: #323233;
        font-size: 12px;
        margin-bottom: 16px;
        margin-top: 4px;
        white-space: pre-wrap;
    }
</style>
<style>
.custormerManage .el-step__head.is-finish {
    color: #1890ff;
    border-color: rgba(24, 144, 255, 0.2);
}

.custormerManage .el-step__line {
    border-color: rgba(242, 243, 245, 1);
}

.custormerManage .el-step__icon {
    background: rgba(24, 144, 255, 0.2);
    z-index: 3;
}

.custormerManage .el-step.is-vertical .el-step__line {
    height: 60px;
    top: 30px;
}

.custormerManage .el-dialog__wrapper {
    overflow: hidden;
}

.el-tooltip__popper {
    max-width: 60%;
}
</style>