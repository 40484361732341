<template>
  <div class="home-page">
    <el-container>
      <el-aside width="200px" class="aside">
        <el-menu
          :default-openeds="['1', '2']"
          active-text-color="#fff"
          background-color="#282C34"
          @select="getselect"
        >
          <!-- <el-menu-item index="1" class="hd-title">
            <template slot="title"> -->
          <div class="flex menuImg-hd">
            <!-- <img src="../assets/image/list-icon.png" alt="" /> -->
            <div style="font-weight: 600">
              <!-- <img src="../assets/image/logo.png" width="128px" height="56px"  alt=""> -->
              <h1 class="home-header">企园网（管理后台）</h1>
            </div>
          </div>
          <!-- <img src="../assets/image/list-icon.png" alt="">推客联盟管理后台</template
            > -->
          <!-- </el-menu-item> -->
          <el-submenu
            :index="item.index"
            v-for="(item, index) in dataList"
            :key="'info-' + index"
            v-show="item.child != null"
          >
            <template slot="title">
              <div class="flex menuImg">
                <img src="../assets/image/list-icon.png" alt="" />
                <div style="color: #fff; font-size: 16px">
                  {{ item.menuName }}
                </div>
              </div>
            </template>
            <el-menu-item-group class="menuGroup">
              <el-menu-item
                :index="arr.index"
                v-for="(arr, idx) in item.child"
                :key="'info1-' + idx"
                >{{ arr.menuName }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item
            v-for="(item, index) in dataList"
            :key="index"
            :index="'info2-' + (index + 1)"
            :class="{ 'bg-active': index + 2 == active }"
            v-show="item.child == null"
          >
            <template slot="title">
              <div class="flex menuImg">
                <img src="../assets/image/list-icon.png" alt="" />
                <div>{{ item.menuName }}</div>
              </div>
            </template>
          </el-menu-item>
          <!-- <el-submenu index="2">
            <template slot="title">
               <div class="flex menuImg">
                <img src="../assets/image/list-icon.png" alt="" />
                <div style="color:#fff;font-size:16px;">系统</div>
              </div>
            </template>
            <el-menu-item-group class="menuGroup">
              <el-menu-item index="2-1">成员管理</el-menu-item>
              <el-menu-item index="2-2">部门管理</el-menu-item>
              <el-menu-item index="2-3">角色管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->
          <!-- <el-submenu index="0" v-for="(item, index) in dataList" :key="index">
            <template slot="title">
               <div class="flex menuImg">
                <img src="../assets/image/list-icon.png" alt="" />
                <div style="color:#fff;font-size:16px;">{{ item.title }}</div>
              </div>
            </template>
          </el-submenu> -->

          <!-- <el-menu-item
            v-for="(item, index) in dataList"
            :key="index"
            :index="'' + (index + 2)"
            :class="{ 'bg-active': index + 2 == active }"
          >
            <template slot="title">
              <div class="flex menuImg">
                <img src="../assets/image/list-icon.png" alt="" />
                <div>{{ item.title }}</div>
              </div>
            </template>
          </el-menu-item> -->
        </el-menu>
      </el-aside>
      <el-main>
        <div class="">
          <div class="hd-user flex">
            <div>
              <img src="../assets/image/show.png" alt="" />
            </div>
            <div>
              <el-dropdown
                trigger="click"
                placement="top"
                style="position: relative; cursor: pointer"
                @command="logOut"
              >
                <!-- el-dropdown-link -->
                <span
                  class="flex"
                  style="
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    align-items: center;
                  "
                >
                  <!-- <el-avatar size="medium" :src="circleUrl"></el-avatar> -->
                  <span style="margin-left: 10px">{{ username }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command="exitLogin"
                  >修改密码</el-dropdown-item
                > -->
                  
                  <el-dropdown-item command="exitPwd"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item command="exitLogin"
                    >安全退出</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <pageTags :rightStyle="rightStyle" :thatPath="thatPath"></pageTags>
        <div>
          <keep-alive v-if="isRouterAlive">
            <router-view />
          </keep-alive>
        </div>
        <div v-if="$route.path == '/home'">
          <ContentBody />
        </div>
      </el-main>
    </el-container>

    <!-- 修改密码 -->
    <el-dialog
      class="batch-dialog n3"
      title="修改密码"
      :visible.sync="batchDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck"
    >
      <div class="dialog-cont">
        <el-form
          ref="batchForm"
          :inline="true"
          :model="batchForm"
          :rules="batchRules"
          class="batchForm3"
        >
          <el-form-item
            label="旧密码"
            prop="oldpwd"
            label-width="130px"
            style="margin-top: 30px"
          >
            <el-input
              style="width: 434px"
              show-password
              autocomplete="new-password"
              v-model="batchForm.oldpwd"
              placeholder="请输入旧密码"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPwd" label-width="130px">
            <el-input
              style="width: 434px"
              show-password
              autocomplete="new-password"
              v-model="batchForm.newPwd"
              placeholder="请输入新密码"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="确认新密码"
            prop="firmPwd"
            label-width="130px"
            style="margin-bottom: 50px"
          >
            <el-input
              style="width: 434px"
              show-password
              autocomplete="new-password"
              v-model="batchForm.firmPwd"
              placeholder="请重复输入新密码"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import ContentBody from "@/components/ContentBody";
import { editPassword, adminUserMenu } from "@/api/report";
import pageTags from "@/components/pageTags";

export default {
  name: "",
  provide() {
    return {
      reload: this.reload,
      getUsermenu: this.GetUserMenu,
    };
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let reqexp = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
        // let reqexp = /^[A-Za-z0-9]{6,18}$/;
        if (reqexp.test(value)) {
          callback();
        } else {
          callback(new Error("密码须数字和字母组成，且不少于6位"));
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.batchForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dataList: [],
      active: "",
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      isRouterAlive: true,
      username: "",
      batchDialog: false,
      batchForm: {
        oldpwd: "",
        newPwd: "",
        firmPwd: "",
      },
      batchRules: {
        oldpwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPwd: [{ required: true, validator: validatePass, trigger: "blur" }],
        firmPwd: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      isApply: false,
      rightStyle: {
        left: "72px",
        width: "95%",
      },
      thatPath: this.$route.path,
    };
  },
  components: {
    ContentBody,
    pageTags,
  },
  watch: {
    "$route.path"(val) {
      let that = this;
      that.thatPath = this.$route.path;
    },
  },
  mounted() {
    let username = this.$store.state.database;
    this.username = username;
    this.active = sessionStorage.getItem("pageItem");
    this.GetUserMenu();
    console.log(this.active);
    // this.$router.push("/contentbody");
  },
  methods: {
    getselect(index, item, arr) {
      let that = this;
      console.log(index, item, arr);
      this.reload();
      console.log(this.dataList);
      that.dataList.map((item, index2) => {
        if (item.child.length) {
          for (let y in item.child) {
            // console.log(item.child[y].index);
            if (index == item.child[y].index) {
              console.log(item.child[y], index);
              that.clickMenu(item.child[y], index);
              that.$router.push(item.child[y].pcUrl);
              console.log(item.child[y].pcUrl);
            }
          }
        }
      });
      let paths;
      if (index == item[0]) {
        console.log(index);
        // switch (index) {
        //   case "2":
        //     paths = "/agentslist";
        //     break;
        //   case "3":
        //     paths = "/userslist";
        //     break;
        //   case "4":
        //     paths = "/taskmanagement";
        //     break;
        //   case "5":
        //     paths = "/taskdeliverycheck";
        //     break;
        //   case "6":
        //     paths = "/withdrawCheck";
        //     break;
        //   case "7":
        //     paths = "/accountdetail";
        //     break;
        // }
        // this.$router.push(paths);
        this.isRouterAlive = false;
        this.$nextTick(function () {
          this.isRouterAlive = true;
        });
      }
      this.active = index;
      sessionStorage.setItem("pageItem", index);
    },
    contentClick(item) {
      console.log(item);
    },
    // 退出登录
    logOut(val) {
      let that = this;
      console.log(val);
      if (val == "exitLogin") {
        setTimeout(() => {
          sessionStorage.removeItem("pageItem");
          localStorage.Authorization = "";
          that.$router.push({ path: "/" });
        }, 500);
      } else if (val == "exitPwd") {
        that.batchDialog = true;
      }
    },
    // 关闭弹窗
    closeCheck() {
      this.batchDialog = false;
    },
    // 取消关闭弹层
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.batchDialog = false;
    },
    // 点击导航链接
    clickMenu(item, index) {
      Object.assign(item, { parentIndex: index });
      this.$store.commit("selectMenu", item);
      console.log(item);
      // this.$store.commit("selectMenu", item);
    },
    // 提交表单
    applyBatch(formName) {
      let params = {};
      let _this = this;

      this.$refs[formName].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          _this.isApply = true;
          if (_this.batchForm.oldpwd) {
            params.oldPasswrod = _this.batchForm.oldpwd;
          }
          if (_this.batchForm.newPwd) {
            params.password = _this.batchForm.newPwd;
          }
          if(_this.batchForm.firmPwd){
             params.confirmPassword = _this.batchForm.firmPwd;
          }
          try {
            let response;
            response = await editPassword(params);
            if (response.statusCode == 200) {
              console.log(response);
              _this.$message.success('密码修改成功');
              _this.batchDialog = false;
              localStorage.removeItem("Authorization");
              window.location.reload();
            } else {
              _this.$message.error(response.errors);
            }
            _this.isApply = false;
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取菜单
    async GetUserMenu() {
      let _this = this;
      let params = {};
      let response = await adminUserMenu(params);
      if (response.statusCode == 200) {
        response.data.forEach((item, index, arr) => {
          item.index = index + 1 + "";
          for (let k in item.child) {
            item.child[k].index = index + 1 + "-" + (k + 1);
          }
        });
      } else {
        _this.$message({
          type: "error",
          message: response.errors,
        });
      }
      _this.dataList = response.data;
      console.log(response.data);
    },
    //局部刷新
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/common.scss";
.btn-group {
  padding: 12px 30px;
  -webkit-box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  border-radius: 0 0 4px 4px;
}
.home-page {
  background: #f2f2f2;
  .bg-active,
  .is-active {
    background: #2468f2 !important;
  }
}

.page_box {
  position: relative;
  padding: 5px 20px;
  margin: 0;
  background: #f2f2f2;
}
.el-container {
  width: 100vw;
  height: 100vh;
}
.aside {
  z-index: 999;
  background: $--container-style;
}
.el-menu {
  background: $--container-style;
}
.el-menu-item {
  color: #fff;
  font-size: 16px;
}
.el-submenu .el-menu-item {
  min-width: 0;
}
.el-menu-item:hover {
  background-color: #2468f2 !important;
}
.hd-title {
  border-bottom: 2px solid rgba(240, 241, 243, 0.5);
}

.menuImg {
  align-items: center;
  width: 1.25rem;
  //  height: 1.25rem;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
    padding-right: 7px;
  }
}
.menuImg-hd {
  padding-left: 1.25rem;
  align-items: center;
  //  width: 12.5rem;
  height: 3.5rem;
  img {
    width: 100%;
    height: 100%;
    padding-right: 7px;
  }
}
.el-main {
  padding: 0;
}
.hd-user {
  justify-content: space-between;
  // align-items: center;
  padding: 0 20px;
  // padding-left: 11%;
  box-sizing: border-box;
  height: 56px;
  line-height: 56px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.fixed {
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  color: #fff;
  width: 100%;
  height: 56px;
  line-height: 56px;
  overflow: hidden;
}
.home-header {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
</style>
<style>
.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #fff !important;
}
.menuGroup .el-menu-item-group__title {
  padding: 0 !important;
}
.el-table__body .el-button {
  padding: 0 !important;
}
</style>