<template>
  <div class="page_box">
    <page-title-bar :title="title"></page-title-bar>
    <div class="bd-bar">
      <div class="menu-add">
        <el-button type="primary" @click.stop="newMenu">新增部门</el-button>
      </div>
      <div class="table_page" v-loading="loading">
        <el-table
          :data="tableData"
          style="width: 100%"
          row-key="id"
          :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
          v-if="tableData.length > 0"
          ref="tableTreeRef"
          :expand-row-keys="ids"
        >
          <ex-table-column
            prop="departmentName"
            label="部门名称"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          />
          <ex-table-column
            label="排序"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="">{{ scope.row.sort }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            label="部门负责人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="">{{ scope.row.depManager }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            label="成员数"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="">{{ scope.row.membersNum }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            label="创建时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            prop="createTime"
          >
          </ex-table-column>
          <ex-table-column
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            fixed="right"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleuserEdit(scope.row)"
              >
                修改
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="handleuserDelete(scope.row)"
              >
                删除
              </el-button>
            </template>
          </ex-table-column>
        </el-table>
        <el-table :data="tableData" style="width: 100%" row-key="id" v-else>
          <el-table-column prop="menu_name" label="部门名称" min-width="200" />
          <el-table-column label="排序" min-width="300"> </el-table-column>
          <el-table-column label="部门负责人" min-width="300">
          </el-table-column>
          <el-table-column label="成员数" min-width="300"> </el-table-column>
          <el-table-column label="创建时间" min-width="300"> </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="200">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleuserEdit(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click="handleuserDelete(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 新增部门 -->
    <el-dialog
      class="batch-dialog n3"
      :title="batchForm.typeNmber ? '编辑部门' : '新增部门'"
      :visible.sync="batchDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('batchForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="batchForm"
          :inline="true"
          :model="batchForm"
          :rules="batchRules"
          class="batchForm3"
        >
          <el-form-item label="上级部门" prop="newPwd" label-width="130px">
            <el-cascader
              :props="{
                checkStrictly: true,
                emitPath: false,
                value: 'id',
                label: 'departmentName',
              }"
              v-model="batchForm.newPwd"
              :options="roleid"
              :key="countindex"
              @change="optionlist"
              :show-all-levels="false"
              style="width: 434px"
              placeholder="请选择部门"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="部门名称" prop="menuname" label-width="130px">
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.menuname"
              placeholder="请输入内容"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="部门负责人" prop="userId" label-width="130px">
            <!-- <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.menuname"
              placeholder="请输入或选择（支持添加多个）"
              show-word-limit
            ></el-input> -->
            <el-select
              v-model="batchForm.userId"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="请输入或选择(支持添加多个)"
              :remote-method="remoteMethod"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in options"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="显示排序" prop="sort" label-width="130px">
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.sort"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTitleBar from "@/components/page-title-bar.vue";
import {
  departmentList,
  addDepartment,
  departmentDepManagerList,
  departmentEdit,
  departmentDelete,
} from "@/api/report";
export default {
  components: { pageTitleBar },
  inject: ["reload"],
  data() {
    return {
      countindex: 0,
      roleid: [],
      title: "部门管理",
      isApply: false,
      loading: false,
      tableData: [],
      batchDialog: false,
      batchForm: {
        menuType: "",
        newPwd: "",
        menuname: "",
        terminalType: "",
        menubarType: "",
        sort: "",
        userId: "",
        typeNmber: 0,
        gid: "",
      },
      options: [],
      list: [],
      batchRules: {
        menuname: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
      },
      menuType_list: [
        { value: 3, label: "目录" },
        { value: 0, label: "菜单" },
        { value: 1, label: "功能" },
      ],
      terminal_list: [
        { value: 2, label: "小程序+PC" },
        { value: 1, label: "小程序" },
        { value: 3, label: "PC" },
      ],
      menubar_list: [
        { value: 1, label: "是" },
        { value: 2, label: "否" },
      ],
      ids: [],
    };
  },
  watch: {
    tableData(val, index) {
      let _this = this;
      this.$nextTick(() => _this.unFoldAll(val));
    },
  },
  mounted() {
    this.getData();
    this.getDepartmentList();
  },
  methods: {
    unFoldAll(val) {
      // let queryResult =this.$refs.tableTreeRef.$el.children[2].querySelectorAll("tr");
      // console.log(queryResult);
      // for (let i = 0; i < queryResult.length; i++) {
      //  let item = queryResult[i];
      //   item.style.display = "";
      //   let classList = item.querySelectorAll("td > div > div")[0].classList;
      //   classList.contains("el-table__expand-icon") &&
      //     item
      //       .querySelectorAll("td > div > div")[0]
      //       .classList.add("el-table__expand-icon--expanded");
      // }
      console.log(val, "val");
      let arr = val;
      arr.forEach((item, index) => {
        // if (item.children) {
        this.ids.push(item.id);
        for (let it of item.children) {
          this.ids.push(it.id);
          if (it.children) {
            for (let qu of it.children) {
              this.ids.push(qu.id);
              // this.unFoldAll(qu.children);
            }
          }
        }
        // }
      });
      console.log(this.ids);
    },
    handleuserEdit(row) {
      console.log(row);
      this.batchDialog = true;
      this.batchForm.gid = row.id;
      this.batchForm.typeNmber = 1;
      this.batchForm.newPwd = row.parentId;
      this.batchForm.sort = row.sort;
      if (row.depManagerId) {
        this.batchForm.userId = row.depManagerId.split(",") || "";
      } else {
        this.batchForm.userId = "";
      }

      console.log(this.batchForm.newPwd);
      this.batchForm.menuname = row.departmentName;
    },
    // 菜单管理
    remoteMethod(query) {
      console.log(query);
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;

          this.options = this.list.filter((item) => {
            console.log(item);
            return (
              item.userName.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    closeCheck(form) {
      this.$refs[form].resetFields();
      this.batchForm = {
        menuType: "",
        newPwd: "",
        menuname: "",
        terminalType: "",
        menubarType: "",
        sort: "",
        userId: "",
        typeNmber: 0,
        gid: "",
      };
    },
    cancel(form) {
      this.batchDialog = false;
    },
    applyBatch(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let depManagerId = _this.batchForm.userId.join(",");
          let params = {
            departmentName: _this.batchForm.menuname,
            sort: _this.batchForm.sort,
            depManager: depManagerId,
            parentId: _this.batchForm.newPwd,
          };
          console.log(_this.batchForm.userId);
          try {
            let response;
            if (_this.batchForm.typeNmber) {
              params.id = _this.batchForm.gid;
              response = await departmentEdit(params);
            } else {
              response = await addDepartment(params);
            }
            console.log(response.data);
            if (response.statusCode == 200) {
              _this.batchDialog = false;
              _this.getData();
              _this.reload();
              _this.$message({
                type: "success",
                message: "添加成功",
              });
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });

      console.log(_this.batchForm.newPwd);
    },
    newMenu() {
      this.batchDialog = true;
      this.batchForm.typeNmber = 0;
    },
    // 获取部门列表
    async getData() {
      let _this = this;
      let params = {
        pageIndex: 1,
        pageSize: 100000,
      };
      _this.loading = true;
      _this.$ajax({
        url: _this.globalData.orgHost + "department/list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.tableData = res.data;
            let new_data = [];
            for (let item of res.data) {
              item.value = item.id;
              item.label = item.departmentName;
              item.children = item.children;
              if (item.children) {
                for (let it of item.children) {
                  it.value = it.id;
                  it.label = it.departmentName;
                  it.children = it.children;
                  if (it.children) {
                    for (let qu of it.children) {
                      qu.value = qu.id;
                      qu.label = qu.departmentName;
                      qu.children = "";
                    }
                  }
                }
              }
              new_data.push(item);
            }
            console.log(new_data, "这是上级部门");
            _this.roleid = new_data;
           
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.loading = false;
        },
      });
      // let response = await departmentList(params);
      // if (response.statusCode == 200) {
      // } else {
      //   _this.$message({
      //     type: "error",
      //     message: response.errors,
      //   });
      // }
      // setTimeout(() => {
      //   _this.loading = false;
      // }, 1000);
      // console.log(response);
    },
    // 获取部门负责人
    async getDepartmentList() {
      let _this = this;
      let params = {};
      try {
        let response = await departmentDepManagerList(params);
        console.log(response.data, "获取");
        if (response.statusCode == 200) {
          _this.list = response.data;
          _this.options = _this.list;
        }
      } catch (error) {
        new Error(error);
      }
    },
    // 删除部门
    handleuserDelete(row) {
      let _this = this;
      if (row.membersNum != 0) {
        _this.$message({
          type: "success",
          message: "不可删除该部门，已存在用户使用,请先修改关系再删除",
        });
        return;
      }
      let params = {
        id: row.id,
      };
      console.log(row);
      _this
        .$confirm("确认删除部门？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          let response = await departmentDelete(params);

          if (response.statusCode == 200) {
            _this.$message({
              type: "success",
              message: "删除成功",
            });
            _this.getData();
          } else {
            _this.$message({
              type: "error",
              message: response.errors,
            });
          }
          console.log(response);
        })
        .catch(() => {});
    },
    // 选择上级菜单
    async optionlist() {},
  },
};
</script>

<style scoped>
.dialog-cont {
  padding: 30px 0 20px;
}
.menu-add {
  padding: 20px 0px;
}
.bd-bar {
  padding: 20px;
}
</style>
<style>
.btn-group {
  padding: 12px 30px;
  -webkit-box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  border-radius: 0 0 4px 4px;
}
</style>