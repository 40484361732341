import { render, staticRenderFns } from "./followUpRecord.vue?vue&type=template&id=afb4a11c&scoped=true&"
import script from "./followUpRecord.vue?vue&type=script&lang=js&"
export * from "./followUpRecord.vue?vue&type=script&lang=js&"
import style0 from "./followUpRecord.vue?vue&type=style&index=0&id=afb4a11c&prod&scoped=true&lang=css&"
import style1 from "./followUpRecord.vue?vue&type=style&index=1&id=afb4a11c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afb4a11c",
  null
  
)

export default component.exports