import axios from "axios";
import qs from "qs";
import router from "@/router";
import Vue from "vue"
import {Message} from 'element-ui'
// if (process.env.NODE_ENV == 'development') {
//
//   axios.defaults.baseURL = 'http://api.test.gwyapp.net/';
//
// } else if (process.env.NODE_ENV == 'debug') {
//
//   axios.defaults.baseURL = 'https://api.gwyapp.net/';
//
// } else if (process.env.NODE_ENV == 'production') {
//
//   // axios.defaults.baseURL = 'https://api.gwyapp.net/';
//
// axios.defaults.baseURL = '/httpServer/';
// //
// }

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.timeout = 60000;
axios.defaults.withCredentials = false;
axios.interceptors.request.use(
  function (config) {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    config.headers["Authorization"] = "Bearer " + localStorage.getItem('Authorization');
    // {headers:{'Content-Type' : 'application/x-www-form-urlencoded'}}
    if (config.method === "post" || "put") {
      // post请求时，处理数据
      // config.data = qs.stringify(config.data);
    }
    return config;
  },
  function (error) {
    loadinginstace.close();
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    // 处理响应数据
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  function (error) {
    // 处理响应失败
    // console.log('进来了',error.response)
    if (error.response.status == 401) {
       Message.error("登录失效,请重新登录");
       router.push("/");
      localStorage.isLogin = false;
      return Promise.resolve(error);
    }
    return Promise.reject(error);
  }
);

export function get(url, params) {
  return new Promise((resolve, reject) => {
    // axios
    //   .get(this.globalData.baseHost + "api/v1/security/sign/random")
    //   .then(res => {
    //     var data = res.data.data;
    //     var timestamp = new Date().getTime();
    //     var key = data.key;
    //     var str = this.MD5(
    //       timestamp +
    //       key +
    //       this.MD5(data.x.split(".").reverse()[0]) +
    //       "53aae4b0-4881-4749-a0c7-7bd4459299a3"
    //     );

        axios
          .get(this.globalData.apiHost + url, {
            // headers: {
            //   sign: str,
            //   "sign-key": key,
            //   "sign-timestamp": timestamp
            // },
            params: params
          })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err.data);
          });
      // })
      // .catch(err => { });
  });
}
export function post(url, params) {
  return new Promise((resolve, reject) => {
    // axios
    //   .get(Vue.prototype.globalData.baseHost + "api/v1/security/sign/random")
    //   .then(res => {
    //     var data = res.data.data;
    //     var timestamp = new Date().getTime();
    //     var key = data.key;
      
        // var str = this.MD5(
        //   timestamp +
        //   key +
        //   this.MD5(data.x.split(".").reverse()[0]) +
        //   "53aae4b0-4881-4749-a0c7-7bd4459299a3"
        // );
        // console.log(Vue.prototype.globalData.orgHost + url,params) ;
        // {
        //   headers: {
        //     sign: str,
        //     "sign-key": key,
        //     "sign-timestamp": timestamp
        //   }
        // }
        axios
          .post(Vue.prototype.globalData.orgHost + url, params,)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err.data);
          });
      // })
      // .catch(err => { });
  });
}
export function put(url, id, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url + id, params)
      .then(res => {
        resolve(res.data);
        console.log(res.data)
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

export function Delete(url, id, params) {
  return new Promise((resolve, reject) => {
       console.log(params);
      //  let forms = new FormData()
      //  forms.append('id', params.id)
    axios({ 
        method:'delete',
        url:url + id,
        data:params
    })
      .then(res => {
        resolve(res.data);
        console.log(res.data)
      })
      .catch(err => {
        reject(err.data);
      });
  });
}
// 机构后台api
/**
*  @param {object} config
* 
*/
export function orgGwy(config) {
  return new Promise((resolve, reject) => {
    if (config.url) {
      config.url = Vue.prototype.globalData.orgHost + config.url;
    }
    let access_token = "";
    let setIntervalS = setInterval(() => {
      access_token = localStorage.getItem('Authorization');
      if (access_token) {
        clearInterval(setIntervalS);
        axios(config)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err.data);
          });
      }
    }, 1000);
  });
}
export function orgQyw(config) {
  return new Promise((resolve, reject) => {
    if (config.url) {
      config.url = Vue.prototype.globalData.orgCommonHost + config.url;
    }
    let access_token = "";
    let setIntervalS = setInterval(() => {
      access_token = localStorage.getItem('Authorization');
      if (access_token) {
        clearInterval(setIntervalS);
        axios(config)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err.data);
          });
      }
    }, 1000);
  });
}
// export function tkExport(config) {
//   return new Promise((resolve, reject) => {
//     if (config.url) {
//       config.url = Vue.prototype.globalData.orgHost + config.url;
//     }
//     let params2 = [];
//     if (config.method == 'get') {
//       for (var key in config.params) {
//         params2.push(key+"="+ encodeURI(config.params[key]))
//       };
//       let postData = params2.join('&');
//       location.assign( config.url+'?'+ postData)
//       resolve(true);
//     } else {
//       reject(false);
//     }
//   })
  // let access_token = "";
  // let setIntervalS = setInterval(() => {
  //   access_token = localStorage.getItem('Authorization');
  //   if (access_token) {
  //     clearInterval(setIntervalS);
  //     axios(config)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err.data);
  //       });
  //   }
  // }, 1000);
// }