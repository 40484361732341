import { render, staticRenderFns } from "./glueDetails.vue?vue&type=template&id=3a4d6986&scoped=true&"
import script from "./glueDetails.vue?vue&type=script&lang=js&"
export * from "./glueDetails.vue?vue&type=script&lang=js&"
import style0 from "./glueDetails.vue?vue&type=style&index=0&id=3a4d6986&prod&scoped=true&lang=css&"
import style1 from "./glueDetails.vue?vue&type=style&index=1&id=3a4d6986&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4d6986",
  null
  
)

export default component.exports