<template>
  <el-dialog
    title="线索详情"
    :visible.sync="dialogDetail2"
    :close-on-click-modal="false"
    width="980px"
    @close="closeDetail"
  >
    <div class="page_box">
      <div class="glue-details">
        <div class="flex flex-center">
          <div class="company-name">{{ cluelist.companyName }}</div>
          <div  v-if="!detailType">
            <el-dropdown
              style="padding-left: 10px; margin-right: 14px"
              trigger="click"
              @command="MenuMore"
            >
              <span class="el-dropdown-link">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="beforeHandleCommand('transferTohim', cluelist)"
                  >转给他人</el-dropdown-item
                >
                <el-dropdown-item
                  :command="beforeHandleCommand('returnCluePool', cluelist)"
                  >退回线索池</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              size="small"
              type="primary"
              @click="transferClient(cluelist)"
              >转客户</el-button
            >
            <el-button size="small" type="primary" @click="redact(cluelist)"
              >编辑</el-button
            >
            <el-select
              v-model="form.followupStatus"
              style="width: 100px; margin-left: 10px"
              placeholder="请选择"
              size="small"
              @change="getFollowup"
            >
              <el-option
                v-for="(item, index) in clueFollowupStatus"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class=" information">
          <div style="margin-right: 8px" class="flex">
            <div class="flex info-list">
              <div style="width:70px">行业：</div>
              <div>{{ cluelist.industry }}</div>
            </div>
             <div class="flex info-list">
              <div style="width:70px">年营收：</div>
              <div>{{ cluelist.annualRevenue }}</div>
            </div>
             <div class="flex info-list">
              <div style="width:70px">电话人：</div>
              <div v-if="cluelist.contactName">
                {{ cluelist.contactName }}/{{ cluelist.contactPhone }}
              </div>
            </div>
           
          </div>
          <div class="flex">
             <div class="flex info-list">
              <div style="width:70px">负责人：</div>
              <div>{{ cluelist.departmentName }}-{{ cluelist.chargeName }}</div>
            </div>
            <div class="flex info-list">
              <div style="width:70px">下次跟进：</div>
              <div>{{ cluelist.followupDateStr }}</div>
            </div>
            <div class="flex info-list">
              <div style="width:70px">最近更新：</div>
              <div>{{ cluelist.updateTime }}</div>
            </div>
          </div>
          <!-- <div>
           
            
          </div> -->
        </div>
      </div>
      <div>
        <el-radio-group
          v-model="radio"
          style="margin-bottom: 12px"
          size="small"
        >
          <el-radio-button :label="1">跟进动态</el-radio-button>
          <el-radio-button :label="2">详情信息</el-radio-button>
        </el-radio-group>
        <div class="label-button" v-if="radio == 1">
          <button :class="{ active: number == -1 }" @click="labelBut(-1)">
            全部
          </button>
          <button :class="{ active: number == 2 }" @click="labelBut(2)">
            跟进记录
          </button>
          <button :class="{ active: number == 1 }" @click="labelBut(1)">
            系统动态
          </button>
          <button
            style="float: right"
            class="write-follow-up"
            v-if="!detailType"
            @click="followUp(cluelist)"
          >
            写跟进
          </button>
        </div>
      </div>
      <div class="followUp-dynamics" v-if="radio == 1 && list.length">
        <div v-for="(item, index) in list" :key="index">
          <div>
            {{ item.createTime }} {{ item.departmentName }} -
            {{ item.createdName }}
            <em
              style="color: #f9a91e"
              v-if="item.followupType == 1 && item.followAs"
              >【{{ selectDictlabel(FollowAs, item.followAs) }}】</em
            >
            <em
              style="color: #f9a91e"
              v-if="item.followupType == 2 && item.followupMethod"
              >【{{
                selectDictlabel(followupMethod, item.followupMethod)
              }}】</em
            >
          </div>
          <div v-if="item.chargeRecord">负责人：{{ item.chargeRecord }}</div>
          <div v-if="item.followupContent">
            跟进内容：{{ item.followupContent }}
          </div>
          <div v-if="item.followupStatusRecord">
            跟进状态：{{ item.followupStatusRecord }}
          </div>
        </div>
      </div>
      <el-empty
        description="没有数据了~"
        v-if="list.length == 0 && radio == 1"
      ></el-empty>
      <div class="leadTo-content" v-if="radio == 2">
        <div class="leadTo-title">
          <div class="leadTo-desc">基础信息</div>
        </div>
        <div class="flex flex-box">
          <div class="h1" style="margin-right: 55px; margin-top: 20px">
            <div class="flex">
              <div class="flex leadTo-list">
                <div>公司名称</div>
                <div>{{ cluelist.companyName }}</div>
              </div>
              <div class="flex leadTo-list">
                <div>联系人姓名</div>
                <div>{{ cluelist.contactName }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="flex leadTo-list">
                <div>联系电话</div>
                <div>{{ cluelist.contactPhone }}</div>
              </div>
              <div class="flex leadTo-list">
                <div>联系人职务</div>
                <div>{{ cluelist.contactJob }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="flex leadTo-list">
                <div>所属行业</div>
                <div>{{ cluelist.industry }}</div>
              </div>
              <div class="flex leadTo-list">
                <div>年营收</div>
                <div>{{ cluelist.annualRevenue }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="flex leadTo-list">
                <div>年税收</div>
                <div>{{ selectDictlabel(AnnualTax, cluelist.annualTax) }}</div>
              </div>
              <div class="flex leadTo-list">
                <div>所在地区</div>
                <div v-show="cluelist.provice">
                  {{ cluelist.provice }}/{{ cluelist.city }}/{{
                    cluelist.borough
                  }}
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex leadTo-list">
                <div>详细地址</div>
                <div>{{ cluelist.address }}</div>
              </div>
              <div class="flex leadTo-list">
                <div>备注</div>
                <div>{{ cluelist.remark }}</div>
              </div>
            </div>
          </div>
          <!-- <div>
          
          </div> -->
        </div>
      </div>
      <div class="leadTo-content" v-if="radio == 2">
        <div class="leadTo-title">
          <div class="leadTo-desc">系统信息</div>
        </div>
        <div class="flex flex-box">
          <div class="h1" style="margin-right: 55px">
            <div class="flex leadTo-list">
              <div style="width: 90px">领取/分配人</div>
              <div>{{ cluelist.assignerName }}</div>
            </div>
            <div class="flex leadTo-list">
              <div style="width: 90px">领取/分配时间</div>
              <div>{{ cluelist.assignerDate }}</div>
            </div>
          </div>
          <div>
            <div class="flex leadTo-list">
              <div style="width: 90px">最近更新人</div>
              <div>{{ cluelist.updatedName }}</div>
            </div>
            <div class="flex leadTo-list">
              <div style="width: 90px">最近更新时间</div>
              <div>{{ cluelist.updateTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  assetsClueAssetClueFollow,
  commonType,
  assetsClueFollowupStatus,
} from "@/api/report";
import followUpRecordVue from "@/view/CRM/followUpRecord.vue";
export default {
  inject: ["reload"],
  props: {
    dialogDetail: Boolean,
    cluelist: Object,
    clueLoading: Boolean,
    detailType:Boolean,
  },
  data() {
    return {
      // 是否显示弹层
      active: "2",
      radio: 1,
      number: -1,
      dialogDetail2: false,
      options: [
        {
          label: "请选择",
          value: "-1",
        },
        {
          label: "有意向",
          value: "1",
        },
        {
          label: "明显拒绝",
          value: "2",
        },
        {
          label: "意向不明",
          value: "3",
        },
        {
          label: "未联系上",
          value: "4",
        },
        {
          label: "无效信息",
          value: "5",
        },
      ],
      form: {
        followupStatus: "",
      },
      list: [],
      clueFollowupStatus: "",
      FollowupStatus: "",
      followupMethod: "",
      Grade: "",
      commonlist: "",
      Industry: "",
      Product: "",
      AnnualTax: "",
      FollowAs: "",
      intentionlevelList: [],
    };
  },
  watch: {
    dialogDetail(val) {
      console.log(val);
      if (!val) {
        this.$emit("closeDetails");
      }
      this.dialogDetail2 = val;
    },
    cluelist(val) {
      console.log(val);
      this.form.followupStatus = val.followupStatus;
      if (val.id) {
        this.getData();
      }
    },
  },
  mounted() {
    this.getCommon();
  },
  methods: {
    followUp(col) {
      col.active=1
      console.log(col);
      this.$emit("followUp", col);
    },
    // 转客户
    transferClient(col) {
      this.$emit("transferClient", col);
    },
    // 跟进状态快捷编辑
    async getFollowup() {
      let _this = this;
      console.log("shkghs");
      try {
        let params = {
          id: _this.cluelist.id,
          followupStatus: _this.form.followupStatus,
        };
        let response = await assetsClueFollowupStatus(params);
        if (response.statusCode == 200) {
          _this.$message({
            type: "success",
            message: "跟进状态修改成功",
          });
        }
      } catch (error) {}
    },
    // 编辑
    redact(col) {
      this.$emit("editRedact", col);
    },
    beforeHandleCommand(flag, command) {
      return {
        flag: flag,
        command: command,
      };
    },
    selectDictlabel(lab, value) {
      let _this = this;
      let label = this.ll.selectDictLabel(lab, value);
      return label;
    },
    // 公共类型
    // 公共类型
    async getCommon() {
      let _this = this;
      let params = {};
      try {
        let response = await commonType();
        console.log(response);
        if (response.statusCode == 200) {
          let {
            FollowupMethod,
            ClueFollowupStatus,
            Grade,
            Industry,
            Product,
            AnnualTax,
            FollowupStatus,
            FollowAs,
            Intentionlevel,
          } = response.data;
          _this.clueFollowupStatus = ClueFollowupStatus;
          _this.followupMethod = FollowupMethod;
          _this.Grade = Grade;
          _this.Industry = Industry;
          _this.Product = Product;
          _this.AnnualTax = AnnualTax;
          _this.FollowupStatus = FollowupStatus;
          _this.FollowAs = FollowAs;
          _this.intentionlevelList = Intentionlevel;
          // console.log(FollowupMethod,ClueFollowupStatus);
        }
      } catch (error) {
        console.log(error);
      }
    },
    MenuMore(flag) {
     
      flag.command.index=2;
      if (flag.flag == "transferTohim") {
        this.$emit("transferTohim", flag.command);
      } else if (flag.flag == "returnCluePool") {
        this.$emit("returnCluePool", flag.command);
      }
    },
    labelBut(val) {
      console.log(1);
      this.number = val;
      this.getData();
    },
    closeDetail() {
      this.$emit("closeDetails");
    },
    // assets-clue/asset-clue-follow
    async getData() {
      let _this = this;
      let params = {
        id: _this.cluelist.id,
      };
      if (_this.number != -1) {
        params.followupType = _this.number;
      }
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/asset-clue-follow",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data } = res;
            console.log(data);
            _this.list = data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
  },
};
</script>

<style scoped>
.leadTo-list {
  /* width: 193px; */
  /* line-height: 40px; */
  padding-bottom: 20px;
  padding-left: 22px;
}
.leadTo-list div:first-child {
  width: 70px;
  padding-right: 26px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #999999;
}
.leadTo-list div:last-child {
  width: 193px;
  padding-left: 22px;
}
.info-list div:last-child {
     width: 193px;
}
.leadTo-desc {
  padding-left: 6px;
  border-left: 3px solid #1890ff;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
}
.leadTo-title {
  width: 100%;
  padding: 10px 0 10px 4px;
  /* height: 40px;
  line-height: 40px; */
  margin-bottom: 12px;
  background: #f3f4f7;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  box-sizing: border-box;
}
.write-follow-up {
  background: #1890ff !important;
  color: #fff !important;
}
.followUp-dynamics {
  margin-top: 12px;

  width: 884px;
  height: 400px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #f1f1f1;
  overflow-y: auto;
}
.followUp-dynamics > div {
  padding: 16px 24px;
  line-height: 22px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #333333;
  border-bottom: 1px solid #f1f1f1;
}
.label-button button {
  margin-right: 10px;
  width: 70px;
  height: 24px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #666666;
  border: none;
  outline: none;
  background: #f5f5f5;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  cursor: pointer;
}
.label-button .active {
  background: #e5f3ff;
  border-radius: 2px 2px 2px 2px;
  color: #1890ff;
  opacity: 1;
  border: 1px solid #1890ff !important;
}
.info-list > div {
  line-height: 20px;
  padding-bottom: 8px;
}
.info-list div:first-child {
  color: #999999;
}
.information {
  padding: 10px 0;
  width: 80%;
}
.company-name {
  width: 372px;
  font-size: 20px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
  line-height: 35px;
}
.flex-center {
  justify-content: space-between;
  align-items: center;
}
.page_box {
  padding: 26px 48px !important;
  background: #fff;
}
.el-dropdown-link {
  color: #1890ff;
}
</style>
<style>
.glue-details .el-input__inner {
  background: rgba(249, 169, 30, 0.1);
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #f9a91e;
  color: #f9a91e;
}
.glue-details .el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #f9a91e !important;
}
.glue-details .el-select .el-input__inner:focus {
  border-color: #f9a91e !important;
}
.glue-details .el-select .el-input .el-select__caret {
  color: #f9a91e !important;
}
</style>