<template>
  <div class="page_box">
    <page-title-bar :title="title"></page-title-bar>
    <div class="bd-bar">
      <el-form
        ref="SearchForm"
        :inline="true"
        :model="form"
        label-width="80px"
        class="search-bar_n"
      >
        <div class="bd-bar2">
          <span class="bd-name">搜索：</span>
          <el-form-item label="" prop="type">
            <el-select
              v-model="form.type"
              style="width: 150px"
              placeholder="来源"
              size="small"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="operateType">
            <el-select
              v-model="form.operateType"
              style="width: 150px"
              placeholder="跟进方式"
              size="small"
            >
              <el-option
                v-for="(item, index) in followupMethod"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="taskTitle">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.taskTitle"
              placeholder="跟进人"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="85px" label="跟进时间段" prop="auditDate">
            <el-date-picker
              v-model="form.auditDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getData(1)"
              >查询</el-button
            >
            <el-button size="small" @click="reset('SearchForm')"
              >重置</el-button
            >
          </el-form-item>
          <!-- <el-form-item style="float: right" v-if="tabStatus == 1">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click.stop="addTask"
              >新增任务</el-button
            >
          </el-form-item> -->
        </div>
      </el-form>
      <div class="table_page">
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-loading="tabLoading"
          v-if="advanceDataList.length > 0"
        >
          <ex-table-column
            key="2"
            prop="name"
            label="跟进对象"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.source"
                  >【{{ selectSource(scope.row.source) }}】</span
                >
                <em>{{ scope.row.followName }}</em>
                <em>{{ scope.row.demandName }}</em>
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="3"
            prop="createTime"
            label="跟进时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="4"
            prop="createdName"
            label="跟进人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
          </ex-table-column>
          <ex-table-column
            key="5"
            prop="followupMethod"
            label="跟进方式"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ selectDictlabel(followupMethod, scope.row.followupMethod) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="6"
            prop="followupContent"
            label="跟进内容"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="fllowup-content">{{ scope.row.followupContent }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="7"
            prop="followupStatusRecord"
            label="跟进备注"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row.chargeRecord || scope.row.followupStatusRecord"
              >
                {{ scope.row.chargeRecord }}
                {{ scope.row.followupStatusRecord }}
              </div>
            </template>
          </ex-table-column>
        </el-table>
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-loading="tabLoading"
          v-else
        >
          <el-table-column
            key="16"
            prop=""
            label="跟进对象"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="17"
            prop="title"
            label="跟进时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="type"
            label="跟进人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="reward"
            label="跟进方式"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="20"
            label="跟进内容"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="orderNum"
            label="跟进备注"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="this.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import pageTitleBar from "@/components/page-title-bar.vue";
import { followUpRecord, commonType, clientAssetFollow } from "@/api/report";
let optionsall = [];
export default {
  inject: ["reload"],
  components: { pageTitleBar },
  data() {
    return {
      title: "跟进记录",
      tabStatus: "1",
      page: 1,
      perPage: 10,
      tabLoading: false,
      count: 0,
      options: [
        {
          label: "线索",
          value: 1,
        },
        {
          label: "客户",
          value: 2,
        },
        {
          label: "需求",
          value: 3,
        },
      ],

      form: {
        taskTitle: "",
        type: "",
        operateType: "",
        auditDate: [],
      },
      followupMethod: "",
      Source: "",
      advanceDataList: optionsall,
      selectionItem: [],
    };
  },
  mounted() {
    this.getData(1);
    this.getCommon();
  },
  methods: {
    selectSource(value) {
      let val;
      switch (value) {
        case 1:
          val = "线索";
          break;
        case 2:
          val = "客户";
          break;
        case 3:
          val = "需求";
          break;
      }
      return val;
    },
    selectDictlabel(lab, value) {
      let _this = this;
      let label = this.ll.selectDictLabel(lab, value);
      return label;
    },
    MenuMore(flag) {
      console.log(flag);
    },
    handleClick() {},
    // 查询数据
    handleSizeChange(val) {
      this.perPage = val;
      this.page = 1;
      this.getData(this.page, this.perPage);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData(this.page, this.perPage);
    },
    // 多选项目
    handleSelectionChange(val) {
      this.selectionItem = val;
      console.log(val);
    },
    // 获取跟进方式
    // 公共类型
    async getCommon() {
      let _this = this;
      let params = {};
      try {
        let response = await commonType();
        console.log(response);
        if (response.statusCode == 200) {
          let { FollowupMethod, Source } = response.data;
          _this.followupMethod = FollowupMethod;
          _this.Source = Source;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 重置
    reset(formName) {
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.getData(1);
    },
    // 获取跟进列表数据
    async getData(page, perPage) {
      let _this = this;
      if (page == 1) {
        _this.page = 1;
      }
      _this.advanceDataList = [];

      // try {
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      let params = {
        pageIndex: index,
        pageSize: size,
        FollowupMethod: _this.form.operateType,
        keyword: _this.form.taskTitle,
        Source: _this.form.type,
        CreateTimeStart: _this.form.auditDate[0] || "",
        CreateTimeEnd: _this.form.auditDate[1] || "",
        FollowupType: 2,
      };
      _this.tabLoading = true;
      _this.$ajax({
        url: _this.globalData.orgHost + "client/asset-follow",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let {data,totalCount} = res.data;
          _this.advanceDataList = data;
          _this.count = totalCount;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });
      //   let response = await clientAssetFollow(params);
      //   if (response.statusCode == 200) {
      //      let {data,totalCount} = response.data;
      //     _this.advanceDataList = data;
      //     _this.count = totalCount;
      //   }
      //    _this.tabLoading = false;
      // } catch (error) {
      //   console.log(error);
      // }
    },
  },
};
</script>

<style scoped>
.bd-bar {
  padding: 20px 20px 0;
  background-color: #fff;
}
.table_page .page {
  padding-bottom: 10px;
}
.fllowup-content {
  width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.search-bar_n {
  padding: 20px 0;
  margin-bottom: 0;
}
</style>
<style>
.page_box .el-table th.el-table__cell {
  background: #f5f7fa;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-table .cell {
  white-space: nowrap !important;
  width: fit-content !important;
}
.dotted-line {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  margin: 0 auto;
  background: url("../../assets/image/dotted-line.png") no-repeat center;
  background-size: 100%;
  /* margin: -40px 20px 22.5px 2px; */
}
.el-form-item {
  margin-bottom: 10px;
}
</style>