/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router';
import axios from 'axios';
Vue.use(ElementUI)
Vue.config.productionTip = false
import { get, post, put, orgGwy,Delete } from './utils/api'

Vue.use(VueRouter)

var isformal = 1;
if (isformal == 1) {
  Vue.prototype.globalData = {
    orgHost: 'https://api.qyw.zxshowstar.com/api/',
    orgCommonHost: 'https://api.qyw.zxshowstar.com/Common/',
    baseHost: "https://basetest.gwyapp.net/",
    imghost: 'http://image.test.gwyapp.net/',
    qiniu: 'http://qny.qyw.zxshowstar.com/',
  }
  Vue.prototype.domian = 'https://image.gwyapp.net/'
} else if (isformal == 2) {
  Vue.prototype.globalData = {
    orgHost: 'https://test.qyw.zxshowstar.com/api/',
    orgCommonHost: 'https://test.qyw.zxshowstar.com/Common/',
    baseHost: "https://basetest.gwyapp.net/",
    imghost: 'http://image.test.gwyapp.net/',
    qiniu: 'http://qny.qyw.zxshowstar.com/',
  };
  Vue.prototype.domian = "http://image.test.gwyapp.net/";
}

import gwy from './js/gwy';
Vue.prototype.gwy = gwy;

import Ll from './js/ll';
Vue.prototype.ll = Ll;

import Dd from './js/dd';
Vue.prototype.dd = Dd;

import myajax from './utils/ajax.js'
Vue.prototype.$ajax = myajax;

// 解决：Uncaught (in promise) NavigationDuplicated; 
let originalPush = VueRouter.prototype.push; // 先保存一份 VueRouter.prototype.push方法
let originalRepace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
  // call(); 篡改上下文    catch(); 捕获异常 **** 
  return originalPush.call(this, location).catch(e => e);
}
VueRouter.prototype.replace = function replace(location) {
  return originalRepace.call(this, location).catch(e => e);
}
// //表格自适应
import ExTableColumn from 'ex-table-column';
Vue.component(ExTableColumn.name, ExTableColumn);
//将方法挂载到Vue原型上
Vue.prototype.get = get;
Vue.prototype.post = post;
Vue.prototype.put = put;
Vue.prototype.orgGwy = orgGwy;
Vue.prototype.Delete = Delete;

// 防重复点击
import preventReClick from './js/preventReClick.js'
Vue.use(preventReClick);

// 路由拦截登录状态
router.beforeEach((to, from, next) => {
  console.log(to.path);
  if (to.path === '/') {
    next();
  } else {
    let token = localStorage.getItem('Authorization');
    if (token === null || token === '') {
         if (to.path === '/') {
          next();
        } else {
          next('/');
        }
    } else {
      next();
    }
  }
});


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
