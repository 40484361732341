var Dd = {
    isPoneAvailable: function (str) {
        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        return myreg.test(str);
    },
    checkCardNo: function (txtval) {
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        return reg.test(txtval);
    },
    checkEmail: function (str) {
        var myreg =
            /^[a-z0-9]+([._\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
        if (!myreg.test(str)) {
            return false;
        } else {
            return true;
        }
    },
    toFixeds(val, pre) {
        const num = parseFloat(val);
        if (isNaN(num)) {
            return false;
        }
        const p = 10 ** pre;
        const value = num * p;
        let f = (Math.floor(value) / p).toString();
        let rs = f.indexOf(".");
        if (rs < 0) {
            rs = f.length;
            f += ".";
        }
        while (f.length <= rs + pre) {
            f += "0";
        }
        return f;
    },
    // ���������ֵ�
    selectDictLabel(datas, value) {
        let actions = [];
        Object.keys(datas).some((key) => {
            if (datas[key].value == "" + value) {
                actions.push(datas[key].label);
                return true;
            }
        });
        return actions.join("");
    },
    /**
     * ʱ���ʽ��
     */
    formatDate(cellValue) {
        if (cellValue == null || cellValue == "") return "";
        var date = new Date(cellValue)
        var year = date.getFullYear()
        var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    },
    // ���ڸ�ʽ��
    parseTime(time, pattern) {
        if (arguments.length === 0 || !time) {
            return null
        }
        const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
            date = time
        } else {
            if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                time = parseInt(time)
            } else if (typeof time === 'string') {
                time = time.replace(new RegExp(/-/gm), '/');
            }
            if ((typeof time === 'number') && (time.toString().length === 10)) {
                time = time * 1000
            }
            date = new Date(time)
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') {
                return ['��', 'һ', '��', '��', '��', '��', '��'][value]
            }
            if (result.length > 0 && value < 10) {
                value = '0' + value
            }
            return value || 0
        })
        return time_str
    },

    getRecentMonth_Date(n) {
        let result = '';
        const datenow = new Date();
        const dateend =
            datenow.getFullYear().toString() +
            '-' +
            (datenow.getMonth() + 1).toString() +
            '-' +
            datenow.getDate().toString();
        datenow.setMonth(datenow.getMonth() - n);
        let dyear = datenow.getFullYear();
        let dmonth = datenow.getMonth() + 1;
        dmonth = dmonth < 10 ? 0 + dmonth : dmonth;
        let dday = datenow.getDate();
        const datestart =
            dyear.toString() + '-' + dmonth.toString() + '-' + dday.toString();
        result += datestart + ',';
        result += dateend;
        console.log(datestart);
        return result;
    },
    getLastMonth_Date() {
        let result = '';
        // ��ȡ�������ڵ��·�
        const now = new Date()
        let currentMonth = now.getMonth() + 1;
        // ��õ�ǰ���4λ��
        let currentYear = now.getFullYear();
        // �ϸ��µĿ�ʼʱ��
        let beg = new Date(currentYear, currentMonth - 1, 1);
        // �ϸ��µĽ���ʱ��
        let end = new Date(currentYear, currentMonth, 0);
        let month = end.getMonth() < 10 ? '0' + end.getMonth() : end.getMonth();
        const datestart = beg.getFullYear().toString() + '-' + month.toString() + '-' + beg.getDate().toString();
        const dateend = end.getFullYear().toString() + '-' + month.toString() + '-' + end.getDate().toString();
        result += datestart + ',';
        result += dateend;
        return result;
    },
    getFirstDayOfWeek(date) {
        var day = date.getDay() || 7;
        let time = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 - day);
        return time;
    },
    GetDateStr(dayCount) {
        const dd = new Date()
        dd.setDate(dd.getDate() + dayCount) // ��ȡdayCount��������
        const y = dd.getFullYear()
        const m = (dd.getMonth() + 1) < 10 ? '0' + (dd.getMonth() + 1) : (dd.getMonth() + 1)// ��ȡ��ǰ�·ݵ����ڣ�����10��0
        const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate() // ��ȡ��ǰ���ţ�����10��0
        return y + '-' + m + '-' + d
    },
    getTime(n) {
        var now = new Date();
        var year = now.getFullYear();
        //��Ϊ�·��Ǵ�0��ʼ��,���Ի�ȡ����µ��·���Ҫ��1����
        var month = now.getMonth() + 1;
        var date = now.getDate();
        var day = now.getDay();
        console.log(date);
        //�ж��Ƿ�Ϊ����,������ǵĻ�,���ý����day-1(�������ڶ�����2-1)
        if (day !== 0) {
            n = n + (day - 1);
        } else {
            n = n + day;
        }
        if (day) {
            //����ж���Ϊ�˽�����������
            if (month > 1) {
                month = month;
            }
            //����ж���Ϊ�˽�����������,�·��Ǵ�0��ʼ��
            else {
                year = year - 1;
                month = 12;
            }
        }
        now.setDate(now.getDate() - n);
        year = now.getFullYear();
        month = now.getMonth() + 1;
        date = now.getDate();
        // console.log(n);
        var s = year + "-" + (month < 10 ? ('0' + month) : month) + "-" + (date < 10 ? ('0' + date) : date);
        return s;
    },
    /**
     * This is just a simple version of deep copy
     * Has a lot of edge cases bug
     * If you want to use a perfect deep copy, use lodash's _.cloneDeep
     * @param {Object} source
     * @returns {Object}
     */
    deepClone(source) {
        if (!source && typeof source !== "object") {
            throw new Error("error arguments", "deepClone");
        }
        const targetObj = source.constructor === Array ? [] : {};
        Object.keys(source).forEach((keys) => {
            if (source[keys] && typeof source[keys] === "object") {
                targetObj[keys] = Ll.deepClone(source[keys]);
            } else {
                targetObj[keys] = source[keys];
            }
        });
        return targetObj;
    },

    copyText(str) {
        let oInput = document.createElement("input");
        oInput.value = str;
        document.body.appendChild(oInput);
        oInput.select();
        document.execCommand("Copy");
        oInput.style.display = "none";
        document.body.removeChild(oInput);
        // window.alert('���Ƴɹ�')
    },
    GetRequest: function () {
        var url = location.search;
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
            }
        }
        return theRequest;
    },
    getTimeFormatYMD: function (time) {
        if (!time) {
            var time = new Date().getTime();
        }
        var date = new Date(parseInt(time));
        var year, month, day;
        year = date.getFullYear();
        parseInt(date.getMonth()) + 1 < 10
            ? (month = "0" + (parseInt(date.getMonth()) + 1))
            : (month = parseInt(date.getMonth()) + 1);
        date.getDate() < 10 ? (day = "0" + date.getDate()) : (day = date.getDate());
        return [year, month, day].join("-");
    },
    randomString: function (len) {
        var len = len || 8;
        var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
        var maxPos = $chars.length;
        var pwd = "";
        for (var i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
    clone: function (obj) {
        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;
        // Handle Date
        if (obj instanceof Date) {
            var copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        // Handle Array
        if (obj instanceof Array) {
            var copy = [];
            for (var i = 0, len = obj.length; i < len; ++i) {
                copy[i] = this.clone(obj[i]);
            }
            return copy;
        }
        // Handle Object
        if (obj instanceof Object) {
            var copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
            }
            return copy;
        }
        throw new Error("Unable to copy obj! Its type isn't supported.");
    },
    setCookie(name, value) {
        var days = 1;
        var exp = new Date();
        exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie =
            name + "=" + escape(value) + ";path=/;expires=" + exp.toGMTString();
    },
    readCookie(name) {
        var arr,
            reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if ((arr = document.cookie.match(reg))) {
            return unescape(arr[2]);
        } else {
            return null;
        }
    },
    TimeDown(time) {
        var time = time || 0;

        var totalSeconds = parseInt(time / 1000);

        if (totalSeconds <= 0) {
            return "0Сʱ0����0��";
        }

        var days = Math.floor(totalSeconds / (60 * 60 * 24));

        var modulo = totalSeconds % (60 * 60 * 24);

        var hours = Math.floor(modulo / (60 * 60));
        modulo = modulo % (60 * 60);

        var minutes = Math.floor(modulo / 60);

        var seconds = modulo % 60;

        return hours + "Сʱ" + minutes + "����" + seconds + "��";
    },
    uploadAjax(opt) {
        var opt = opt || {};
        opt.method = opt.method.toUpperCase() || "POST";
        opt.url = opt.url || "";
        opt.header = opt.header || {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        };
        opt.async = opt.async || true;
        opt.data = opt.data || null;
        opt.success = opt.success || function () { };
        opt.errer = opt.errer || function () { };
        var xmlHttp = null;
        if (XMLHttpRequest) {
            xmlHttp = new XMLHttpRequest();
        } else {
            xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
        }
        if (!opt.header["file"]) {
            var params = [];

            for (var key in opt.data) {
                params.push(key + "=" + opt.data[key]);
            }

            var postData = params.join("&");
        } else {
            var postData = opt.data;
        }

        if (opt.method.toUpperCase() === "POST") {
            xmlHttp.open(opt.method, opt.url, opt.async);

            for (var key2 in opt.header) {
                xmlHttp.setRequestHeader(key2, opt.header[key2]);
            }

            xmlHttp.send(postData);
        } else if (opt.method.toUpperCase() === "GET") {
            xmlHttp.open(opt.method, opt.url + "?" + postData, opt.async);

            for (var key2 in opt.header) {
                xmlHttp.setRequestHeader(key2, opt.header[key2]);
            }

            xmlHttp.send(null);
        }

        xmlHttp.onreadystatechange = function () {
            // console.log(xmlHttp.readyState,xmlHttp.status);
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                opt.success(JSON.parse(xmlHttp.responseText));
            } else if (xmlHttp.status >= 400) {
                opt.errer(xmlHttp);
            }
        };
    },
    uploadFile(id, url, callback, that) {
        var forma = document.getElementById(id);
        var formdata = new FormData(forma);
        // console.log(formdata);
        that.loading = true;
        this.uploadAjax({
            method: "POST",
            url: url,
            header: {
                file: true,
                Authorization: "Bearer " + sessionStorage.token,
            },
            data: formdata,
            success: function (res) {
                callback(res);
                that.loading = false;
            },
            errer: function (res) {
                // console.log(res);
                that.loading = false;
            },
        });
    },
    GetQueryValue(queryName) {
        var query = decodeURI(window.location.search.substring(1));
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == queryName) {
                return pair[1];
            }
        }
        return null;
    },
    timestampToDate(timestamp, index) {
        var date = new Date(timestamp + index * 86400000);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y + M + D
    },
    GetDateStr(AddDayCount) {
        var dd = new Date();
        dd.setDate(dd.getDate() + AddDayCount);//��ȡAddDayCount��������
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1;//��ȡ��ǰ�·ݵ�����
        var d = dd.getDate();
        return y + "-" + m + "-" + d;
    },
    currmonthStarttime() {
        let today = new Date();  //�й���׼ʱ�� ��ǰʱ��
        // ����һ���µ�ĳһ��
        today.setDate(1)
        let oYear = today.getFullYear();
        let oMoth = (today.getMonth() + 1).toString();
        if (oMoth.length <= 1) oMoth = "0" + oMoth;
        let oDay = today.getDate().toString();
        if (oDay.length <= 1) oDay = "0" + oDay;
        return oYear + "-" + oMoth + "-" + oDay;
    }
};
export default Dd;
