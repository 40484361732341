<template>
  <div class="page_box">
    <page-title-bar :title="title">
      <el-button
        @click="cuePoolSetup"
        type="primary"
        size="small"
        style="margin-right: 10px"
        >线索池设置</el-button
      >
    </page-title-bar>
    <div class="bd-bar">
      <el-form
        ref="SearchForm"
        :inline="true"
        :model="form"
        label-width="80px"
        class="search-bar_n"
      >
        <el-tabs v-model="tabStatus" @tab-click="handleClick">
          <el-tab-pane
            :label="`${item.label}`"
            :name="item.value"
            v-for="(item, index) in statusType"
            :key="index"
          >
          </el-tab-pane>
        </el-tabs>
        <div class="bd-bar2">
          <span class="bd-name">搜索：</span>
          <el-form-item label="" prop="returnMode" v-show="tabStatus == '3'">
            <el-select
              v-model="form.returnMode"
              style="width: 150px"
              placeholder="退回方式"
              size="small"
            >
              <el-option
                v-for="(item, index) in returnMode"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="companyName">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.companyName"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="industry">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.industry"
              placeholder="所属行业"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="income">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.income"
              placeholder="年营收"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="returnModeName"
            v-show="tabStatus == '3'"
          >
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.returnModeName"
              placeholder="退回人"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="" prop="taskTitle">
            <el-input
              size="small"
              style="width: 200px"
              v-model="form.taskTitle"
              placeholder="领取/分配时间段"
            ></el-input>
          </el-form-item> -->
          <el-form-item
            label-width="88px"
            label="退回时间段"
            prop="auditDate"
            v-show="tabStatus == '3'"
          >
            <el-date-picker
              v-model="form.auditDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 220px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label-width="88px"
            label="作废时间段"
            prop="auditDate"
            v-show="tabStatus == '4'"
          >
            <el-date-picker
              v-model="form.auditDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 220px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getData(1)"
              >查询</el-button
            >
            <el-button size="small" @click="reset('SearchForm')"
              >重置</el-button
            >
            <el-button size="small" @click="adrentExport(1)">导出</el-button>
          </el-form-item>
          <!-- <el-form-item style="float: right" v-if="tabStatus == 1">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click.stop="addTask"
              >新增任务</el-button
            >
          </el-form-item> -->
        </div>
      </el-form>
      <div class="dotted-line"></div>
      <div
        class="left batch-option"
        style="min-width: 315px; padding: 12px 0 12px"
      >
        <div>
          <el-button type="text" style="padding-bottom: 0; color: #333"
            >已选 {{ selectionItem.length }} 条数据</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click.stop="drawAll(2)"
            v-show="tabStatus == '3'"
            >批量领取</el-button
          >
          <el-button
            size="small"
            @click.stop="cancellation(1)"
            v-show="tabStatus == '3'"
            >批量作废</el-button
          >
          <el-button
            size="small"
            v-show="tabStatus == '3'"
            @click="batchDistribution"
            >批量分配</el-button
          >
          <el-button
            size="small"
            type="primary"
            v-show="tabStatus == '4'"
            @click="batchRecovery"
            >批量恢复</el-button
          >
        </div>
      </div>

      <div class="table_page">
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-loading="tabLoading"
          v-if="advanceDataList.length > 0"
          @selection-change="handleSelectionChange"
        >
          <ex-table-column
            type="selection"
            key="1"
            align="center"
            :autoFit="true"
            :fitHeader="true"
          >
          </ex-table-column>
          <ex-table-column
            key="2"
            prop="companyName"
            label="公司名称"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.companyName" @click="getAssetsClueassetsClue(scope.row)">
                <el-tooltip
                  placement="top"
                  v-if="scope.row.companyName.length > 13"
                >
                  <div slot="content" style="width: 300px">
                    {{ scope.row.companyName }}
                  </div>
                  <div class="details-text link">{{ scope.row.companyName }}</div>
                </el-tooltip>
                <div v-else class="details-text link">
                  {{ scope.row.companyName }}
                </div>
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="3"
            prop="mobile"
            label="联系人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.contactName }} {{ scope.row.contactPhone }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="4"
            prop="industry"
            label="所属行业"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top" v-if="scope.row.industry.length > 13">
                <div slot="content" style="width: 300px">
                  {{ scope.row.industry }}
                </div>
                <div class="details-text">{{ scope.row.industry }}</div>
              </el-tooltip>
              <div v-else class="details-text">{{ scope.row.industry }}</div>
              <!-- <div class="details-text">{{scope.row.industry}}</div> -->
            </template>
          </ex-table-column>
          <ex-table-column
            key="5"
            prop="annualRevenue"
            label="年营收"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="6"
            prop="transfersNum"
            label="转手次数"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="7"
            prop="returnMode"
            label="退回方式"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            v-if="tabStatus == 3"
          >
            <template slot-scope="scope">
              <div>{{ selectDictlabel(returnMode, scope.row.returnMode) }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="8"
            prop="returnModeName"
            label="退回人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            v-if="tabStatus == 3"
          >
          </ex-table-column>
          <ex-table-column
            key="9"
            prop="returnModeDate"
            label="退回时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            v-if="tabStatus == 3"
          >
          </ex-table-column>
          <ex-table-column
            key="10"
            prop="returnModeReason"
            label="退回原因"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            v-if="tabStatus == 3"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.returnModeReason">
                <el-tooltip
                  placement="top"
                  v-if="scope.row.returnModeReason.length > 12"
                >
                  <div slot="content" style="width: 300px">
                    {{ scope.row.returnModeReason }}
                  </div>
                  <div class="details-text">
                    {{ scope.row.returnModeReason }}
                  </div>
                </el-tooltip>
                <div v-else class="details-text">
                  {{ scope.row.returnModeReason }}
                </div>
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="11"
            prop="cancelName"
            label="作废操作人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            v-if="tabStatus == 4"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.cancelName }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="12"
            prop="cancelDate"
            label="作废时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            v-if="tabStatus == 4"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.cancelDate }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="13"
            prop="cancelReason"
            label="作废原因"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            v-if="tabStatus == 4"
          >
           <template slot-scope="scope">
              <div v-if="scope.row.cancelReason">
                <el-tooltip
                  placement="top"
                  v-if="scope.row.cancelReason.length > 12"
                >
                  <div slot="content" style="width: 300px">
                    {{ scope.row.cancelReason }}
                  </div>
                  <div class="details-text">
                    {{ scope.row.cancelReason }}
                  </div>
                </el-tooltip>
                <div v-else class="details-text">
                  {{ scope.row.cancelReason }}
                </div>
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="14"
            label="操作"
            align="left"
            fixed="right"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div class="flex-box table-fixed f-center">
                <el-button
                  type="text"
                  @click.stop="getAssetsClueassetsClue(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  @click.stop="drawDown(scope.row)"
                  v-if="tabStatus == 3"
                  >领取</el-button
                >
                <el-button
                  type="text"
                  v-if="tabStatus == 3"
                  @click.stop="leadNull(scope.row)"
                  >作废</el-button
                >
                <el-button
                  type="text"
                  v-if="tabStatus == 4"
                  @click.stop="recover(scope.row)"
                  >恢复</el-button
                >
              </div>
            </template>
          </ex-table-column>
        </el-table>
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-loading="tabLoading"
          v-else
        >
          <ex-table-column
            type="selection"
            key="15"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
          </ex-table-column>
          <el-table-column
            key="16"
            prop=""
            label="公司名称"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="17"
            prop="title"
            label="联系人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="type"
            label="所属行业"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="reward"
            label="年营收"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="20"
            label="转手次数"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="orderNum"
            label="退回方式"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="22"
            prop="checkNum"
            label="退回人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="23"
            prop="order_sum_reward"
            label="退回时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="24"
            prop="create_id.username"
            label="退回原因"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>

          <el-table-column key="25" label="操作" align="left" fixed="right">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="this.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 线索领取/恢复 -->
    <el-dialog :title="'线索领取'" :visible.sync="dialogVisible" width="424px">
      <div style="padding: 20px 30px">
        已选择 <em v-if="type == 2">{{ selectionItem.length }}</em
        ><em v-else>{{ list.length }}</em> 条线索，确认领取吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-preventReClick="3000" @click="confirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 线索恢复 -->
    <el-dialog :title="'线索恢复'" :visible.sync="dialogVisible2" width="424px">
      <div style="padding: 20px 30px">
        已选择 <em v-if="type2 == 1">{{ selectionItem.length }}</em
        ><em v-else>{{ list3.length }}</em> 条线索，确认恢复吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" v-preventReClick="3000" @click="confirmHf"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 批量分配/批量作废 -->
    <el-dialog
      title="批量作废"
      :visible.sync="returnCluePoolDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheckPool('returnClueForm')"
    >
      <div class="dialog-cont2">
        <div style="padding: 10px 0px">
          已选择 <em v-if="istype == 1">{{ selectionItem.length }}</em
          ><em v-else>{{ list2.length }}</em> 条线索，请选择作废原因？
        </div>
        <el-form
          ref="returnClueForm"
          :model="returnClueForm"
          :rules="returnClueRules"
          class="returnClueForm"
          label-position="top"
        >
          <el-form-item label="" prop="" label-width="100px">
            <el-button
              style="margin-right: 15px"
              size="small"
              :plain="returnActive == item.value ? false : true"
              type="primary"
              v-for="(item, index) in reasonForReturn"
              :key="index"
              @click="labelactiveClick(item.label, item.value)"
              >{{ item.label }}</el-button
            >
          </el-form-item>
          <el-form-item label="" prop="followupContent" label-width="100px">
            <el-input
              type="text"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="returnClueForm.followupContent"
              placeholder="自定义输入退回原因"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="returnCluePoolDialog = false">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="returnConfirm('returnClueForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 批量分配线索 -->
    <el-dialog
      title="批量分配"
      :visible.sync="batchDistributionDialog"
      :close-on-click-modal="false"
      width="424px"
      @close="closePrincipal"
    >
      <div class="dialog-cont">
        <div class="dialog-title">
          已选择<em style="color: #1890ff">{{ selectionItem.length }}</em
          >条线索，请选择负责人
        </div>
        <el-select
          v-model="batchForm.principalId"
          filterable
          placeholder="请选择或输入搜索"
          style="width: 434px"
        >
          <el-option
            v-for="item in principalList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <glueDetails
      :dialogDetail="dialogDetail"
      @closeDetails="closeDetails"
      :cluelist="cluelist"
      :clueLoading="clueLoading"
      :detailType="detailType"
      ref="child"
    ></glueDetails>
    <!-- 线索自动分配设置 -->
    <el-dialog
      title="线索池设置"
      :visible.sync="distributionDialog"
      :close-on-click-modal="false"
      width="624px"
    >
      <div class="dialog-cont2 defineSwitch">
        <div class="dialog-title2" style="padding-left: 44px">
          <h3>线索自动回收设置</h3>
        </div>
        <el-form
          :model="batchForm2"
          ref="batchForm"
          :inline="true"
          :rules="batchRules"
          class="batchForm3"
          style="padding-left: 44px"
        >
          <el-form-item
            label="自动回收开关"
            style="display: block"
            prop="functionSwitch"
          >
            <el-switch
              v-model="batchForm2.functionSwitch"
              :width="55"
              class="amend-switch"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="自动回收条件" prop="number">
            <div class="flex flex-box">
              <span style="padding-right: 8px">超过</span>
              <el-select
                v-model="batchForm2.number"
                style="width: 90px"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="(item, index) in position"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <span style="padding-left: 8px">未跟进的线索</span>
            </div>
          </el-form-item>
          <div class="dialog-title2">
            <h3>线索领取设置</h3>
          </div>
          <el-form-item label="" prop="numberTow">
            <div class="flex flex-box">
              <span style="padding-right: 8px">每人每日可领取上限</span>
              <el-select
                v-model="batchForm2.numberTow"
                style="width: 90px"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="(item, index) in positionTow"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="distributionDialog = false">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="PoolConfirm('demandForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import pageTitleBar from "@/components/page-title-bar.vue";
import glueDetails from "@/components/glueDetails";
import { assetsClueDepManagerList } from "@/api/report";
let optionsall = [];
// for (let i = 0; i < 10; i++) {
//   optionsall.push({
//     name: "百里屠苏",
//     mobile: 18612125115,
//     companyName: "深圳工务园科技有限公司",
//     reward: "李四",
//     upStatus: "待跟进",
//     orderNum: "王五",
//     checkNum: "电话跟进",
//     checkContent: "未联系上",
//     order_sum_reward: "2022-06-09 14:50:55",
//     follow_up_time: "2022-06-11",
//     remark: "花样百出",
//     allocation_time: "2022-06-07 14:50:55",
//   });
// }
export default {
  inject: ["reload"],
  components: { pageTitleBar, glueDetails },
  data() {
    return {
      title: "线索池",
      tabStatus: "3",
      page: 1,
      perPage: 10,
      tabLoading: false,
      dialogVisible: false,
      returnCluePoolDialog: false,
      batchDistributionDialog: false,
      dialogVisible2: false,
      distributionDialog: false,
      principalList: [],
      detailType: true,
      batchForm: {
        principalId: "",
      },
      batchForm2: {
        functionSwitch: 2,
        number: 15,
        numberTow: 15,
        id: "",
      },
      batchRules: {
        functionSwitch: [
          {
            required: true,
            message: "请输入选择",
            trigger: "change",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入选择",
            trigger: "change",
          },
        ],
      },
      position: [
        {
          label: "7天",
          value: 7,
        },
        {
          label: "10天",
          value: 10,
        },
        {
          label: "15天",
          value: 15,
        },
        {
          label: "21天",
          value: 21,
        },
        {
          label: "30天",
          value: 30,
        },
        {
          label: "45天",
          value: 45,
        },
        {
          label: "60天",
          value: 60,
        },
      ],
      positionTow: [
        {
          label: "10条",
          value: 10,
        },
        {
          label: "15条",
          value: 15,
        },
        {
          label: "30条",
          value: 30,
        },
        {
          label: "45条",
          value: 45,
        },
        {
          label: "60条",
          value: 60,
        },
        {
          label: "100条",
          value: 100,
        },
        {
          label: "200条",
          value: 200,
        },
      ],
      count: 0,
      isApply: false,
      statusType: [
        {
          label: "可领取",
          value: "3",
        },
        {
          label: "已作废",
          value: "4",
        },
      ],
      reasonForReturn: [
        {
          label: "多次跟进均拒绝",
          value: "1",
        },
        {
          label: "客户联系不上",
          value: "2",
        },
        {
          label: "手机号是空号",
          value: "3",
        },
      ],
      returnActive: "",
      options: [],
      form: {
        companyName: "",
        returnMode: "",
        industry: "",
        income: "",
        returnModeName: "",
        auditDate: [],
      },
      advanceDataList: optionsall,
      selectionItem: [],
      returnMode: "",
      type: 1,
      type2: 1,
      istype: 1,
      returnClueForm: {
        followupContent: "",
      },
      cluelist: {},
      list: [],
      list2: [],
      list3: [],
      dialogDetail: false,
      clueLoading: true,
      returnClueRules: {
        followupContent: [
          {
            required: true,
            message: "请输入退回原因",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getData(1);
    this.getPrincipal();
    this.getCommon();
    this.getAssetsCluePoolSetting();
  },
  methods: {
    closePrincipal(){
          this.batchForm.principalId = "";
    },
    // 线索池自动回收
    PoolConfirm() {
      let _this = this;
      let params = {
        cluePoolName: "线索池自动回收设置",
        autoRecovery: _this.batchForm2.functionSwitch,
        autoRecoveryDate: _this.batchForm2.number,
        dailyReceive: _this.batchForm2.numberTow,
      };
      if (_this.batchForm2.id) {
        params.id = _this.batchForm2.id;
      }
      _this.$ajax({
        url:
          _this.globalData.orgHost + "assets-clue/set-assets-clue-pool-setting",
        method: "post",
        header: {
          file: true,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(params),
        success(res) {
          if (res.statusCode == 200) {
            _this.getData(1);
            _this.$message({
              type: "success",
              message: "设置成功",
            });
            _this.distributionDialog = false;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });
    },
    // 获取线索池自动回收设置 assets-clue/assets-clue-pool-setting
    async getAssetsCluePoolSetting() {
      let _this = this;
      let params = {};
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/assets-clue-pool-setting",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            console.log(res.data);
            let { data } = res;
            _this.batchForm2.functionSwitch = data.autoRecovery;
            _this.batchForm2.number = data.autoRecoveryDate;
            _this.batchForm2.numberTow = data.dailyReceive;
            _this.batchForm2.id = data.id;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    closeCheckPool(formName) {
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.returnClueForm.followupContent = "";
      this.returnActive = "";
    },
    // 线索池设置
    cuePoolSetup() {
      this.distributionDialog = true;
    },
    // 线索详情
    async getAssetsClueassetsClue(row) {
      let _this = this;
      console.log(row);
      this.dialogDetail = true;
      let params = {
        id: row.id,
      };
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/assets-clue",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.cluelist = res.data;
            console.log(_this.clueDetails);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    closeDetails() {
      this.dialogDetail = false;
    },
    // 批量分配
    batchDistribution() {
      const { selectionItem } = this;
      if (selectionItem.length > 0) {
        this.batchDistributionDialog = true;
      } else {
        this.$message({
          type: "error",
          message: "请勾选数据!",
        });
      }
    },
    MenuMore(flag) {
      console.log(flag);
    },
    handleClick() {
      this.reset('SearchForm');
      this.getData(1);
    },
    labelactiveClick(label, index) {
      this.returnActive = index;
      this.returnClueForm.followupContent = label;
    },
    leadNull(row) {
      let _this = this;
      this.list2 = [];
      if (this.list2.length == 0) {
        this.list2.push(row);
      }
      console.log(this.list2);
      _this.returnCluePoolDialog = true;
      _this.istype = 2;
    },
    cancellation(index) {
      let _this = this;
      const { selectionItem } = this;
      if (selectionItem.length > 0) {
        _this.returnCluePoolDialog = true;
        _this.istype = index;
      } else {
        this.$message({
          type: "error",
          message: "请勾选数据!",
        });
      }
    },
    // 获取负责人列表 assetsClueDepManagerList
    async getPrincipal() {
      let _this = this;
      let params = {};
      try {
        let response = await assetsClueDepManagerList(params);
        console.log(response);
        if (response.statusCode == 200) {
          let data = response.data;
          data.map((item, index) => {
            _this.principalList.push({
              label: item.userName,
              value: item.userId,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    applyBatch() {
      let _this = this;
      if (!_this.batchForm.principalId) {
        _this.$message({
          type: "error",
          message: "请选择负责人",
        });
        return;
      }
      let list = this.selectionItem.map((item, index) => {
        return item.id;
      });
      let params = {
        chargeId: _this.batchForm.principalId,
        assetClueIds: list,
      };
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/batchallocation",
        method: "post",
        header: {
          file: true,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(params),
        success(res) {
          if (res.statusCode == 200) {
            _this.getData(1);
            _this.$message({
              type: "success",
              message: "操作成功",
            });
            _this.batchDistributionDialog = false;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });
    },
    // 批量分配取消
    cancel() {
      // this.$refs[form].resetFields();
      this.batchDistributionDialog = false;
    },
    // 批量作废
    returnConfirm(form) {
      let _this = this;
      const { selectionItem, list2 } = this;
      console.log(selectionItem);
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let arr = [];
          if (_this.istype == 1) {
            selectionItem.forEach((item, index) => {
              if (item.id) {
                arr.push(item.id);
              }
            });
          } else {
            list2.forEach((item, index) => {
              if (item.id) {
                arr.push(item.id);
              }
            });
          }
          console.log(arr);
          let params = {
            assetClueIds: arr,
            followupContent: _this.returnClueForm.followupContent,
          };
          _this.$ajax({
            url: _this.globalData.orgHost + "assets-clue/clue-cancel",
            method: "put",
            header: {
              file: true,
              "Content-Type": "application/json",
            },
            data: JSON.stringify(params),
            success(res) {
              if (res.statusCode == 200) {
                _this.getData(1);
                _this.$message({
                  type: "success",
                  message: "操作成功",
                });
                _this.returnCluePoolDialog = false;
              } else {
                _this.$message({
                  type: "error",
                  message: res.errors,
                });
              }
              _this.tabLoading = false;
            },
          });
        }
      });
    },
    // 查询数据
    async getData(page, perPage) {
      let _this = this;
      _this.advanceDataList = [];
      if (_this.page == 1) {
        _this.page = 1;
      }
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      let params = {
        pageIndex: index,
        pageSize: size,
        Cuestatus: _this.tabStatus,
        CompanyName: _this.form.companyName || "",
        Industry: _this.form.industry || "",
        Income: _this.form.income || "",
        ReturnModeName: _this.form.returnModeName || "",
       
      };
      if (_this.form.returnMode) {
        params.ReturnMode = _this.form.returnMode;
      }
      if(_this.tabStatus == '3'){
          params.ReturnModeTimeStart = _this.form.auditDate[0] || "";
          params.ReturnModeTimeEnd = _this.form.auditDate[1] || "";
      }else{
           params.CancelTimeStart = _this.form.auditDate[0] || "";
          params.CancelTimeEnd = _this.form.auditDate[1] || "";
      }
      _this.tabLoading = true;
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/assets-clue-pool-list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data, cumulativeData, totalCount } = res.data;
            _this.advanceDataList = data;
            console.log(data);
            _this.count = totalCount;
            let m;
            // _this.cumulativeList = cumulativeData.cumulative;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
          _this.selectionItem = [];
        },
      });
    },
    selectDictlabel(lab, value) {
      let _this = this;
      let label = this.ll.selectDictLabel(lab, value);
      return label;
    },
    // 线索恢复/领取
    confirm() {
      let _this = this;
      const { selectionItem, list } = this;
      let arr = [];
      console.log(selectionItem);
      if (_this.type == 2) {
        selectionItem.forEach((item, index) => {
          if (item.id) {
            arr.push(item.id);
          }
        });
      } else {
        list.forEach((item, index) => {
          if (item.id) {
            arr.push(item.id);
          }
        });
      }

      console.log(arr);
      let params = {
        assetClueIds: arr,
        followupContent: "",
      };
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/clue-receive",
        method: "put",
        header: {
          file: true,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(params),
        success(res) {
          if (res.statusCode == 200) {
            _this.getData(1);
            _this.$message({
              type: "success",
              message: "领取成功",
            });
            _this.dialogVisible = false;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });
      //   }
      // });
    },
    // 线索恢复
    confirmHf() {
      let _this = this;
      const { selectionItem, list3 } = this;
      let arr = [];
      console.log(selectionItem);
      if (_this.type2 == 1) {
        selectionItem.forEach((item, index) => {
          if (item.id) {
            arr.push(item.id);
          }
        });
      } else {
        list3.forEach((item, index) => {
          if (item.id) {
            arr.push(item.id);
          }
        });
      }
      let params = {
        assetClueIds: arr,
        followupContent: "",
      };
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/clue-restore",
        method: "put",
        header: {
          file: true,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(params),
        success(res) {
          if (res.statusCode == 200) {
            _this.getData(1);
            _this.$message({
              type: "success",
              message: "恢复成功",
            });
            _this.dialogVisible2 = false;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });
    },
    // 领取
    drawDown(row) {
      this.type = 1;
      this.list = [];
      if (this.list.length == 0) {
        this.list.push(row);
      }
      console.log(this.list);
      this.dialogVisible = true;
    },
    drawAll(val) {
      const { selectionItem } = this;
      if (selectionItem.length > 0) {
        this.dialogVisible = true;
        this.type = val;
      } else {
        this.$message({
          type: "error",
          message: "请勾选数据!",
        });
      }
    },
    batchRecovery() {
      const { selectionItem } = this;
      if (selectionItem.length > 0) {
        this.dialogVisible2 = true;
        this.type2 = 1;
      } else {
        this.$message({
          type: "error",
          message: "请勾选数据!",
        });
      }
    },
    recover(row) {
      this.type2 = 2;
      this.list3 = [];
      if (this.list3.length == 0) {
        this.list3.push(row);
      }
      console.log(this.list3);
      this.dialogVisible2 = true;
    },
    // 重置
    reset(formName) {
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.getData(1);
    },
    // 导出
    adrentExport(page, perPage) {
      let _this = this;
      let access_token = localStorage.getItem("Authorization");
      if (page == 1) {
        _this.page = 1;
      }
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      let params = {
        pageIndex: index,
        pageSize: size,
        Cuestatus: _this.tabStatus,
        CompanyName: _this.form.companyName || "",
        Industry: _this.form.industry || "",
        Income: _this.form.income || "",
        ReturnModeName: _this.form.returnModeName || "",
        Authorization: access_token,
      };
      if (_this.form.returnMode) {
        params.ReturnMode = _this.form.returnMode;
      }
      if(_this.tabStatus == '3'){
          params.ReturnModeTimeStart = _this.form.auditDate[0] || "";
          params.ReturnModeTimeEnd = _this.form.auditDate[1] || "";
      }else{
           params.CancelTimeStart = _this.form.auditDate[0] || "";
          params.CancelTimeEnd = _this.form.auditDate[1] || "";
      }
      _this.$ajax({
        url:
          _this.globalData.orgHost + "assets-clue/export-assets-clue-cool-list",
        method: "get",
        header: {
          dowload: true,
        },
        data: params,
        success: function (res) {
          _this.$message({
            type: "success",
            message: "已导出",
          });
        },
      });
    },
    handleSizeChange(val) {
      this.perPage = val;
      this.page = 1;
      this.advanceDataList = [];
      this.getData(this.page, this.perPage);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.advanceDataList = [];
      this.getData(this.page, this.perPage);
    },
    // 多选项目
    handleSelectionChange(val) {
      this.selectionItem = val;
      console.log(val);
    },

    async getCommon() {
      let _this = this;
      let params = {};
      _this.$ajax({
        url: _this.globalData.orgCommonHost + "system/common-type",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { ReturnMode } = res.data;
            _this.returnMode = ReturnMode;
          }
        },
      });
    },
  },
};
</script>

<style scoped>
.dialog-title2 {
  padding-bottom: 10px;
}
.dialog-title2 h3 {
  font-size: 16px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}
.defineSwitch {
  height: 256px;
}
.details-text {
  width: 202px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog-cont {
  padding: 21px 26px 40px;
}
.dialog-title {
  padding: 2px 0 20px;
}
.dialog-cont2 {
  padding: 20px 30px;
}
.bd-bar {
  padding: 0 20px;
  background-color: #fff;
}
.table_page .page {
  padding-bottom: 10px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.search-bar_n {
  padding: 10px 0;
  margin: 0;
}
</style>
<style>
.bd-bar2 .el-form-item {
  margin-bottom: 0;
}
.page_box .el-table th.el-table__cell {
  background: #f5f7fa;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-table .cell {
  white-space: nowrap !important;
  width: fit-content !important;
}
.dotted-line {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  margin: 0 auto;
  background: url("../../assets/image/dotted-line.png") no-repeat center;
  background-size: 100%;
  /* margin: -40px 20px 22.5px 2px; */
}
.el-form-item {
  margin-bottom: 10px;
}
</style>