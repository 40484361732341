function myajax(obj) {
    var that = this;
    var opt = obj || {};
    var timeoutFlag = null;
    opt.method = opt.method || 'POST';
    opt.url = opt.url || '';
    opt.header = opt.header || {};
    opt.header['random'] = Math.random() * 10000;
    opt.header['ClientCode'] = 'PC_Org';
    if (!opt.header['Content-Type']) {
      opt.header['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
    }
    if (localStorage.getItem('Authorization')) {
      opt.header['Authorization'] = 'Bearer ' + localStorage.getItem('Authorization');;
    } else if (that.globalData.access_token) {
      opt.header['Authorization'] = 'Bearer ' + localStorage.getItem('Authorization');;
    }
  
    if (sessionStorage.sign) {
      opt.header['sign'] = sessionStorage.sign;
    }
    if (sessionStorage.s_timestamp) {
      opt.header['sign-timestamp'] = sessionStorage.s_timestamp;
    }
    if (sessionStorage.s_key) {
      opt.header['sign-key'] = sessionStorage.s_key;
    }
    opt.async = opt.async || true;
    // opt.timeout=opt.timeout||'';//超时处理，默认8s
    opt.data = opt.data || null;
    opt.success = opt.success || function () { };
    opt.error = opt.error || function () { };
    opt.complete = opt.complete || function () { };
    var xmlHttp = null;
  
    if (XMLHttpRequest) {
      xmlHttp = new XMLHttpRequest();
    }
    else {
      xmlHttp = new ActiveXObject('Microsoft.XMLHTTP');
    }
  
    var params = [];
    var postData = null;
    if (!opt.header.file) {
      for (var key in opt.data) {
        if (opt.method.toUpperCase() === 'POST') {
          params.push(key + '=' + opt.data[key]);
        } else {
          params.push(key + '=' + encodeURI(opt.data[key]));
        }
  
      }
      postData = params.join('&');
    } else {
      postData = opt.data
    }
    if (opt.method.toUpperCase() === 'POST') {
      xmlHttp.open(opt.method, opt.url, opt.async);
      for (var key2 in opt.header) {
        xmlHttp.setRequestHeader(key2, opt.header[key2]);
      }
      xmlHttp.send(postData);
    }
    else if (opt.method.toUpperCase() === 'GET') {
      if (opt.header.dowload) {
        location.assign(opt.url + '?' + postData);
        return false;
      }
  
      if (postData == '') {
        xmlHttp.open(opt.method, opt.url, opt.async);
      } else {
        xmlHttp.open(opt.method, opt.url + '?' + postData, opt.async);
      }
  
      for (var key2 in opt.header) {
        xmlHttp.setRequestHeader(key2, opt.header[key2]);
      }
  
      xmlHttp.send(null);
    } else if (opt.method.toUpperCase() === 'PUT') {
      xmlHttp.open(opt.method, opt.url, opt.async);
      for (var key2 in opt.header) {
        xmlHttp.setRequestHeader(key2, opt.header[key2]);
      }
      xmlHttp.send(postData);
    }

     console.log(opt.method.toUpperCase());

    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
        if (opt.header.dowload) {
          opt.success(xmlHttp.responseText);
          return false;
        }
  
        if (JSON.parse(xmlHttp.responseText).code == 8) {
          that.$message({
            message: '授权失效，请您重新登录',
            type: 'error'
          });
          that.$router.push({ path: '/' });
          return false;
        }
        if (JSON.parse(xmlHttp.responseText).code == 3) {
          that.random(obj);//获取随机因子
          return false;
        }
        if (JSON.parse(xmlHttp.responseText).code == 42) {
          that.$alert(JSON.parse(xmlHttp.responseText).message, '提示', {
            confirmButtonText: '确定',
            callback() {
              that.$router.push({ path: '/' });
            }
          });
          return false;
        }
  
        var responseData = null;
        try {
          responseData = JSON.parse(xmlHttp.responseText);
        } catch (e) {
          responseData = null
        }
        opt.success(responseData);
        // console.log(" xmlHttp.status", xmlHttp.status)
      } else if (xmlHttp.readyState == 4 && xmlHttp.status == 401) {
        if (that.globalData.code401) {
          return false;
        }
        that.globalData.code401 = true;
        that.$alert('登录已过期！', '提示', {
          confirmButtonText: '确定',
          callback() {
            that.globalData.code401 = false;
            that.$router.push({ path: '/' });
          }
        });
  
      } else if (xmlHttp.readyState == 4 && xmlHttp.status >= 400) {
        opt.error(xmlHttp);
      }
  
      if (xmlHttp.readyState == 4) {
        opt.complete(xmlHttp);
      }
  
    }
  }
  
  export default myajax;
  