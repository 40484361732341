<template>
  <div class="container">
    <!-- <div class="head-logo">
      <img src="../assets/image/logo.png" />
    </div> -->
    <div class="form-head flex">
      <div class="form-banner">
        <div class="logo">
          <img src="../assets/image/banner.png" alt="" />
        </div>
      </div>
      <div class="form-body">
        <div class="form-con">
          <h1>您好，欢迎登录企园网</h1>
          <el-form
            hide-required-asterisk
            ref="form"
            :model="form"
            :rules="rules"
            label-width="80px"
          >
            <el-form-item label-width="20" label="账户" prop="name">
              <el-input
                placeholder="请输入登录账户"
                v-model="form.name"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label-width="20" label="密码" prop="pws">
              <el-input
                placeholder="请输入登录密码"
                autocomplete="new-password"
                v-model="form.pws"
                show-password
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label-width="20">
              <el-button @click.stop="login('form')" type="primary"
                @keyup.enter="keyDown(e)">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLogin } from "@/api/report";
import { mapMutations, changeUserName } from "vuex";
export default {
  name: "Register",
  data() {
    return {
      form: {
        name: "",
        pws: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入登录账户", trigger: "blur" },
          {
            min: 3,
            max: 11,
            message: "长度在 3 到 11 个字符",
            trigger: "blur",
          },
        ],
        pws: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
      },
      userToken: "",
    };
  },
  mounted(){
       // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
   destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    ...mapMutations(["changeLogin", "changeUserName"]),
    login(formName) {
      let that = this;
      let params = {};
      let loading;
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          if (this.form.pws) {
            params.passWord = this.form.pws;
          }
          if (this.form.name) {
            params.Mobile = this.form.name;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
        try {
          let response = await getLogin(params);
          console.log(response);
          if (response.statusCode == 200) {
            setTimeout(() => {
              that.userToken = response.data.token;
              that.changeLogin({ Authorization: that.userToken });
              sessionStorage.userId = response.data.userId;
              sessionStorage.isAdmin = response.data.isAdmin;
              console.log(response.data.userName);
              that.changeUserName(response.data);
              loading.close();
              that.$router.push("/home");
              // that.$message.show("登录成功","success");
            }, 1000);
          } else {
            loading.close();
             that.$message({
               type:'error',
               message:response.errors
             })
            console.log(111);
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      console.log(e);
      if (e.keyCode == 13) {
        console.log(e.keyCode);
        this.login('form'); // 定义的登录方法
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/common.scss";
.container {
  width: 100vw;
  height: 100vh;
  font-size: 1rem;
  color: $--color-primary;
  background: $--border-style;
  .head-logo {
    padding: 2rem 2.5rem;
  }
  .form-head {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40.5rem;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);

    .form-banner {
      width: 35rem;
      height: 40.5rem;
      .logo {
        height: 40.5rem;
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.03);
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
    .form-body {
      width: 32.5rem;
      height: 100%;
      background: #fff;
      border-radius: 5px;
      box-sizing: border-box;
      .form-con {
        padding: 8.25rem 6.25rem;
        box-sizing: border-box;
        h1 {
          padding-bottom: 3.25rem;
          font-size: 28px;
          font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
          font-weight: 400;
          color: #2e384d;
          line-height: 32px;
        }
      }
    }
  }
}
.el-button {
  margin-top: 2.75rem;
  width: 100%;
  height: 3rem;
}
</style>
<style>
.form-body .el-form-item__label {
  color: #8c98a9 !important;
  float: auto !important;
}
.form-body .el-input__inner {
  height: 3rem;
  line-height: 3rem;
}
</style>