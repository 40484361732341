<template>
  <div class="page_box">
    <page-title-bar :title="title">
      <el-button
        @click.stop="newCustomer"
        type="primary"
        size="small"
        style="margin-right: 10px"
        >新增客户</el-button
      >
    </page-title-bar>
    <div class="bd-bar">
      <el-form
        ref="SearchForm"
        :inline="true"
        :model="form"
        label-width="80px"
        class="search-bar_n"
      >
        <el-tabs v-model="tabStatus" @tab-click="handleClick">
          <el-tab-pane
            :label="`${item.lable} ${item.num}`"
            :name="item.value + ''"
            v-for="(item, index) in cumulativeList"
            :key="index"
          >
          </el-tab-pane>
        </el-tabs>
        <div class="bd-bar2">
          <span class="bd-name">搜索：</span>
          <el-form-item label="" prop="source">
            <el-select
              v-model="form.source"
              style="width: 150px"
              placeholder="客户来源"
              size="small"
            >
              <el-option
                v-for="(item, index) in Source"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="product">
            <el-select
              v-model="form.product"
              style="width: 150px"
              placeholder="需求产品"
              size="small"
            >
              <el-option
                v-for="(item, index) in Product"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="grade">
            <el-select
              v-model="form.grade"
              style="width: 150px"
              placeholder="签约状态"
              size="small"
            >
              <el-option
                v-for="(item, index) in Category"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="Industry">
            <el-select
              v-model="form.Industry"
              style="width: 150px"
              placeholder="所属行业"
              filterable
              size="small"
            >
              <el-option
                v-for="(item, index) in Industry"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="AnnualTax">
            <el-select
              v-model="form.AnnualTax"
              style="width: 150px"
              placeholder="年税收"
              size="small"
            >
              <el-option
                v-for="(item, index) in AnnualTax"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="customerName">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.customerName"
              placeholder="客户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="principal">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.principal"
              placeholder="负责人/归属人"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="88px" label="创建时间段" prop="auditDate">
            <el-date-picker
              v-model="form.auditDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 220px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getData(1)"
              >查询</el-button
            >
            <el-button size="small" @click="reset('SearchForm')"
              >重置</el-button
            >
            <el-button size="small" @click="ClientExport(1)">导出</el-button>
          </el-form-item>
          <el-form-item style="padding-left: 0px">
            <el-checkbox v-model="checked">仅看我负责的</el-checkbox>
          </el-form-item>
        </div>
      </el-form>
      <div class="table_page" v-loading="tabLoading">
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-if="advanceDataList.length > 0"
          @selection-change="handleSelectionChange"
        >
          <ex-table-column
            key="1"
            prop="clientName"
            label="客户名称"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                class="client-name"
                style="color: #1890ff; cursor: pointer"
                @click.stop="details(scope.row)"
              >
                {{ scope.row.clientName }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="2"
            prop="industry"
            label="所属行业"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ selectDictlabel(Industry, Number(scope.row.industry)) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="3"
            prop="revenue"
            label="年税收"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div class="">
                {{ selectDictlabel(AnnualTax, Number(scope.row.revenue)) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="4"
            prop="reward"
            label="联系人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.contactName }} {{ scope.row.contactPhone }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="5"
            prop=""
            label="客户等级"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ selectDictlabel(Grade, Number(scope.row.grade)) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="6"
            prop=""
            label="签约状态"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ selectDictlabel(Category, Number(scope.row.category)) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="7"
            prop="source"
            label="来源"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ selectDictlabel(Source, scope.row.source) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="8"
            prop="ownership"
            label="归属"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="9"
            prop="chargeName"
            label="负责人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.departmentName }}-{{ scope.row.chargeName }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="10"
            prop="productDemand"
            label="需求产品"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            width="180px"
          >
            <template slot-scope="scope">
              <div class="details-text">
                {{ selectCirclation(Product, scope.row.productDemand) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="11"
            prop="followupMethod"
            label="最近跟进"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            width="150px"
          >
            <template slot-scope="scope">
              <div class="fllowup-content">
                {{ scope.row.followTime }}
                {{ selectDictlabel(followupMethod, scope.row.followupMethod) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="12"
            prop=""
            label="跟进内容"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            width="150px"
          >
            <template slot-scope="scope">
              <div class="fllowup-content">{{ scope.row.followupContent }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="13"
            prop="nextFollowTimeStr"
            label="下次跟进日期"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="14"
            prop="allocation_time"
            label="创建人/时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.createdName }} {{ scope.row.createTime }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="15"
            label="操作"
            align="left"
            fixed="right"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div class="flex-box table-fixed f-center">
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="details(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="followUp(scope.row)"
                  >写跟进</el-button
                >
                <el-dropdown
                  style="padding-left: 10px"
                  trigger="click"
                  @command="MenuMore"
                >
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="beforeHandleCommand('redact', scope.row)"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('transferTohim', scope.row)"
                      >转给他人</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('delete', scope.row)"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </ex-table-column>
        </el-table>
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-else
        >
          <el-table-column
            key="16"
            prop=""
            label="客户名称"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="17"
            prop="title"
            label="所属行业"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="revenue"
            label="年税收"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="reward"
            label="联系人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="20"
            label="来源"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="orderNum"
            label="归属"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="22"
            prop="checkNum"
            label="负责人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="23"
            prop="order_sum_reward"
            label="需求产品"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="24"
            prop="create_id.username"
            label="最近跟进"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="25"
            prop="create_id.username"
            label="跟进内容"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="26"
            prop=""
            label="下次跟进日期"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="27"
            prop=""
            label="创建人/时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column key="28" label="操作" align="left" fixed="right">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="this.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 写跟进 -->
    <el-dialog
      class="batch-dialog n3"
      title="写跟进"
      :visible.sync="writeFollowUpDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('batchForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="batchForm"
          :inline="true"
          :model="batchForm"
          :rules="batchRules"
          class="batchForm3"
        >
          <el-form-item label="跟进客户" prop="" label-width="130px">
            <div>{{ commonlist.clientName }}</div>
          </el-form-item>
          <el-form-item
            label="跟进方式"
            prop="followupValue"
            label-width="130px"
          >
            <el-select
              v-model="batchForm.followupValue"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in followupMethod"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="跟进内容"
            prop="followupContent"
            label-width="130px"
          >
            <el-input
              type="textarea"
              :rows="3"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.followupContent"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
            label="跟进状态"
            prop="clueFollowup"
            label-width="130px"
          >
            <el-select
              v-model="batchForm.clueFollowup"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in FollowupStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item
            label="下次跟进日期"
            prop="entryDate"
            label-width="130px"
          >
            <el-date-picker
              v-model="batchForm.entryDate"
              align="center"
              placeholder="请选择"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 434px"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="writeFollowUpDialog = false">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 转给他人 -->
    <el-dialog
      title="客户负责人转移"
      :visible.sync="demandTransferDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('demandForm')"
    >
      <div class="dialog-cont" style="padding: 30px 30px 20px">
        <el-form
          ref="demandForm"
          :inline="true"
          :model="demandForm"
          :rules="demandRules"
          class="demandForm"
        >
          <el-form-item label="新负责人" prop="principal" label-width="100px">
            <el-select
              v-model="demandForm.principal"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="转移说明"
            prop="transferInstruction"
            label-width="100px"
          >
            <el-input
              type="text"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="demandForm.transferInstruction"
              placeholder="请输入（选填）"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel2('demandForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirm('demandForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 新增客户 -->
    <el-dialog
      :title="clientType == 1 ? '新增客户' : '编辑客户'"
      :visible.sync="leadToDialog"
      :close-on-click-modal="false"
      width="900px"
      @close="closeCheck('batchForm')"
      class="leadto"
    >
      <div :class="{ 'leadTo-container': clientType == 1 }">
        <div class="leadTo-content">
          <div class="leadTo-title" v-if="clientType == 1">
            <div class="leadTo-desc">客户基础信息</div>
          </div>
          <div>
            <el-form
              ref="batchForm"
              :inline="true"
              :model="clientForm"
              :rules="clientRules"
              class="batchForm3"
              label-width="88px"
            >
              <div class="flex flex-space-bewent">
                <el-form-item label="客户名称" prop="companyName">
                  <el-input
                    v-model="clientForm.companyName"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                    :maxlength="20"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contactName">
                  <el-input
                    v-model="clientForm.contactName"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                    :maxlength="20"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    v-model="clientForm.contactPhone"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                    :maxlength="11"
                    @input="handleInput"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系人职务" prop="contactJob">
                  <el-input
                    v-model="clientForm.contactJob"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="客户来源" prop="clientSource">
                  <el-select
                    v-model="clientForm.clientSource"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in Source"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="客户归属" prop="ownership">
                  <el-input
                    v-model="clientForm.ownership"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="负责人" prop="principal">
                  <el-select
                    v-model="clientForm.principal"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="客户等级" prop="clientGrade">
                  <el-select
                    v-model="clientForm.clientGrade"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in Grade"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <!-- <el-form-item label="跟进状态" prop="category">
                  <el-select
                    v-model="clientForm.category"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in Category"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="所在地区" prop="region">
                  <el-cascader
                    :props="{ checkStrictly: false }"
                    v-model="clientForm.region"
                    :options="roleid"
                    :key="countindex"
                    style="width: 260px"
                    size="small"
                    :show-all-levels="true"
                    placeholder="请选择省/市/区"
                    @change="provinceChange"
                  ></el-cascader>
                </el-form-item>

                <el-form-item label="所属行业" prop="industry">
                  <!-- <el-select
                    v-model="clientForm.industry"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                    :remote-method="(val) => remoteSupplierDropList(val, 1)"
                  >
                    <el-option
                      v-for="item in Industry"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->
                  <el-autocomplete
                    size="small"
                    clearable
                    class="blsupplier"
                    v-model="clientForm.industry"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入内容或选择内容"
                    @select="handleAddSupplier3"
                    style="width: 260px"
                  ></el-autocomplete>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="年营收" prop="income">
                  <el-select
                    v-model="clientForm.income"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in AnnualTax"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="年税收" prop="annualTax">
                  <el-select
                    v-model="clientForm.annualTax"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in AnnualTax"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="详细地址" prop="address">
                  <el-input
                    v-model="clientForm.address"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    style="width: 260px"
                    size="small"
                    v-model="clientForm.remark"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </div>
              <!-- flex flex-space-bewent -->
              <div class="Introduction" style="width:100%;">
                <el-form-item label="客户介绍" style="width:100%" prop="Introduction" label-width="11%">
                  <el-input
                    v-model="clientForm.Introduction"
                    size="small"
                    placeholder="请输入"
                    type="textarea"
                    :rows="2"
                    :maxlength="500"
                    style="width: 100%"
                    autocomplete="new-password"
                    show-word-limit
                    ref="leadtoContent"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <div class="leadTo-content2" v-if="clientType == 1">
          <div class="leadTo-title">
            <div class="leadTo-desc">客户需求</div>
          </div>
          <div class="leadTo-box">
            <div
              class="leadTo-card"
              v-for="(item, index) in clientList"
              :key="index"
            >
              <div class="flex flex-space-bewent">
                <div>需求{{ toChineseNumber(index + 1, index) }}</div>
                <el-button type="text" @click.stop="leadToDelete(index)"
                  >删除</el-button
                >
              </div>
              <div>
                <el-form
                  ref="customerform"
                  :model="item"
                  label-width="80px"
                  :inline="true"
                  :rules="customerRules"
                >
                  <div
                    class="flex flex-space-bewent"
                    style="justify-content: flex-start"
                  >
                    <el-form-item label="需求产品" prop="productId">
                      <el-select
                        v-model="item.productId"
                        filterable
                        placeholder="请选择"
                        :loading="loading"
                        size="small"
                        style="width: 122px"
                      >
                        <el-option
                          v-for="truee in Product"
                          :key="truee.value"
                          :label="truee.label"
                          :value="truee.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="紧急程度" prop="intentionlevel">
                      <el-select
                        v-model="item.intentionlevel"
                        filterable
                        placeholder="请选择"
                        :loading="loading"
                        size="small"
                        style="width: 122px"
                      >
                        <el-option
                          v-for="truee in intentionlevelList"
                          :key="truee.value"
                          :label="truee.label"
                          :value="truee.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!--<el-form-item label="负责人" prop="chargeid">
                      <el-select
                        v-model="item.chargeid"
                        filterable
                        placeholder="请选择"
                        :loading="loading"
                        size="small"
                        style="width: 122px"
                      >
                       <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                      </el-select>
                    </el-form-item>-->
                  </div>
                  <el-form-item label="" prop="details">
                    <el-input
                      type="textarea"
                      :rows="3"
                      :maxlength="500"
                      style="width: 766px"
                      autocomplete="new-password"
                      v-model="item.details"
                      placeholder="请输入需求详情"
                      show-word-limit
                      ref="leadtoContent"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div style="padding: 15px 0">
            <el-button size="small" type="primary" @click.stop="addToContinue"
              >继续添加</el-button
            >
          </div>
        </div>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right" v-if="clientType == 1">
          <el-button @click="cancelkehu('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirmTransfer('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="1000"
            >确认创建
          </el-button>
        </div>
        <div class="right" v-else>
          <el-button @click="cancelkehu('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirmTransfer('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="1000"
            >保存
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 删除客户 -->
    <el-dialog title="客户删除" :visible.sync="dialogVisible" width="424px">
      <div style="padding: 20px 30px">
        客户删除时，将一并删除客户的需求，请谨慎操作
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-preventReClick="3000" @click="clientDel"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 客户详情 -->
    <el-dialog
      title="客户详情"
      :visible.sync="dialogDetail2"
      :close-on-click-modal="false"
      width="980px"
    >
      <div class="page_box2">
        <div class="glue-details">
          <div class="flex flex-center">
            <div class="flex flex-center">
              <div class="company-but" v-if="cluelist.grade">
                {{ selectDictlabel(Grade, cluelist.grade) }}类
              </div>
              <div
                class="company-but"
                v-if="cluelist.category"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ selectDictlabel(Category, cluelist.category) }}
              </div>
              <div class="company-name">{{ cluelist.clientName }}</div>
            </div>

            <div>
              <el-dropdown
                style="padding-left: 10px; margin-right: 14px"
                trigger="click"
                @command="MenuMore"
              >
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="beforeHandleCommand('transferTohim', cluelist)"
                    >转给他人</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="beforeHandleCommand('delete', cluelist)"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button size="small" @click.stop="compile" type="primary"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click.stop="followUp(cluelist)"
                >写跟进</el-button
              >
              <!-- <el-select
                v-model="form.type"
                style="width: 100px; margin-left: 10px"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="(item, index) in followUpStatus"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
            </div>
          </div>
          <div class="flex flex-center information">
            <div style="margin-right: 8px" class="flex">
              <div class="flex info-list">
                <div>来源：</div>
                <div>{{ selectDictlabel(Source, cluelist.source) }}</div>
              </div>
              <div class="flex info-list">
                <div>归属：</div>
                <div>{{ cluelist.ownership }}</div>
              </div>
              <div class="flex info-list">
                <div>负责人：</div>
                <div>
                  {{ cluelist.departmentName }}-{{ cluelist.chargeName }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex info-list">
              <div>需求产品：</div>
              <div>
                {{ selectCirclation(Product, cluelist.productDemand) }}
              </div>
            </div>
            <div class="flex info-list">
              <div>下次跟进：</div>
              <div>{{ cluelist.nextFollowTimeStr }}</div>
            </div>
            <div class="flex info-list">
              <div>最近更新：</div>
              <div>{{ cluelist.updateTime }}</div>
            </div>
          </div>
        </div>
        <div>
          <el-radio-group
            v-model="radio"
            style="margin: 12px 0 20px"
            size="small"
          >
            <el-radio-button :label="1">跟进动态</el-radio-button>
            <el-radio-button :label="2">详细</el-radio-button>
            <el-radio-button :label="3">需求</el-radio-button>
            <el-radio-button :label="4">附件</el-radio-button>
          </el-radio-group>
          <div class="label-button" v-if="radio == 1">
            <button :class="{ active: number == -1 }" @click="labelBut(-1)">
              全部
            </button>
            <button :class="{ active: number == 2 }" @click="labelBut(2)">
              跟进记录
            </button>
            <button :class="{ active: number == 1 }" @click="labelBut(1)">
              系统动态
            </button>
            <!-- <button style="float: right" class="write-follow-up">写跟进</button> -->
          </div>
        </div>
        <div
          class="followUp-dynamics"
          v-if="detalist.length != 0 && radio == 1"
        >
          <div v-for="(item, index) in detalist" :key="index">
            <div>
              {{ item.createTime }} {{ item.departmentName }} -
              {{ item.createdName }}
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 1 && item.followAs"
                >【{{ selectDictlabel(FollowAs, item.followAs) }}】</em
              >
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 2 && item.followupMethod"
                >【{{
                  selectDictlabel(followupMethod, item.followupMethod)
                }}】</em
              >
            </div>
            <div v-if="item.followupContent">
              跟进内容：{{ item.followupContent }}
            </div>
            <div v-if="item.followupStatusRecord">
              跟进状态：{{ item.followupStatusRecord }}
            </div>
            <div v-if="item.chargeRecord">负责人：{{ item.chargeRecord }}</div>
          </div>
        </div>
        <el-empty
          description="没有数据了~"
          v-if="detalist.length == 0 && radio == 1"
        ></el-empty>
        <div class="leadTo-content3" v-if="radio == 2">
          <div class="leadTo-title">
            <div class="leadTo-desc">基础信息</div>
          </div>
          <div class="flex flex-box">
            <div class="h1" style="margin-right: 55px; margin-top: 20px">
              <div class="flex">
                <div class="flex leadTo-list">
                  <div>客户名称</div>
                  <div>{{ cluelist.clientName }}</div>
                </div>
                <div class="flex leadTo-list">
                  <div>联系人姓名</div>
                  <div>{{ cluelist.contactName }}</div>
                </div>
              </div>
              <div class="flex">
                <div class="flex leadTo-list">
                  <div>联系电话</div>
                  <div>{{ cluelist.contactPhone }}</div>
                </div>
                <div class="flex leadTo-list">
                  <div>联系人职务</div>
                  <div>{{ cluelist.contactPosition }}</div>
                </div>
              </div>
              <div class="flex">
                <div class="flex leadTo-list">
                  <div>客户来源</div>
                  <div>{{ selectDictlabel(Source, cluelist.source) }}</div>
                </div>
                <div class="flex leadTo-list">
                  <div>客户归属</div>
                  <div>{{ cluelist.ownership }}</div>
                </div>
              </div>
              <div class="flex">
                <div class="flex leadTo-list">
                  <div>负责人</div>
                  <div>{{ cluelist.chargeName }}</div>
                </div>
                <div class="flex leadTo-list">
                  <div>客户等级</div>
                  <div>{{ selectDictlabel(Grade, cluelist.grade) }}</div>
                </div>
              </div>
              <div class="flex">
                <div class="flex leadTo-list">
                  <div>所属行业</div>
                  <div>{{ selectDictlabel(Industry, cluelist.industry) }}</div>
                </div>
                <div class="flex leadTo-list">
                  <div>年营收</div>
                  <div>{{ selectDictlabel(AnnualTax, cluelist.revenue) }}</div>
                </div>
              </div>
              <div class="flex">
                <div class="flex leadTo-list">
                  <div>年税收</div>
                  <div>{{ selectDictlabel(AnnualTax, cluelist.income) }}</div>
                </div>
                <div class="flex leadTo-list">
                  <div>所在地区</div>
                  <div v-show="cluelist.provice">
                    {{ cluelist.provice }}/{{ cluelist.city }}/{{
                      cluelist.borough
                    }}
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex leadTo-list">
                  <div>详细地址</div>
                  <div>{{ cluelist.address }}</div>
                </div>
                <div class="flex leadTo-list">
                  <div>备注</div>
                  <div>{{ cluelist.remark }}</div>
                </div>
              </div>
                <div class="flex">
                <div class="flex leadTo-list">
                  <div>客户介绍</div>
                  <div :style="{width:cluelist.introduction?'100%':''}">{{ cluelist.introduction }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- leadTo-content -->
        <div class="" v-if="radio == 2">
          <div class="leadTo-title">
            <div class="leadTo-desc">系统信息</div>
          </div>
          <div class="flex flex-box">
            <div class="h1" style="margin-right: 55px">
              <div class="flex leadTo-list">
                <div style="width: 90px">创建人</div>
                <div>{{ cluelist.createdName }}</div>
              </div>
              <div class="flex leadTo-list">
                <div style="width: 90px">最近更新人</div>
                <div>{{ cluelist.updatedName }}</div>
              </div>
            </div>
            <div>
              <div class="flex leadTo-list">
                <div style="width: 90px">创建时间</div>
                <div>{{ cluelist.createTime }}</div>
              </div>
              <div class="flex leadTo-list">
                <div style="width: 90px">最近更新时间</div>
                <div>{{ cluelist.updateTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <el-descriptions title="基础信息" v-if="radio == 2">
          <el-descriptions-item label="公司名称">{{
            cluelist.companyName
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人姓名">{{
            cluelist.contactName
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            cluelist.contactPhone
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人职务">{{
            cluelist.contactPosition
          }}</el-descriptions-item>
          <el-descriptions-item label="所属行业">{{
            selectDictlabel(Industry, cluelist.industry)
          }}</el-descriptions-item>
          <el-descriptions-item label="年营收">{{
            cluelist.annualRevenue
          }}</el-descriptions-item>
          <el-descriptions-item label="所在地区"></el-descriptions-item>
          <el-descriptions-item label="详细地址"
            >{{ cluelist.address}}</el-descriptions-item
          >
          <el-descriptions-item label="备注">{{
            cluelist.remark
          }}</el-descriptions-item>
        </el-descriptions> -->
        <!-- <el-descriptions title="基础信息" v-if="radio == 2">
          <el-descriptions-item label="领取/分配人">{{
            cluelist.assignerName
          }}</el-descriptions-item>
          <el-descriptions-item label="领取/分配时间">{{
            cluelist.assignerDate
          }}</el-descriptions-item>
          <el-descriptions-item label="最近更新人">{{
            cluelist.updatedName
          }}</el-descriptions-item>
          <el-descriptions-item label="最近更新时间">{{
            cluelist.updateTime
          }}</el-descriptions-item>
        </el-descriptions> -->
        <div v-if="radio == 3">
          <div style="padding-bottom: 12px">
            <el-button
              type="primary"
              size="small"
              @click.stop="newDemand(cluelist)"
              >新增需求</el-button
            >
          </div>
          <div
            class="demand-box"
            v-for="(item, index) in reviewList"
            :key="index"
            @click.stop="demandDetails(item)"
          >
            <div class="flex flex-box flex-header">
              <div class="flex flex-box">
                <div>需求：</div>
                <div>{{ selectDictlabel(Product, item.productId) }}</div>
              </div>
              <div class="demand-btn">
                <div>
                  {{ selectDictlabel(FollowupStatus, item.followupStatus) }}
                </div>
              </div>
            </div>
            <div class="flex" style="padding-bottom: 5px">
              <div class="demand-card1">
                <div class="flex">
                  <div style="width: 88px">负责人：</div>
                  <div style="width: 150px">{{ item.ownership }}</div>
                </div>
              </div>
              <div class="demand-card2" style="padding-left: 5px">
                <div class="flex">
                  <div style="width: 88px">意向等级：</div>
                  <div style="width: 150px">
                    {{
                      selectDictlabel(intentionlevelList, item.intentionlevel)
                    }}
                  </div>
                </div>
              </div>
              <div class="demand-card3">
                <div class="flex">
                  <div style="width: 88px">最后跟进：</div>
                  <div style="width: 200px">
                    {{ item.followTime }}
                    {{ selectDictlabel(followupMethod, item.followupMethod) }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="details-css">
                {{ item.details }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="radio == 4">
          <div style="padding-bottom: 12px" class="temp-upfile">
            <el-upload
              class="upload-demo3"
              :headers="header"
              :action="uploadUrl"
              multiple
              :file-list="tempFileList"
              :on-success="handleTempSuccess"
              :on-error="handleError"
              :before-upload="beforeupload"
              element-loading-text="数据转换中，请耐心等待"
              element-loading-spinner="el-icon-loading"
              :class="['upload-temp3', loading ? 'mt-30' : '']"
            >
              <el-button type="primary" size="small">上传附件</el-button>
            </el-upload>
          </div>
          <div>
            <el-table
              style="width: 100%"
              tooltip-effect="dark"
              :data="appendList"
              ref="appendList"
              v-if="appendList.length > 0"
              height="450px"
            >
              <ex-table-column
                key="1"
                prop="createTime"
                label="上传时间"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
              </ex-table-column>
              <ex-table-column
                key="2"
                prop="annexName"
                label="附件名称"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.annexName }}</div>
                </template>
              </ex-table-column>
              <ex-table-column
                key="3"
                prop="annexSize"
                label="大小"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.annexSize }} KB</div>
                </template>
              </ex-table-column>
              <ex-table-column
                key="4"
                prop=""
                label="操作"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="flex-box table-fixed f-center">
                    <el-button
                      type="text"
                      v-preventReClick="3000"
                      @click.stop="downloadFlie(scope.row.loadUrl)"
                      >下载</el-button
                    >
                    <el-button
                      type="text"
                      v-preventReClick="3000"
                      @click.stop="clientDelete(scope.row.id)"
                      >删除</el-button
                    >
                  </div>
                </template>
              </ex-table-column>
            </el-table>
            <el-table
              id="table"
              style="width: 100%"
              tooltip-effect="dark"
              :data="appendList"
              ref="appendList"
              v-else
            >
              <el-table-column
                key="1"
                prop=""
                label="上传时间"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                key="2"
                prop=""
                label="附件名称"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                key="3"
                prop=""
                label="大小"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                key="4"
                prop=""
                label="操作"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
                <template>
                  <div class="flex-box table-fixed f-center">
                    <el-button type="text" v-preventReClick="3000"
                      >下载</el-button
                    >
                    <el-button type="text" v-preventReClick="3000"
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 新增需求 -->
    <el-dialog
      :title="'新增需求'"
      :visible.sync="newDemandDialog"
      width="624px"
      @close="closeCheck2('demandForms')"
    >
      <div class="dialog-cont">
        <el-form
          ref="demandForms"
          :inline="true"
          :model="demandForms"
          :rules="demandRules"
          class="demandForms"
        >
          <el-form-item label="所属客户" prop="clientId" label-width="100px">
            <el-select
              v-model="demandForms.clientId"
              filterable
              placeholder="请选择所属客户"
              :loading="loading"
              style="width: 434px"
              :disabled="true"
              :remote-method="(val) => remoteSupplierDropList(val, 2)"
            >
              <el-option
                v-for="item in customerList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="需求产品" prop="productId" label-width="100px">
            <el-select
              v-model="demandForms.productId"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in Product"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人" prop="principal" label-width="100px">
            <el-select
              v-model="demandForms.principal"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="紧急程度"
            prop="intentionlevel"
            label-width="100px"
          >
            <el-select
              v-model="demandForms.intentionlevel"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in intentionlevelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="跟进状态"
            prop="followupStatus"
            label-width="100px"
          >
            <el-select
              v-model="demandForms.followupStatus"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in FollowupStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="需求详情" prop="details" label-width="100px">
            <el-input
              type="textarea"
              :rows="3"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="demandForms.details"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="newDemandDialog = false">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirm2('demandForms')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 需求详情 -->
    <el-dialog
      title="需求详情"
      :visible.sync="dialogDetail"
      :close-on-click-modal="false"
      width="980px"
    >
      <div class="page_box2">
        <div class="glue-details">
          <div class="flex flex-center">
            <div class="company-name">
              需求：{{ selectDictlabel(Product, cluelist2.productId) }}
            </div>
            <!-- <div>
              <el-dropdown
                style="padding-left: 10px; margin-right: 14px"
                trigger="click"
                @command="MenuMore"
              >
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="beforeHandleCommand('transferTohim', cluelist)"
                    >转给他人</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="beforeHandleCommand('delete', cluelist)"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button size="small" type="primary" @click.stop="compile"
                >编辑</el-button
              >
              <el-select
                v-model="form.followupStatus"
                style="width: 100px; margin-left: 10px"
                placeholder="请选择"
                size="small"
                @change="getFollowup"
              >
                <el-option
                  v-for="(item, index) in FollowupStatus"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div class="flex flex-center information">
            <div style="margin-right: 8px" class="flex">
              <div class="flex info-list">
                <div>所属客户：</div>
                <div class="link" @click="details(cluelist2)">
                  {{ cluelist2.clientName }}
                </div>
              </div>
              <div class="flex info-list">
                <div>归属：</div>
                <div>{{ cluelist2.ownership }}</div>
              </div>
              <div class="flex info-list">
                <div>负责人：</div>
                <div>
                  {{ cluelist2.departmentName }}-{{ cluelist2.chargeName }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex info-list">
              <div>紧急程度：</div>
              <div>
                {{
                  selectDictlabel(intentionlevelList, cluelist2.intentionlevel)
                }}
              </div>
            </div>
            <div class="flex info-list">
              <div>下次跟进：</div>
              <div>{{ cluelist2.followupDateStr }}</div>
            </div>
            <div class="flex info-list">
              <div>最近更新：</div>
              <div>{{ cluelist2.updateTime }}</div>
            </div>
            <!-- <div>
              
             
            </div> -->
          </div>
          <div class="cluelist-details">
            <div>{{ cluelist2.details }}</div>
          </div>
        </div>
        <div style="padding-bottom: 10px">
          <div class="company-name">跟进动态</div>
        </div>
        <div>
          <div class="label-button">
            <button :class="{ active: number2 == -1 }" @click="labelBut2(-1)">
              全部
            </button>
            <button :class="{ active: number2 == 2 }" @click="labelBut2(2)">
              跟进记录
            </button>
            <button :class="{ active: number2 == 1 }" @click="labelBut2(1)">
              系统动态
            </button>
          </div>
        </div>
        <div class="followUp-dynamics" v-if="detalist2.length != 0">
          <div v-for="(item, index) in detalist2" :key="index">
            <div>
              {{ item.createTime }} {{ item.departmentName }} -
              {{ item.createdName }}
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 1 && item.followAs"
                >【{{ selectDictlabel(FollowAs, item.followAs) }}】</em
              >
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 2 && item.followupMethod"
                >【{{
                  selectDictlabel(followupMethod, item.followupMethod)
                }}】</em
              >
            </div>
            <div v-if="item.followupContent">
              跟进内容：{{ item.followupContent }}
            </div>
            <div v-if="item.followupStatusRecord">
              跟进状态：{{ item.followupStatusRecord }}
            </div>
            <div v-if="item.chargeRecord">负责人：{{ item.chargeRecord }}</div>
          </div>
        </div>
        <el-empty description="没有数据了~" v-else></el-empty>
      </div>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import pageTitleBar from "@/components/page-title-bar.vue";
import {
  clientList,
  commonType,
  clientFollow,
  departmentDepManagerList,
  clientCharge,
  addClientClient,
  commonProvices,
  clientDelete,
  clientEdit,
  clientDetail,
  clientAssetFollow,
  clientAnnexList,
  clientAnnexDelete,
  assetsDemandList,
  depClientList,
  assetsDemandDemand,
  editDemandDemand,
} from "@/api/report";
let optionsall = [];
export default {
  inject: ["reload"],
  components: { pageTitleBar },
  data() {
    var checkPhone = (rule, value, callback) => {
      console.log(value);
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      title: "客户",
      newDemandDialog: false,
      tabStatus: "null",
      detalist: [],
      detalist2: [],
      page: 1,
      radio: 1,
      perPage: 10,
      loading: false,
      isApply: false,
      tabLoading: false,
      checked: false,
      writeFollowUpDialog: false,
      demandTransferDialog: false,
      dialogVisible: false,
      leadToDialog: false,
      dialogDetail: false,
      dialogDetail2: false,
      clientAssetFollow: false,
      count: 0,
      customerList: [],
      demandForms: {
        clientId: "",
        productId: "",
        intentionlevel: "",
        details: "",
        principal: "",
        followupStatus: "",
        Id: "",
      },
      customerRules: {
        productId: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        intentionlevel: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        //chargeid: [
        //    {
        //        required: true,
        //        message: "请选择",
        //        trigger: "change",
        //    },
        //],
        details: [
          {
            required: true,
            message: "请输入需求详情",
            trigger: "blur",
          },
        ],
      },
      tempFileList: [],
      batchForm: {
        followupContent: "",
        followupValue: 1092,
        clueFollowup: "",
        entryDate: "",
      },
      demandRules: {
        clientId: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        principal: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        productId: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        intentionlevel: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        followupStatus: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        details: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
      },
      appendList: [],
      list: [],
      customerform: {},
      clientForm: {
        clientGrade: "",
        companyName: "",
        contactName: "",
        contactPhone: "",
        ownership: "",
        category: "",
        industry: "", //所属行业
        income: "", //年营收
        annualTax: "", //年税收
        region: [],
        clientSource: "", //客户来源
        principal: "",
        roleid: [],
        address: "", //详细地址
        Introduction: "",
      },
      roleid: [],
      countindex: 0,
      intentionlevelList: [],
      followUpStatus: [],
      clientRules: {
        principal: [
          {
            required: true,
            message: "请输入负责人",
            trigger: "change",
          },
        ],
        companyName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        ownership: [
          {
            required: true,
            message: "请输入客户归属",
            trigger: "blur",
          },
        ],
        clientGrade: [
          {
            required: true,
            message: "请选择客户等级",
            trigger: "change",
          },
        ],
        clientSource: [
          {
            required: true,
            message: "请选择客户来源",
            trigger: "change",
          },
        ],
        category: [
          {
            required: true,
            message: "请选择跟进状态",
            trigger: "change",
          },
        ],
        industry: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change",
          },
        ],
      },
      clientList: [
        {
          productId: "",
          intentionlevel: "",
          followupStatus: 1018,
          details: "",
        },
      ],
      batchRules: {
        followupValue: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        followupContent: [
          {
            required: true,
            message: "请输入跟进内容",
            trigger: "blur",
          },
        ],
        clueFollowup: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
      commonlist: "",
      form: {
        taskTitle: "",
        source: "",
        product: "",
        grade: "",
        Industry: "",
        AnnualTax: "",
        auditDate: [],
        customerName: "", // 客户名称
        principal: "", // 负责人/归属人
      },
      demandForm: {
        principal: "",
        transferInstruction: "",
      },
      demandRules: {
        principal: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
      },
      options: [],
      advanceDataList: [],
      selectionItem: [],
      cumulativeList: [],
      clueFollowupStatus: "",
      followupMethod: "",
      Grade: "",
      Category: "",
      commonlist: "",
      Industry: "",
      Product: "",
      AnnualTax: "",
      FollowupStatus: "",
      Source: "",
      FollowAs: "",
      demandId: "",
      clientType: 1,
      number: -1,
      number2: -1,
      cluelist: "",
      cluelist2: "",
      applytype: 1,
      reviewList: [],
      provice: "",
      city: "",
      borough: "",
      indexs: 0,
    };
  },
  watch: {
    cluelist(val) {
      if (val.id) {
        this.getFollow();
        this.getClist();
        this.getAssetsDemandList();
      }
    },
    cluelist2(val) {
      if (val.id) {
        this.getFollow2();
      }
    },
    checked(val) {
      console.log(val);
      this.getData(1);
    },
  },
  computed: {
    header: function () {
      let that = this;
      return {
        Authorization: "Bearer " + localStorage.getItem("Authorization"),
      };
    },
    uploadUrl: function () {
      return (
        this.globalData.orgHost + "client/upload-annex?Id=" + this.cluelist.id
      );
    },
  },
  mounted() {
    this.getData(1);
    this.getCommon();
    this.depClient();
    this.getDepartmentList();
    this.getCommonProvices();
  },
  methods: {
    querySearch(queryString, cb) {
      let _this = this;
      let restaurants = _this.Industry;
      let arr = [];
      _this.Industry.forEach((item, index) => {
        if (item.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0) {
          let obj = {
            value: item.label,
            id: item.value,
          };
          arr.push(obj);
        }
      });

      console.log(arr);
      return cb && cb(arr);
    },
    handleAddSupplier3(item) {
      console.log(item);
      this.clientForm.industry = item.value;
      this.clientForm.industryId = item.id;
    },
    // 新增/编辑需求
    async confirm2(form) {
      let _this = this;
      // getAssetsDemandList
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          try {
            let params = {
              chargeId: _this.demandForms.principal || "",
              clientId: _this.demandForms.clientId || "",
              productId: _this.demandForms.productId || "",
              intentionlevel: _this.demandForms.intentionlevel || "",
              details: _this.demandForms.details.replace(/\n/g, ",") || "",
              followupStatus: _this.demandForms.followupStatus || "",
            };
            if (_this.applytype == 2 && _this.demandForms.Id) {
              params.id = _this.demandForms.Id;
            }
            console.log();
            _this.isApply = true;
            let response;
            if (_this.applytype == 2) {
              response = await editDemandDemand(params);
            } else {
              response = await assetsDemandDemand(params);
            }
            if (response.statusCode == 200) {
              _this.newDemandDialog = false;
              _this.getData(1);
              _this.getAssetsDemandList();
              _this.$message({
                type: "success",
                message: _this.applytype == 1 ? "新增需求成功" : "编辑需求成功",
              });
              if (_this.applytype == 2 && this.dialogDetail) {
                _this.demandDetails(_this.cluelist);
              }
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
            _this.isApply = false;
          } catch (error) {
            new Error(error);
          }
        }
      });
    },
    remoteSupplierDropList(val, type) {
      let _this = this;
      let params = {
        KeyWord: val,
      };
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-demand/dep-client-list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let list = res.data;
            list.map((item, index) => {
              let obj = {};
              obj.label = item.userName;
              obj.value = item.userId;
              if (type == 1) {
                _this.customerList.push(obj);
              } else {
                _this.customerList.push(obj);
              }
            });
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    async getFollow2() {
      let _this = this;
      // try {
      let params = {
        id: _this.cluelist2.id,
      };
      if (_this.number2 != -1) {
        params.FollowupType = _this.number2;
      }
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-demand/demand-follow",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data } = res;
            console.log(data);
            _this.detalist2 = data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    // 所属客户
    async depClient() {
      let _this = this;
      let params = {
        KeyWord: "",
      };
      try {
        let response = await depClientList(params);
        if (response.statusCode == 200) {
          let list = response.data;
          list.map((item, index) => {
            let obj = {};
            obj.label = item.userName;
            obj.value = item.userId;
            _this.customerList.push(obj);
          });
          console.log(_this.customerList);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 需求详情
    async demandDetails(row) {
      let _this = this;
      console.log(row);
      this.dialogDetail = true;
      this.applytype == 2;
      let params = {
        Id: row.id,
      };
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-demand/demand",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.cluelist2 = res.data;
            _this.form.followupStatus = _this.cluelist.followupStatus;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    // 选择省市区
    provinceChange(val) {
      console.log(val, "val", this.roleid);
      let provcode;
      if (this.roleid.length > 1) {
        provcode = this.roleid.filter((item, index) => {
          return item.provCode == val[0];
        });
      }
      console.log(provcode);
      for (let item of provcode) {
        if (val[0] == item.provCode) {
          this.provice = item.label;
        }
        if (item.children) {
          for (let it of item.children) {
            if (val[1] == it.provCode) {
              this.city = it.label;
            }
            if (it.children) {
              for (let qu of it.children) {
                if (val[2] == qu.provCode) {
                  this.borough = qu.label;
                }
              }
            }
          }
        }
      }
      console.log("地区", this.provice, this.city, this.borough);
    },
    newDemand(row) {
      let _this = this;
      console.log(row);
      _this.demandForms.clientId = row.id;
      _this.newDemandDialog = true;
    },
    closeCheck2(formName) {
      console.log(formName);
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.demandForms = {
        clientId: "",
        productId: "",
        intentionlevel: "",
        details: "",
        principal: "",
        followupStatus: "",
        Id: "",
      };
    },
    handleInput(value) {
      const reg = /^[\d\.]+$/;
      if (value && !reg.test(value)) {
        this.clientForm.contactPhone = value.replace(/[^\d\.]/g, "");
      }
    },
    // 获取需求列表 assetsDemandList
    async getAssetsDemandList() {
      let _this = this;
      // try {
      let params = {
        pageIndex: 1,
        pageSize: 100000,
        ClientId: _this.cluelist.id,
      };
      // assets-demand/list
      // let response = await assetsDemandList(params);
      // if (response.statusCode == 200) {
      //   let { data, cumulativeData, totalCount } = response.data;
      //   _this.reviewList = data;
      // }

      _this.$ajax({
        url: _this.globalData.orgHost + "assets-demand/list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data, cumulativeData, totalCount } = res.data;
            _this.reviewList = data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });

      // } catch (error) {}
    },
    // 获取附件列表  clientAnnexList
    async getClist() {
      let _this = this;
      try {
        let parmas = {
          Id: _this.cluelist.id,
        };
        let response = await clientAnnexList(parmas);
        if (response.statusCode == 200) {
          _this.appendList = response.data;
        } else {
          _this.$message({
            type: "error",
            message: file.errors,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 导出客户 client/export-client-list
    ClientExport(page, perPage) {
      let _this = this;
      let access_token = localStorage.getItem("Authorization");
      if (page == 1) {
        _this.page = 1;
      }
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      window.location.href = `${
        this.globalData.orgHost
      }client/export-client-list?pageIndex=${index}&pageSize=${size}&keyword=${
        _this.form.customerName
      }&Source=${_this.form.source}&ProductDemand=${_this.form.product}&Grade=${
        _this.form.grade
      }&Industry=${_this.form.Industry}&Revenue=${
        _this.form.AnnualTax
      }&AdminName=${_this.form.principal}&CreateTimeStart=${
        _this.form.auditDate[0] || ""
      }&CreateTimeEnd=${_this.form.auditDate[1] || ""}&Category=${
        _this.tabStatus == "null" ? "" : Number(_this.tabStatus)
      }&OnlySelf=${_this.checked}&Authorization=${access_token}`;
    },
    // 下载
    downloadFlie(url) {
      console.log(this.globalData.qiniu + url);
      window.open(this.globalData.qiniu + url);
    },
    // 删除附件
    async clientDelete(row) {
      let _this = this;
      try {
        let params = {
          id: row,
        };
        let response = await clientAnnexDelete(params);
        if (response.statusCode == 200) {
          _this.$message({
            type: "success",
            message: "附件删除成功",
          });
          _this.getClist();
        } else {
          _this.$message({
            type: "error",
            message: file.errors,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleError() {},
    beforeupload() {
      this.loading = true;
    },
    // 模版文件上传成功
    handleTempSuccess(file, fileList) {
      let _this = this;
      console.log(file);
      if (file.statusCode == 200) {
        _this.loading = false;
        _this.tempFileList = [fileList];
        _this.getClist();
        console.log(_this.uploadTypeObj);
      } else {
        _this.loading = false;
        _this.tempFileList = [fileList];
        _this.$message({
          type: "error",
          message: file.errors,
        });
      }
      _this.loading = false;
    },
    // 获取需求详情 client/client
    async details(row) {
      let _this = this;
      _this.dialogDetail2 = true;
      _this.applytype == 2;
      let params = {
        id: row.id,
      };
      _this.$ajax({
        url: _this.globalData.orgHost + "client/client",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.cluelist = res.data;
            console.log(_this.clueDetails);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
      // try {
      //   let response = await clientDetail(params);
      //   if (response.statusCode == 200) {
      //     _this.cluelist = response.data;
      //   } else {
      //     _this.$message({
      //       type: "error",
      //       message: response.errors,
      //     });
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    labelBut(val) {
      console.log(1);
      this.number = val;
      this.getFollow();
    },
    labelBut2(val) {
      console.log(1);
      this.number2 = val;
      this.getFollow2();
    },
    // 跟进记录 client/asset-follow
    async getFollow() {
      let _this = this;
      // try {
      console.log(_this.cluelist.id);
      let params = {
        Id: _this.cluelist.id,
      };
      if (_this.number != -1) {
        params.FollowupType = _this.number;
      }

      _this.$ajax({
        url: _this.globalData.orgHost + "client/asset-follow",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data } = res.data;
            console.log(data);
            _this.detalist = data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
      //   let response = await clientAssetFollow(params);
      //   if (response.statusCode == 200) {
      //     let { data } = response.data;
      //     _this.detalist = data;
      //   }
      //   console.log(response);
      // } catch (error) {
      //   console.log(error);
      // }
    },
    leadToDelete(index) {
      if (this.clientList.length >= 1) {
        this.clientList.splice(index, 1);
      } else {
        this.$message({
          type: "error",
          message: "至少添加一个需求",
        });
      }
    },
    toChineseNumber(num, cont) {
      let _this = this;
      let index = this.ll.toChineseNumber(num);
      _this.indexs = cont;
      return index;
    },
    // 新增客户需求
    addToContinue() {
      let _this = this;
      if (this.clientList.length >= 5) {
        this.$message({
          type: "error",
          message: "客户需求最多不能超过5个",
        });
        return;
      }
      let obj = {
        productId: "",
        intentionlevel: "",
        followupStatus: 1018,
        details: "",
      };
      this.$message({
        type: "success",
        message: "添加一个需求成功",
      });
      this.clientList.push(obj);
      console.log(_this.$refs.leadtoContent.length);
      this.$nextTick(() => {
        _this.$refs.leadtoContent[_this.clientList.length - 1].focus();
      });
    },
    cancel2() {
      this.demandTransferDialog = false;
    },
    cancelkehu(form) {
      this.leadToDialog = false;
    },
    // 确认转客户
    confirmTransfer(form) {
      let _this = this;
      console.log(_this.clientForm.region);
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            clientName: _this.clientForm.companyName || "", //客户名称
            contactName: _this.clientForm.contactName || "", //联系人
            contactPhone: _this.clientForm.contactPhone || "", // 联系人电话
            contactPosition: _this.clientForm.contactJob || "", //联系人职务
            source: _this.clientForm.clientSource || "", //客户来源
            ownership: _this.clientForm.ownership || "", //客户归属
            chargeId: _this.clientForm.principal || "",
            grade: _this.clientForm.clientGrade || "", //客户等级
            // category: _this.clientForm.category || "", //跟进状态
            // industry: _this.clientForm.industry || "",
            income: _this.clientForm.income || "", //年营收
            revenue: _this.clientForm.annualTax || "", //年税收
            address: _this.clientForm.address || "", //详细地址
            remark: _this.clientForm.remark || "", //备注
            Introduction: _this.clientForm.Introduction,
          };
          if (_this.clientForm.industryId) {
            params.industry = _this.clientForm.industryId;
          } else {
            params.industry = null;
            params.IndustryName = _this.clientForm.industry;
          }
          if (_this.clientForm.region.length) {
            params.proviceId = _this.clientForm.region[0] || "";
            params.provice = _this.provice;
            params.cityId = _this.clientForm.region[1] || "";
            params.city = _this.city;
            params.boroughId = _this.clientForm.region[2] || "";
            params.borough = _this.borough;
          }
          if (_this.clientType == 1 && _this.clientList.length) {
            params.demandList = _this.clientList;
          } else {
            params.id = _this.demandId;
          }

          try {
            let response;
            if (_this.clientType == 1) {
              _this.$refs["customerform"][_this.indexs].validate(
                async (valid) => {
                  if (valid) {
                    _this.isApply = true;
                    response = await addClientClient(params);
                    if (response.statusCode == 200) {
                      _this.leadToDialog = false;
                      _this.advanceDataList = [];
                      _this.getData();
                      _this.reload();
                      _this.$message({
                        type: "success",
                        message:
                          _this.clientType == 1
                            ? "客户新增成功"
                            : "客户编辑成功",
                      });
                      if (_this.applytype == 2) {
                        _this.details(_this.cluelist);
                      }
                    } else {
                      _this.$message({
                        type: "error",
                        message: response.errors,
                      });
                    }
                    _this.isApply = false;
                  }
                }
              );
            } else {
              _this.isApply = true;
              response = await clientEdit(params);
              if (response.statusCode == 200) {
                _this.leadToDialog = false;
                _this.advanceDataList = [];
                _this.getData();
                _this.reload();
                _this.$message({
                  type: "success",
                  message:
                    _this.clientType == 1 ? "客户新增成功" : "客户编辑成功",
                });
                if (_this.applytype == 2) {
                  _this.details(_this.cluelist);
                }
              } else {
                _this.$message({
                  type: "error",
                  message: response.errors,
                });
              }
              _this.isApply = false;
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 删除客户
    async clientDel() {
      let _this = this;
      let params = {
        id: _this.demandId,
      };
      try {
        let response = await clientDelete(params);
        if (response.statusCode == 200) {
          _this.getData();
          _this.$message({
            type: "success",
            message: "删除成功",
          });
          _this.dialogVisible = false;
          if (_this.applytype == 2) {
            _this.details(_this.cluelist);
          }
        } else {
          _this.$message({
            type: "error",
            message: response.errors,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 确认负责人转移
    async confirm(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            id: _this.demandId,
            chargeId: _this.demandForm.principal,
            followupContent: _this.demandForm.transferInstruction,
          };
          try {
            let response = await clientCharge(params);
            if (response.statusCode == 200) {
              _this.getData();
              _this.$message({
                type: "success",
                message: "转移成功",
              });
              _this.demandTransferDialog = false;
              if (_this.applytype == 2) {
                _this.details(_this.cluelist);
              }
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    beforeHandleCommand(flag, command) {
      return {
        flag: flag,
        command: command,
      };
    },
    closeCheck(formName) {
      console.log(formName);
      this.$refs[formName].resetFields();

      this.clientForm = {
        clientGrade: "",
        companyName: "",
        contactName: "",
        contactPhone: "",
        ownership: "",
        category: "",
        industry: "", //所属行业
        income: "", //年营收
        annualTax: "", //年税收
        region: [],
        clientSource: "", //客户来源
        principal: "",
        roleid: [],
        address: "", //详细地址
      };
      this.clientList = [
        {
          productId: "",
          intentionlevel: "",
          followupStatus: 1018,
          details: "",
        },
      ];
      this.$refs[formName].clearValidate();
    },
    // 写跟进
    followUp(row) {
      let _this = this;
      _this.commonlist = row;
      _this.writeFollowUpDialog = true;
    },
    MenuMore(flag) {
      console.log(flag);
      if (flag.flag == "transferTohim") {
        this.demandTransferDialog = true;
        this.demandId = flag.command.id;
      } else if (flag.flag == "delete") {
        this.dialogVisible = true;
        this.demandId = flag.command.id;
      } else if (flag.flag == "redact") {
        this.leadToDialog = true;
        this.clientType = 2;
        this.demandId = flag.command.id;
        this.clientForm.companyName = flag.command.clientName;
        this.clientForm.remark = flag.command.remark;
        this.clientForm.income = flag.command.income;
        this.clientForm.industry = this.selectDictlabel(
          this.Industry,
          flag.command.industry
        ); //  //Number(flag.command.industry);
        this.clientForm.annualTax = flag.command.revenue;
        this.clientForm.contactName = flag.command.contactName;
        this.clientForm.contactPhone = flag.command.contactPhone;
        this.clientForm.contactJob = flag.command.contactPosition;
        this.clientForm.clientSource = flag.command.source;
        this.clientForm.ownership = flag.command.ownership;
        this.clientForm.clientGrade = flag.command.grade;
        this.clientForm.category = flag.command.category;
        this.clientForm.address = flag.command.address;
        this.clientForm.principal = flag.command.chargeId;
        this.clientForm.Introduction = flag.command.introduction;
        this.clientForm.region = [
          flag.command.proviceId,
          flag.command.cityId,
          flag.command.boroughId,
        ];
        console.log(flag.command.proviceId);
      }
    },
    // 详情编辑
    compile() {
      this.applytype = 2;
      this.clientType = 2;
      this.leadToDialog = true;
      this.demandId = this.cluelist.id;
      this.clientForm.companyName = this.cluelist.clientName;
      this.clientForm.remark = this.cluelist.remark;
      this.clientForm.income = this.cluelist.income;
      // this.clientForm.industry = Number(this.cluelist.industry);
     this.clientForm.industry = this.selectDictlabel(
          this.Industry,
          Number(this.cluelist.industry)
        );
      this.clientForm.annualTax = this.cluelist.revenue;
      this.clientForm.contactName = this.cluelist.contactName;
      this.clientForm.contactPhone = this.cluelist.contactPhone;
      this.clientForm.contactJob = this.cluelist.contactPosition;
      this.clientForm.clientSource = this.cluelist.source;
      this.clientForm.ownership = this.cluelist.ownership;
      this.clientForm.clientGrade = this.cluelist.grade;
      this.clientForm.category = this.cluelist.category;
      this.clientForm.address = this.cluelist.address;
      this.clientForm.principal = this.cluelist.chargeId;
      this.clientForm.Introduction = this.cluelist.introduction;
      this.clientForm.region = [
        this.cluelist.proviceId,
        this.cluelist.cityId,
        this.cluelist.boroughId,
      ];
    },
    // 重置
    reset(formName) {
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.getData(1);
    },
    handleClick(val) {
      console.log(val);
      this.getData(1);
    },
    // 查询数据
    inquire() {},
    handleSizeChange(val) {
      this.perPage = val;
      this.page = 1;
      this.getData(this.page, this.perPage);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData(this.page, this.perPage);
    },
    // 多选项目
    handleSelectionChange(val) {
      this.selectionItem = val;
      console.log(val);
    },
    // 循环请求产品
    selectCirclation(lab, value) {
      let _this = this;
      let arr = [];
      console.log(value);
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          console.log(item);
          let lable = _this.selectDictlabel(lab, item);
          arr.push(lable);
        });
      }
      return arr.join(",");
    },
    // 回显字典
    selectDictlabel(lab, value) {
      let _this = this;
      let label = this.ll.selectDictLabel(lab, value);
      return label;
    },
    // 获取部门负责人
    async getDepartmentList() {
      let _this = this;
      let params = {};
      try {
        let response = await departmentDepManagerList(params);
        console.log(response.data, "获取");
        if (response.statusCode == 200) {
          let list = response.data;
          list.map((item, index) => {
            let obj = {};
            obj.label = item.userName;
            obj.value = item.userId;
            _this.options.push(obj);
          });
          console.log(_this.options);
        }
      } catch (error) {
        new Error(error);
      }
    },
    // 新增客户
    newCustomer() {
      this.clientType = 1;
      if (this.$refs["batchForm"]) {
        this.$refs["batchForm"].clearValidate();
      }
      this.leadToDialog = true;
    },
    // 确定跟进记录
    async applyBatch(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            id: _this.commonlist.id,
            followupMethod: _this.batchForm.followupValue,
            followupContent: _this.batchForm.followupContent,
            followupStatus: _this.batchForm.clueFollowup,
            followupDate: _this.batchForm.entryDate,
          };
          _this.isApply = true;
          try {
            let response = await clientFollow(params);
            if (response.statusCode == 200) {
              _this.writeFollowUpDialog = false;
              _this.advanceDataList = [];
              _this.getData(1);
              _this.$message({
                type: "success",
                message: "写跟进成功",
              });
              if (_this.applytype == 2) {
                _this.details(_this.cluelist);
              }
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
            _this.isApply = false;
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 获取客户列表数据
    async getData(page, perPage) {
      let _this = this;
      _this.advanceDataList = [];
      if (page == 1) {
        _this.page = 1;
      }
      // try {
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      let params = {
        pageIndex: index,
        pageSize: size,
        keyword: _this.form.customerName || "",
        Source: _this.form.source || "",
        ProductDemand: _this.form.product || "",
        Grade: _this.tabStatus == "null" ? "" : Number(_this.tabStatus), //
        Industry: _this.form.Industry || "",
        Revenue: _this.form.AnnualTax || "",
        AdminName: _this.form.principal || "",
        CreateTimeStart: _this.form.auditDate[0] || "",
        CreateTimeEnd: _this.form.auditDate[1] || "",
        Category: _this.form.grade || "",
        OnlySelf: _this.checked,
      };
      _this.tabLoading = true;
      // client/list
      _this.$ajax({
        url: _this.globalData.orgHost + "client/list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data, cumulativeData, totalCount } = res.data;
            _this.advanceDataList = data;
            _this.count = totalCount;
            let m;
            _this.cumulativeList = cumulativeData.cumulative.map(
              (item, index) => {
                let obj = {
                  value: item.value,
                  num: item.num,
                };
                if (!item.value) {
                  obj.lable = item.lable;
                } else {
                  obj.lable = item.lable + "类";
                }
                return obj;
              }
            );
            console.log(_this.cumulativeList);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });
      //   let response = await clientList(params);
      //   if (response.statusCode == 200) {
      //     let { data, cumulativeData, totalCount } = response.data;
      //     _this.advanceDataList = data;
      //     _this.count = totalCount;
      //     let m;
      //     _this.cumulativeList = cumulativeData.cumulative;
      //   }
      //   _this.tabLoading = false;
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 公共类型
    async getCommon() {
      let _this = this;
      let params = {};
      // try {
      // let response = await commonType();
      // console.log(response);
      _this.$ajax({
        url: _this.globalData.orgCommonHost + "system/common-type",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let {
              FollowupMethod,
              ClueFollowupStatus,
              Grade,
              Industry,
              Product,
              AnnualTax,
              FollowupStatus,
              Source,
              FollowAs,
              Intentionlevel,
              Category,
            } = res.data;
            _this.clueFollowupStatus = ClueFollowupStatus;
            _this.followupMethod = FollowupMethod;
            _this.Grade = Grade;
            _this.Industry = Industry;
            _this.Category = Category;
            _this.Product = Product;
            _this.AnnualTax = AnnualTax;
            _this.FollowupStatus = FollowupStatus;
            _this.Source = Source;
            _this.FollowAs = FollowAs;
            _this.intentionlevelList = Intentionlevel;
            // console.log(FollowupMethod,ClueFollowupStatus);
          }
        },
      });

      // } catch (error) {
      //   console.log(error);
      // }
    },
    // commonProvices
    async getCommonProvices() {
      let _this = this;
      let params = {};
      try {
        let response = await commonProvices();
        console.log(response);
        if (response.statusCode == 200) {
          let new_data = [];
          for (let item of response.data) {
            item.value = item.provCode;
            item.label = item.provName;
            item.children = item.child;
            if (item.children) {
              for (let it of item.children) {
                it.value = it.provCode;
                it.label = it.provName;
                it.children = it.child;
                if (it.children) {
                  for (let qu of it.children) {
                    qu.value = qu.provCode;
                    qu.label = qu.provName;
                    qu.children = "";
                  }
                }
              }
            }
            new_data.push(item);
          }
          console.log(new_data);
          _this.roleid = new_data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.details-css {
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.cluelist-details {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px;
  background: #e7f4ff;
  margin-bottom: 12px;
}
.leadTo-list {
  /* width: 193px; */
  /* line-height: 40px; */
  padding-bottom: 20px;
  padding-left: 22px;
}
.leadTo-list div:first-child {
  width: 70px;
  padding-right: 26px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #999999;
}
.leadTo-list div:last-child {
  width: 193px;
  padding-left: 22px;
}
.leadTo-desc {
  padding-left: 6px;
  border-left: 3px solid #1890ff;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
}
.leadTo-title {
  width: 100%;
  padding: 10px 0 10px 4px;
  /* height: 40px;
  line-height: 40px; */
  margin-bottom: 12px;
  background: #f3f4f7;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  box-sizing: border-box;
}
.leadTo-container {
  height: 768px;
  overflow: scroll;
}
.company-name,
.client-name,
.details-text {
  width: 500px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}
.details-text {
  width: 150px;
}
.client-name {
  width: 200px;
}
.company-but {
  /* margin-left: 20px; */
  background: #f9a91e;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  text-align: center;
}
.fllowup-content {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.temp-upfile {
  position: relative;
}
.demand-btn {
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  background: rgba(43, 187, 154, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #2bbb9a;
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #2bbb9a;
}
.demand-card1 {
  width: 240px;
}
.demand-card2 {
  width: 240px;
}
.demand-card3 {
  width: 240px;
}
.flex-header {
  justify-content: space-between;
  padding-bottom: 10px;
}
.demand-box {
  padding: 13px 17px 21px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #f1f1f1;
}
.write-follow-up {
  background: #1890ff !important;
  color: #fff !important;
}
.followUp-dynamics {
  margin-top: 12px;

  width: 884px;
  height: 400px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #f1f1f1;
  overflow-y: auto;
}
.followUp-dynamics > div {
  padding: 16px 24px;
  line-height: 22px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #333333;
  border-bottom: 1px solid #f1f1f1;
}
.label-button button {
  margin-right: 10px;
  width: 70px;
  height: 24px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #666666;
  border: none;
  outline: none;
  background: #f5f5f5;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  cursor: pointer;
}
.label-button .active {
  background: #e5f3ff;
  border-radius: 2px 2px 2px 2px;
  color: #1890ff;
  opacity: 1;
  border: 1px solid #1890ff !important;
}
.info-list > div {
  line-height: 20px;
  padding-bottom: 8px;
}
.info-list div:first-child {
  width: 85px;
  text-align: left;
  color: #999999;
}
.info-list div:last-child {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.information {
  padding: 10px 0;
  width: 65%;
}
.company-name {
  font-size: 20px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
  line-height: 35px;
}
.flex-center {
  justify-content: space-between;
  align-items: center;
}
.page_box2 {
  padding: 26px 48px !important;
  background: #fff;
}
.el-dropdown-link {
  color: #1890ff;
}
/* .leadTo-box {
  height: 214px;
  overflow-y: scroll;
} */
.leadTo-card {
  width: 100%;
  box-sizing: border-box;
  padding: 11px 15px 0px;
  margin-bottom: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #f1f1f1;
}
.leadTo-desc {
  padding-left: 6px;
  border-left: 3px solid #1890ff;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
}
.leadTo-title {
  width: 100%;
  padding: 10px 0 10px 4px;
  /* height: 40px;
  line-height: 40px; */
  margin-bottom: 12px;
  background: #f3f4f7;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  box-sizing: border-box;
}
.flex-space-bewent {
  justify-content: space-between;
  align-content: center;
}
.leadTo-content,
.leadTo-content2 {
  padding: 16px 48px 0;
}
.leadTo-content2 {
  padding-top: 0;
}
.leadTo-content3 {
  padding: 16px 0;
}
.bd-bar {
  padding: 0 20px;
  background-color: #fff;
}
.table_page .page {
  padding-bottom: 10px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.search-bar_n {
  padding: 10px 0;
  margin-bottom: 0 !important;
}
</style>
<style>
.Introduction .el-form-item__content {
     width: 89% !important;
}
.page_box2 .el-radio-button--small .el-radio-button__inner {
  width: 120px;
}
.page_box .el-table th.el-table__cell {
  background: #f5f7fa;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-table .cell {
  white-space: nowrap !important;
  width: fit-content !important;
}
.dotted-line {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  margin: 0 auto;
  background: url("../../assets/image/dotted-line.png") no-repeat center;
  background-size: 100%;
  /* margin: -40px 20px 22.5px 2px; */
}
.el-form-item {
  margin-bottom: 10px;
}
.upload3-temp .el-upload-list,
.upload-temp3 .el-upload-list {
  /* display: none; */
  top: 0px;
  left: 92px;
  position: absolute;
}
</style>