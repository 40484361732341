<template>
  <div class="page_box">
    <page-title-bar :title="title"></page-title-bar>
    <el-container style="height: 769px; border: 1px solid #eee">
      <el-aside width="200px" style="background-color: #fff">
        <div class="company">公司组织架构</div>
        <!-- <el-menu :default-openeds="['1', '2']" :default-active="Routes">
          <el-submenu
            :index="item.index"
            v-for="(item, index) in dataList"
            :key="'info-' + index"
            v-show="item.children != null"
          >
            <template slot="title">{{ item.departmentName }}</template>
            <el-submenu
              :index="arr.index"
              v-for="(arr, idx) in item.children"
              :key="'info1-' + idx"
              v-show="arr.children != null"
            >
              <template slot="title">{{ arr.departmentName }}</template>
              <el-menu-item
                :index="tree.index"
                v-for="(tree, k) in arr.children"
                :key="'info2-' + k"
                v-show="tree.children != null"
                >{{ tree.departmentName }}</el-menu-item
              >
            </el-submenu>
            <el-menu-item
              :index="arr.index"
              v-for="(arr, idx) in item.children"
              :key="'info3-' + idx"
              v-show="arr.children == null"
              >{{ arr.departmentName }}</el-menu-item
            >
          </el-submenu>
        </el-menu> -->
        <div class="content">
          <ul v-for="(item, index) in dataList" :key="index">
            <li>
              <i class="el-icon-caret-left" @click.stop="checkll"></i
              ><em
                style="cursor: pointer"
                :class="{ 'active-num': number == item.id }"
                class="active-departmentname"
                @click.stop="checkll2(index, item.id)"
                >{{ item.departmentName }}</em
              >
            </li>
            <ul
              v-for="(arr, idx) in item.children"
              :key="idx"
              :class="{ active: typeList }"
            >
              <li class="list2">
                <i class="el-icon-caret-left"></i
                ><em
                  :class="{ 'active-num': number == arr.id }"
                  class="active-departmentname"
                  style="cursor: pointer"
                  @click.stop="checkll2(idx, arr.id)"
                  >{{ arr.departmentName }}</em
                >
              </li>
              <ul
                v-for="(tree, index2) in arr.children"
                :key="index2"
                :class="{ active: typeList }"
              >
                <li class="list3">
                  <em
                    style="cursor: pointer"
                    :class="{ 'active-num': number == tree.id }"
                    class="active-departmentname"
                    @click.stop="checkll2(index2, tree.id)"
                    >{{ tree.departmentName }}</em
                  >
                </li>
              </ul>
            </ul>
          </ul>
        </div>
      </el-aside>
      <el-container>
        <el-main class="main-content">
          <div class="bd-bar" v-loading="loading">
            <el-form
              ref="SearchForm"
              :inline="true"
              :model="form"
              label-width="80px"
              class="search-bar_n"
            >
              <div class="bd-bar2">
                <span class="bd-name">搜索：</span>
                <el-form-item label="" prop="type">
                  <el-select
                    v-model="form.type"
                    style="width: 120px"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="(item, index) in options"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="taskTitle">
                  <el-input
                    size="small"
                    style="width: 200px"
                    v-model="form.taskTitle"
                    placeholder="姓名/手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label-width="85px"
                  label="创建时间段"
                  prop="auditDate"
                >
                  <el-date-picker
                    v-model="form.auditDate"
                    size="small"
                    type="daterange"
                    align="center"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    size="small"
                    @click.stop="getData(1)"
                    >查询</el-button
                  >
                  <el-button size="small" @click="reset('SearchForm')"
                    >重置</el-button
                  >
                </el-form-item>
                <div class="add-member">
                  <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click.stop="addAmember"
                    >添加成员</el-button
                  >
                </div>
              </div>
            </el-form>
            <el-table
              :data="tableData"
              style="width: 100%"
              v-if="tableData.length > 0"
            >
              <ex-table-column
                prop="userName"
                label="真是姓名"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              />
              <ex-table-column
                prop="mobile"
                label="手机号"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              />
              <ex-table-column
                prop="position"
                label="职务"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              />
              <ex-table-column
                prop="departmentName"
                label="所在部门"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              />
              <ex-table-column
                prop="roleName"
                label="权限角色"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              />
              <ex-table-column
                prop="lastLoginTime"
                label="最后访问时间"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              />
              <ex-table-column
                prop="createUser"
                label="创建人"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              />
              <ex-table-column
                prop="createTime"
                label="创建时间"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              />
              <ex-table-column
                prop="departmentName"
                label="状态"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.status == 1">正常</div>
                  <div v-else-if="scope.row.status == 2">禁用</div>
                  <div v-else-if="scope.row.status == 3">未激活</div>
                </template>
              </ex-table-column>
              <ex-table-column
                prop="departmentName"
                label="操作"
                align="left"
                :autoFit="true"
                :fitHeader="true"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="small"
                      @click="handleuserEdit(scope.row)"
                    >
                      编辑
                    </el-button>
                    <el-button
                      type="text"
                      size="small"
                      v-if="scope.row.status == 1"
                      @click.stop="handleuserForbidden(scope.row, 2)"
                    >
                      禁用
                    </el-button>
                    <el-button
                      type="text"
                      size="small"
                      v-if="scope.row.status == 2"
                      @click.stop="handleuserForbidden(scope.row, 1)"
                    >
                      启用
                    </el-button>
                    <el-dropdown
                      trigger="click"
                      class="dropdown-position"
                      @command="handleCommand"
                    >
                      <span class="el-dropdown-link">
                        <i
                          style="color: #1890ff"
                          class="el-icon-more el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          icon="el-icon-delete-solid"
                          :command="beforeHandleCommand('delete', scope.row)"
                          >删除</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </ex-table-column>
            </el-table>
            <el-table :data="tableData" v-else>
              <el-table-column align="left" prop="date" label="真实姓名">
              </el-table-column>
              <el-table-column align="left" prop="name" label="手机号">
              </el-table-column>
              <el-table-column align="left" prop="address" label="职务">
              </el-table-column>
              <el-table-column align="left" prop="address" label="所在部门">
              </el-table-column>
              <el-table-column align="left" prop="address" label="权限角色">
              </el-table-column>
              <el-table-column align="left" prop="address" label="最后访问时间">
              </el-table-column>
              <el-table-column align="left" prop="address" label="创建人">
              </el-table-column>
              <el-table-column align="left" prop="address" label="创建时间">
              </el-table-column>
              <el-table-column align="left" prop="address" label="状态">
              </el-table-column>
              <el-table-column align="left" prop="address" label="操作">
              </el-table-column>
            </el-table>
            <div
              class="flex-box flex-box-end flex-v-ce"
              style="margin: 20px 0 20px"
            >
              <!-- pagination start -->
              <div class="page" style="margin-top: 0">
                <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="this.page"
                  :page-sizes="[10, 20, 30, 40, 50, 100]"
                  :page-size="this.perPage"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.count"
                >
                </el-pagination>
              </div>
              <!-- pagination end -->
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- 修改密码 -->
    <el-dialog
      :title="batchForm.typeNmber ? '编辑成员' : '新增成员'"
      :visible.sync="batchDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('batchForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="batchForm"
          :inline="true"
          :model="batchForm"
          :rules="batchRules"
          class="batchForm3"
        >
          <el-form-item
            label="姓名"
            prop="userName"
            label-width="130px"
            style="margin-top: 30px"
          >
            <el-input
              style="width: 434px"
              v-model="batchForm.userName"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone" label-width="130px">
            <el-input
              style="width: 434px"
              v-model="batchForm.phone"
              placeholder="请输入"
              :maxlength="11"
              onkeyup="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="department" label-width="130px">
            <el-cascader
              :props="{ checkStrictly: true }"
              v-model="batchForm.department"
              :options="roleid"
              :key="countindex"
              @change="optionlist"
              style="width: 434px"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="性别" prop="sex" label-width="130px">
            <template>
              <el-radio-group v-model="batchForm.sex">
                <el-radio
                  v-for="item in sex_list"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </template>
          </el-form-item>
          <el-form-item
            label="登录密码"
            prop="loginPassword"
            label-width="130px"
          >
            <el-input
              style="width: 434px"
              show-password
              v-model="batchForm.loginPassword"
              placeholder="请输入（如未设置，将默认手机号后6位）"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="职位" prop="position" label-width="130px">
            <el-input
              style="width: 434px"
              v-model="batchForm.position"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="权限角色"
            prop="authorityRole"
            label-width="130px"
          >
            <el-select
              style="width: 434px"
              v-model="batchForm.authorityRole"
              filterable
              :multiple="true"
              placeholder="请选择(可多选)"
            >
              <el-option
                v-for="item in roleList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="OpenID" prop="position" label-width="130px" style="margin-bottom: 50px">
            <el-input
              style="width: 434px"
              v-model="batchForm.openid"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply2"
            @click="applyBatch('batchForm',1)"
            style="margin-left: 20px"
            v-preventReClick="3000"
            v-if="batchForm.typeNmber == 1"
            >保存
          </el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm',2)"
            style="margin-left: 20px"
            v-preventReClick="3000"
            v-if="batchForm.typeNmber == 0"
            >保存
          </el-button>
          <el-button
            type="primary"
            :loading="isApply2"
            @click="applyBatch('batchForm',3)"
            style="margin-left: 20px"
            v-preventReClick="3000"
            v-if="batchForm.typeNmber == 0"
            >保存并添加下一个
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageTitleBar from "@/components/page-title-bar.vue";
import {
  managementList,
  departmentList,
  roleSelectList,
  newManagement,
  editManagement,
  managementDelete,
  managementDisableManagement,
} from "@/api/report";
export default {
  inject: ["reload"],
  data() {
    var checkPhone = (rule, value, callback) => {
      // if (!value) {
      //   return callback(new Error("请输入注册手机"));
      // } else {
      const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
      // }
    };
    return {
      title: "成员管理",
      perPage: 10,
      page: 1,
      count: 0,
      countindex: 0,
      options: [
        {
          label: "状态",
          value: "",
        },
        {
          label: "正常",
          value: "1",
        },
        {
          label: "禁用",
          value: "2",
        },
        {
          label: "未激活",
          value: "3",
        },
      ],
      batchForm: {
        userName: "",
        typeNmber: 0,
        sex: "",
        phone: "",
        position: "",
        department: "",
        authorityRole: "",
        loginPassword: "",
        Id: "",
        openid:""
      },
      batchRules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        department: [
          { required: true, message: "请选择所属部门", trigger: "change" },
        ],
        authorityRole: [
          { required: true, message: "请选择权限角色", trigger: "change" },
        ],
      },
      Routes: this.onRoutes(),
      tableData: [],
      dataList: [],
      roleList: [],
      roleid: [],
      sex_list: [
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 2,
        },
      ],
      number: 0,
      number2: 0,
      number3: 0,
      loading: false,
      isApply: false,
      isApply2: false,
      batchDialog: false,
      form: {
        taskTitle: "",
        type: "",
        auditDate: [],
        department: "",
      },
      typeList: "",
      isAdmin: sessionStorage.isAdmin,
      userId: sessionStorage.userId,
    };
  },
  components: {
    PageTitleBar,
  },
  mounted() {
    this.getData();
    this.getDeparmentList();
    this.getRoleSelect();
    console.log(sessionStorage.isAdmin, sessionStorage.userId);
  },
  methods: {
    onRoutes() {
      console.log(111);
      return "";
    },
    checkll() {
      if (!this.typeList) {
        this.typeList = "active";
      } else {
        this.typeList = "";
      }
    },
    checkll2(index, Department) {
      console.log(Department);
      this.number = Department;
      this.form.department = Department;
      this.getData();
    },
    // 上级部门
    optionlist(row) {},
    // 添加成员
    addAmember() {
      let _this = this;
      _this.batchDialog = true;
      _this.batchForm.typeNmber = 0;
    },
    // 获取数据列表
    async getData(page, perPage) {
      let _this = this;
      if (page == 1) {
        _this.page = 1;
      }
      // try {
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      let params = {
        pageIndex: index,
        pageSize: size,
        keyword: _this.form.taskTitle || "",
        Status: _this.form.type,
        StartTime: _this.form.auditDate[0] || "",
        EndTime: _this.form.auditDate[1] || "",
        Department: _this.form.department,
      };
      // let response = await managementList(params);
      _this.loading = true;
      _this.$ajax({
        url: _this.globalData.orgHost + "management/list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data, totalCount } = res.data;
            _this.tableData = data;
            _this.count = totalCount;
            setTimeout(() => {
              _this.loading = false;
            }, 300);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
          _this.selectionItem = [];
        },
      });
      // console.log(response);

      //   if (response.statusCode == 200) {
      //     let { data, totalCount } = response.data;
      //     _this.tableData = data;
      //     _this.count = totalCount;
      //     setTimeout(() => {
      //       _this.loading = false;
      //     }, 300);
      //   } else {
      //     _this.$message({
      //       type: "error",
      //       message: response.errors,
      //     });
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 获取部门
    async getDeparmentList() {
      let _this = this;
      let params = {
        pageIndex: 1,
        pageSize: 100000,
      };
      _this.loading = true;
      // let response = await departmentList(params);
      _this.$ajax({
        url: _this.globalData.orgHost + "department/list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.newDatalist(res.data);
            let new_data = [];
            for (let item of res.data) {
              item.value = item.id;
              item.label = item.departmentName;
              item.children = item.children;
              if (item.children) {
                for (let it of item.children) {
                  it.value = it.id;
                  it.label = it.departmentName;
                  it.children = it.children;
                  if (it.children) {
                    for (let qu of it.children) {
                      qu.value = qu.id;
                      qu.label = qu.departmentName;
                      qu.children = "";
                    }
                  }
                }
              }
              new_data.push(item);
            }
            console.log(new_data, "这是上级部门");
            _this.roleid = new_data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
          _this.selectionItem = [];
        },
      });
      // if (response.statusCode == 200) {
      //   _this.newDatalist(response.data);
      //   let new_data = [];
      //   for (let item of response.data) {
      //     item.value = item.id;
      //     item.label = item.departmentName;
      //     item.children = item.children;
      //     if (item.children) {
      //       for (let it of item.children) {
      //         it.value = it.id;
      //         it.label = it.departmentName;
      //         it.children = it.children;
      //         if (it.children) {
      //           for (let qu of it.children) {
      //             qu.value = qu.id;
      //             qu.label = qu.departmentName;
      //             qu.children = "";
      //           }
      //         }
      //       }
      //     }
      //     new_data.push(item);
      //   }
      //   console.log(new_data, "这是上级部门");
      //   _this.roleid = new_data;
      // } else {
      //   _this.$message({
      //     type: "error",
      //     message: response.errors,
      //   });
      // }
      setTimeout(() => {
        _this.loading = false;
      }, 1000);
    },
    // 循环遍历
    newDatalist(response) {
      console.log(response);
      let _this = this;
      response.forEach((item, index, arr) => {
        item.index = index + 1 + "";
        for (let k in item.children) {
          item.children[k].index = index + 1 + "-" + (k + 1);
          if (item.children[k].children != null) {
            // this.newDatalist(item.children[k].children)
            //  item.children[k].index = index + 1 + "-" + (k + 1) + '-' ;
            for (let j in item.children[k].children) {
              item.children[k].children[j].index =
                index + 1 + "-" + (k + 1) + "-" + (j + 1);
            }
          }
        }
      });
      _this.dataList = response;
      console.log(response);
    },
    // 获取权限角色
    async getRoleSelect() {
      let _this = this;
      try {
        let params = {};
        let response = await roleSelectList(params);
        console.log(response);
        if (response.statusCode == 200) {
          let role = [];
          response.data.forEach((item, index) => {
            let obj = {};
            obj.label = item.roleName;
            obj.value = item.id;
            role.push(obj);
          });
          _this.roleList = role;
          console.log(role);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 确定添加
    applyBatch(form,index) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            userName: _this.batchForm.userName || "",
            mobile: _this.batchForm.phone || "",
            sex: Number(_this.batchForm.sex),
            position: _this.batchForm.position,
            password: _this.batchForm.loginPassword,
            roldIds: _this.batchForm.authorityRole,
            openId: _this.batchForm.openid
          };
          if (_this.batchForm.typeNmber) {
            params.departmentId = Array.isArray(_this.batchForm.department)
              ? _this.batchForm.department[
                  _this.batchForm.department.length - 1
                ]
              : _this.batchForm.department;
          } else {
            params.departmentId =
              _this.batchForm.department[_this.batchForm.department.length - 1];
          }
          console.log(params);
          if(index==2){
             _this.isApply = true;
          }else {
            _this.isApply2 = true;
          }
          
          if (_this.batchForm.Id && _this.batchForm.typeNmber) {
            params.id = _this.batchForm.Id;
          }
          try {
            let response;
            if (_this.batchForm.typeNmber) {
              response = await editManagement(params);
            } else {
              response = await newManagement(params);
            }
            if (response.statusCode == 200) {
              _this.getData();
              if (_this.batchForm.typeNmber||index==2) {
                _this.batchDialog = false;
              } else if(_this.batchForm.typeNmber==0&&index==3) {
                _this.$refs[form].resetFields();
              }
              _this.$message({
                type: "success",
                message:
                  _this.batchForm.typeNmber == 1 ? "编辑成功" : "添加成功",
              });
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
            if(index==2){
             _this.isApply = false;
          }else {
            _this.isApply2 = false;
          }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 取消
    cancel(reform) {
      this.$refs[reform].resetFields();
      this.batchDialog = false;
    },
    closeCheck(reform) {
      this.$refs[reform].resetFields();
    },
    // 重置
    reset(reform) {
      this.$refs[reform].resetFields();
      this.number = 0;
      this.number2 = 0;
      this.number3 = 0;
      this.form.department = "";
      this.getData();
    },
    // 下拉菜单
    handleCommand(command) {
      if (command.flag == "delete") {
        this.deleteItem(command.command);
      }
    },
    beforeHandleCommand(flag, command) {
      return {
        flag: flag,
        command: command,
      };
    },
    // 删除成员
    deleteItem(row) {
      let _this = this;
      let params = {
        id: row.id,
      };
      this.$confirm("确认删除成员？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let response = await managementDelete(params);
          if (response.statusCode == 200) {
            _this.$message({
              type: "success",
              message: "删除成功",
            });
            _this.getData();
          } else {
            _this.$message({
              type: "error",
              message: response.errors,
            });
          }
          console.log(response);
        })
        .catch(() => {});
    },
    // 编辑
    handleuserEdit(row) {
      console.log(row);
      let _this = this;
      _this.batchDialog = true;
      _this.batchForm.typeNmber = 1;
      _this.batchForm.userName = row.userName;
      _this.batchForm.phone = row.mobile;
      _this.batchForm.department = row.departmentId;
      _this.batchForm.position = row.position;
      _this.batchForm.sex = row.sex;
      _this.batchForm.Id = row.id;
      _this.batchForm.openid = row.openId;
      _this.batchForm.authorityRole = row.roleId.split(",");
    },
    // 禁用
    async handleuserForbidden(row, index) {
      let _this = this;
      console.log(row);
      if (row.status == 1) {
        _this
          .$confirm(`确认禁用${row.userName}吗？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(async () => {
            try {
              let params = {
                id: row.id,
                status: index,
              };
              let response = await managementDisableManagement(params);
              if (response.statusCode == 200) {
                _this.$message({
                  type: "success",
                  message: "操作成功",
                });
                _this.getData();
              } else {
                _this.$message({
                  type: "error",
                  message: response.errors,
                });
              }
            } catch (error) {
              console.log(error);
            }
          })
          .catch(() => {});
      } else {
        try {
          let params = {
            id: row.id,
            status: index,
          };
          let response = await managementDisableManagement(params);
          if (response.statusCode == 200) {
            _this.$message({
              type: "success",
              message: "操作成功",
            });
            _this.getData();
          } else {
            _this.$message({
              type: "error",
              message: response.errors,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    handleSizeChange(val) {
      this.perPage = val;
      this.page = 1;
      this.getData(this.page, this.perPage);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData(this.page, this.perPage);
    },
  },
};
</script>

<style scoped>
.active-num {
  color: #1890ff;
  font-weight: bold;
}
.active-departmentname:hover {
  color: #1890ff;
}
.active {
  display: none;
}
.list2 {
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.list3 {
  padding-left: 35px;
  padding-bottom: 15px;
}
.content {
  padding: 15px 15px 5px;
}
.main-content {
  padding: 0 20px;
}
.bd-bar {
  padding: 20px 20px 0;
  background-color: #fff;
}
.company {
  padding: 15px 15px 5px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #1890ff;
}
.add-member {
  display: inline-block;
  float: right;
}
.btn-group {
  padding: 12px 30px;
  -webkit-box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  border-radius: 0 0 4px 4px;
}
</style>