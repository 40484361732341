<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>

<script>
// localStorage.setItem('access_token','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiLlt6XliqHlm63mnLrmnoTnq68iLCJleHAiOjE2NzA1NTE1NzcsInN1YiI6IlBDX09yZyIsImF1ZCI6IlVTRVIiLCJpYXQiOiIyMDIyLzExLzkgMTA6MDY6MTciLCJkYXRhIjp7InVzZXJObyI6IjR3bWp0d2FqIiwicm9sZSI6IkFCIn19.NT1bYt_3KSHmaPK8crpPWhf8K55lYfTddk42BdCkllQ')
// localStorage.removeItem('Authorization')
 /* eslint-disable */
export default {
  name: "App",
  data() {
    return {
      //设置超时时间： 30分种
      // 60 * 60 * 1000
      timeOut: 60*60*2*1000,
      ModalText: "登录已超时,请重新登录!",
      // locale: zhCN,
      emptyImage: "",
      count: 0,
    };
  },
  methods: {
    checkTimeout() {
      //更新当前时间
      let currentTime = new Date().getTime();
      let lastTime = Number(localStorage.getItem("lastTime"));
      //判断是否超时

      this.count++;
        console.log(this.count)
      if (this.count == 1) {
             console.log(currentTime,lastTime,this.timeOut)
             this.count = 0;
        if (currentTime - lastTime > this.timeOut) {
          // 调用自己的注销接口
          // localStorage.removeItem("Authorization");
          let that = this;
          // this.$confirm({
          //   title: "提示",
          //   content: this.ModalText,
          //   okText: "重新登录",
          //   cancelText: "取消",
          //   icon: "info-circle",
          //   onOk() {
          //     return new Promise((resolve, reject) => {
          //       // console.log(this);
          //       setTimeout(() => {
          //         localStorage.Authorization = "";
          //         that.$router.push({ path: "/" });
          //         window.location.reload();
          //       }, 1000);
          //       setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          //     }).catch(() => console.log("Oops errors!"));
          //   },
          //   onCancel() {},
          // });
          this.$confirm(this.ModalText, "提示", {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              return new Promise((resolve, reject) => {
                // console.log(this);
                setTimeout(() => {
                  localStorage.Authorization = "";
                  that.$router.push({ path: "/" });
                  window.location.reload();
                }, 500);
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
              }).catch(() => console.log("Oops errors!"));
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      } else {
        this.$nextTick(function () {
          clearInterval(this.checkTimeout);
        });
      }
    },
    checkTime() {
      let that = this;
      let timer = setInterval(that.checkTimeout, 30000);
      if (that.count > 1) {
        return clearInterval(timer);
      }
    },
  },
  mounted() {
    // 每30秒 调用检查时间的方法
    this.$nextTick(function () {
      this.checkTime();
    }); // 页面监听 按下鼠标更新操作时间
    window.onload = function () {
      window.document.onmousedown = function () {
        localStorage.setItem("lastTime", new Date().getTime());
      };
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/css/common.scss";
@import "./assets/css/reset.css";
#app {
  width: 100vw;
  height: 100vh;
  font-size: 1rem;
  color: $--color-primary;
  background: $--border-style;
}
</style>
<style lang="scss">
.el-table__empty-text {
  display: block;
  width: 500px;
  height: 160px;
  text-align: center;
  line-height: 260px !important;
  background: url("./assets/image/dataemply.png") no-repeat;
  background-position: center;
  color: #909399;
  box-sizing: border-box;
}
.table_page {
  width: 100%;
  .page {
    text-align: right;
    padding: 0 10px 0 20px;
    margin-top: 20px;
  }
}
.bd-bar {
  width: 100%;
  height: auto;
  background: #fff;
  border: 1px solid #cfd5de;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border-radius: 4px !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.page_box {
  position: relative;
  padding: 45px 20px;
  margin: 0;
  background: #f2f2f2;
}
</style>
