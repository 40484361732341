<template>
  <div class="page_box">
    <page-title-bar :title="title"></page-title-bar>
    <div class="bd-bar">
      <div class="menu-add">
        <el-button type="primary" @click.stop="newMenu" icon="el-icon-plus"
          >新增角色</el-button
        >
      </div>
      <div class="table_page" v-loading="loading">
        <el-table
          :data="tableData"
          style="width: 100%"
          v-if="tableData.length > 0"
        >
          <ex-table-column
            prop="roleName"
            label="角色名称"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          />
          <ex-table-column
            label="角色描述"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.describe }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            label="数据权限范围"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            prop="permission"
          >
            <template slot-scope="scope">
              <div>{{ extendOf(scope.row.scope) }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            label="成员数量"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            prop="userNum"
          >
          </ex-table-column>
          <ex-table-column
            label="最后更新人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            prop="updateName"
          >
          </ex-table-column>
          <ex-table-column
            label="最后更新时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            prop="updateTime"
          >
          </ex-table-column>
          <ex-table-column
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            fixed="right"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleuserlimits(scope.row)"
              >
                权限设置
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="handleuserEdit(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="handleuserDelete(scope.row)"
              >
                删除
              </el-button>
            </template>
          </ex-table-column>
        </el-table>
        <el-table :data="tableData" style="width: 100%" row-key="id" v-else>
          <el-table-column prop="menu_name" label="菜单名称" min-width="200" />
          <el-table-column label="类型" min-width="300">
            <template slot-scope="scope">
              <el-tag type="success">
                {{
                  scope.row.type == 1
                    ? "页面"
                    : scope.row.type == 2
                    ? "按钮"
                    : "接口"
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="300">
            <template slot-scope="scope">
              <div style="color: #e64547" v-if="scope.row.status == 1">
                开启
              </div>
              <div style="color: #23345f" v-else>关闭</div>
            </template>
          </el-table-column>
          <el-table-column label="所属终端" min-width="300">
            <template slot-scope="scope">
              <div style="color: #e64547" v-if="scope.row.status == 1">
                开启
              </div>
              <div style="color: #23345f" v-else>关闭</div>
            </template>
          </el-table-column>
          <el-table-column label="菜单是否可见" min-width="300">
            <template slot-scope="scope">
              <div style="color: #e64547" v-if="scope.row.status == 1">
                开启
              </div>
              <div style="color: #23345f" v-else>关闭</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="200">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleuserEdit(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click="handleuserDelete(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 新增角色 -->
    <el-dialog
      class="batch-dialog n3"
      :title="batchForm.typeNumber ? '编辑角色' : '新增角色'"
      :visible.sync="batchDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('batchForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="batchForm"
          :inline="true"
          :model="batchForm"
          :rules="batchRules"
          class="batchForm3"
        >
          <el-form-item label="角色名称" prop="menuname" label-width="130px">
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.menuname"
              placeholder="请输入内容"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="角色描述" prop="description" label-width="130px">
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.description"
              placeholder="请输入内容"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pageTitleBar from "@/components/page-title-bar.vue";
import { roleList, newRole, roleDelete, editRole } from "@/api/report";
let options = [];
for (var i = 0; i < 10; i++) {
  let obj = {};
  options.push(obj);
}
export default {
  components: { pageTitleBar },
  data() {
    return {
      countindex: 0,
      roleid: [],
      loading: false,
      title: "角色管理",
      tableData: [],
      batchDialog: false,
      isApply: false,
      batchForm: {
        menuType: "",
        newPwd: [],
        menuname: "",
        description: "",
        terminalType: "",
        menubarType: "",
        Id: "",
        typeNumber: 0,
      },
      batchRules: {
        menuname: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
      menuType_list: [
        { value: 3, label: "目录" },
        { value: 0, label: "菜单" },
        { value: 1, label: "功能" },
      ],
      terminal_list: [
        { value: 2, label: "小程序+PC" },
        { value: 1, label: "小程序" },
        { value: 3, label: "PC" },
      ],
      menubar_list: [
        { value: 1, label: "是" },
        { value: 2, label: "否" },
      ],
    };
  },
  mounted() {
    let data = [
      {
        children: [
          {
            id: 10619,
            menu_name: "用户列表",
            parent_id: 10001,
            path: "member",
            status: 1,
            type: 1,
          },
        ],
        id: 10001,
        menu_name: "会员管理",
        parent_id: 0,
        path: "/member",
        status: 1,
        type: 1,
      },
    ];
    let new_data = [];
    for (let item of data) {
      item.value = item.id;
      item.label = item.menu_name;
      item.children = item.children;
      if (item.children) {
        for (let it of item.children) {
          it.value = it.id;
          it.label = it.menu_name;
          it.children = "";
        }
      }

      new_data.push(item);
    }
    this.roleid = new_data;
    this.getData();
  },
  activated() {
    this.getData();
  },
  methods: {
    // 菜单管理
    closeCheck(formName) {
      this.$refs[formName].resetFields();
      this.batchForm.menuname = "";
      this.batchForm.description = "";
    },
    cancel(form) {
      this.batchDialog = false;
    },
    async applyBatch(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            roleName: _this.batchForm.menuname,
            describe: _this.batchForm.description,
          };
          if (_this.batchForm.Id && _this.batchForm.typeNumber) {
            params.id = _this.batchForm.Id;
          }
          let response;
          try {
            if (_this.batchForm.typeNumber == 1) {
              response = await editRole(params);
            } else {
              response = await newRole(params);
            }
            if (response.statusCode == 200) {
              _this.getData();
              _this.batchDialog = false;
              _this.$message({
                type: "success",
                message: "保存成功",
              });
            } else {
              _this.$message({
                type: "error",
                message: response.errors.source[0],
              });
            }
          } catch (error) {}
        }
      });
    },
    // 过滤角色
    extendOf(info) {
      let text;
      switch (info) {
        case 1:
          text = "个人";
          break;
        case 2:
          text = "所在部门";
          break;
        case 3:
          text = "本部门及下属部门";
          break;
        case 4:
          text = "全公司";
          break;
      }
      return text;
    },
    newMenu() {
      this.batchDialog = true;
      this.batchForm.typeNumber = 0;
    },
    async getData() {
      let _this = this;
      let params = {
        pageIndex: 1,
        pageSize: 100000,
      };
      _this.loading = true;
      // try {
      //   let response = await roleList(params);
      //   console.log(response.data);
      _this.$ajax({
        url: _this.globalData.orgHost + "role/list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data, cumulativeData, totalCount } = res.data;
            _this.tableData = data;
            console.log(data);
            _this.count = totalCount;
            let m;
            setTimeout(() => {
              _this.loading = false;
            }, 100);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
          _this.selectionItem = [];
        },
      });
      //   if (response.statusCode == 200) {
      //     _this.tableData = response.data.data;
      //     setTimeout(() => {
      //       _this.loading = false;
      //     }, 500);
      //   } else {
      //     _this.$message({
      //       type: "error",
      //       message: response.errors,
      //     });
      //   }
      // } catch (error) {}
    },
    handleuserDelete(row) {
      let _this = this;
      console.log(row.userNum);
      if (row.userNum != 0) {
        _this.$message({
          type: "success",
          message: "不可删除该角色，已存在用户使用,请先修改关系再删除",
        });
        return;
      }
      let params = {
        id: row.id,
      };
      this.$confirm("确认删除角色？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let response = await roleDelete(params);
          if (response.statusCode == 200) {
            _this.$message({
              type: "success",
              message: "删除成功",
            });
            _this.getData();
          } else {
            _this.$message({
              type: "error",
              message: response.errors,
            });
          }
          console.log(response);
        })
        .catch(() => {});
    },
    handleuserEdit(row) {
      console.log(row);
      let _this = this;
      _this.batchDialog = true;
      _this.batchForm.menuname = row.roleName;
      _this.batchForm.description = row.describe;
      _this.batchForm.Id = row.id;
      _this.batchForm.typeNumber = 1;
    },
    // 权限设置
    handleuserlimits(row) {
      let _this = this;
      console.log(row);
      _this.$router.push({
        path: "/permissionSetting",
        query: { id: row.id, roleName: row.roleName, scope: row.scope },
      });
    },
  },
};
</script>

<style scoped>
.batchForm3 {
  margin: 20px 0;
}
.menu-add {
  padding: 20px 0px;
}
.bd-bar {
  padding: 20px;
}
.btn-group {
  padding: 12px 30px;
  -webkit-box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  border-radius: 0 0 4px 4px;
}
</style>