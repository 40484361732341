<template>
  <div class="page_box">
    <page-title-bar :title="title">
      <div>
        <div
          style="display: inline-block; padding-right: 12px; font-size: 14px"
          v-if="assetsClue.length"
        >
          自动分配：{{
            assetsClue[0].autoAllocation == 1 ? "已开启" : "未开启"
          }}
        </div>
        <el-button size="small" type="primary" @click="allocationSetting"
          >分配设置</el-button
        >
        <el-button
          @click="leadInCue"
          type="primary"
          size="small"
          style="margin-right: 10px"
          >导入线索</el-button
        >
      </div>
    </page-title-bar>
    <div class="bd-bar">
      <el-form
        ref="SearchForm"
        :inline="true"
        :model="form"
        label-width="80px"
        class="search-bar_n"
      >
        <!-- <el-tabs v-model="tabStatus" @tab-click="handleClick">
          <el-tab-pane
            :label="`${item.label}`"
            :name="item.value"
            v-for="(item, index) in statusType"
            :key="index"
          >
          </el-tab-pane>
        </el-tabs> -->
        <div class="bd-bar2">
          <span class="bd-name">搜索：</span>
          <el-form-item label="" prop="industryInvolved">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.industryInvolved"
              placeholder="所属行业"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="annualSalesVolume">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.annualSalesVolume"
              placeholder="年营业额"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="companyName">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.companyName"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="gatherer">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.gatherer"
              placeholder="采集人"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="createName">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.createName"
              placeholder="创建人"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="100px" label="创建时间段" prop="auditDate">
            <el-date-picker
              v-model="form.auditDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
              <!--  :default-time="['00:00:00', '23:59:59']" -->
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getData(1)"
              >查询</el-button
            >
            <el-button size="small" @click="reset('SearchForm')"
              >重置</el-button
            >
            <el-button size="small" @click="exportpost(1)">导出</el-button>
          </el-form-item>
        </div>
      </el-form>
      <div class="dotted-line"></div>
      <div
        class="left batch-option"
        style="min-width: 315px; padding: 12px 0 12px"
      >
        <div>
          <el-button type="text" style="padding-bottom: 0; color: #333"
            >已选 {{ selectionItem.length }} 条数据</el-button
          >
          <el-button size="small" type="primary" @click.stop="batchDistribution"
            >批量分配</el-button
          >
        </div>
      </div>

      <div class="table_page" v-loading="tableloading">
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-loading="tabLoading"
          v-if="advanceDataList.length > 0"
          @selection-change="handleSelectionChange"
        >
          <ex-table-column
            type="selection"
            key="1"
            align="center"
            :autoFit="true"
            :fitHeader="true"
          >
          </ex-table-column>
          <ex-table-column
            key="2"
            prop="companyName"
            label="公司名称"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="3"
            prop="contactName"
            label="联系人姓名"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="4"
            prop="contactPhone"
            label="手机号"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
          </ex-table-column>
          <ex-table-column
            key="5"
            prop="industry"
            label="所属行业"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top" v-if="scope.row.industry.length > 13">
                <div slot="content" style="width: 600px">
                  {{ scope.row.industry }}
                </div>
                <div class="details-text">{{ scope.row.industry }}</div>
              </el-tooltip>
              <div v-else class="details-text">
                {{ scope.row.industry }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="6"
            prop="annualRevenue"
            label="年营业额"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="7"
            prop="collectionSource"
            label="采集来源"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="8"
            prop="gatherer"
            label="采集人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="9"
            prop="createName"
            label="创建人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="10"
            prop="createTime"
            label="创建时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
        </el-table>
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-loading="tabLoading"
          v-else
        >
          <ex-table-column
            type="selection"
            key="15"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
          </ex-table-column>
          <el-table-column
            key="16"
            prop=""
            label="公司名称"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="17"
            prop="title"
            label="联系人姓名"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="type"
            label="手机号"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="reward"
            label="所属行业"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="20"
            prop="annualRevenue"
            label="年营业额"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="collectionSource"
            label="采集来源"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="22"
            prop="gatherer"
            label="采集人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="23"
            prop="createName"
            label="创建人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="24"
            prop="create_id.username"
            label="创建时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="this.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 线索导入 -->
    <el-dialog
      title="导入线索"
      :visible.sync="batchDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck"
    >
      <div class="dialog-cont">
        <div class="dialog-text">
          1、
          <em class="dialog-desc" @click.stop="downloadTemplate"
            >点击下载导入数据模板</em
          >
          将要导入的数据填充到数据导入模板文件中。
        </div>
        <div class="dialog-text">
          <p>注意事项：</p>
          <p>1）模板中的表头不可更改，表头行不可删除；</p>
          <p>2）单次导入的数据不超过10000条。</p>
          <p>3）导入的数据，将自动去重，查重规则：手机号/公司名称</p>
        </div>
        <div class="dialog-upload">
          <div>2、上传数据文件并导入</div>
          <div style="margin-top: 11px; position: relative">
            <el-upload
              class="upload-demo"
              drag
              :headers="header"
              :action="uploadUrl"
              multiple
              :file-list="tempFileList"
              :on-success="handleTempSuccess"
              :on-error="handleError"
              :before-upload="beforeupload"
              element-loading-text="数据转换中，请耐心等待"
              element-loading-spinner="el-icon-loading"
              :class="['upload2-temp', loading ? 'mt-30' : '']"
              accept=".xlsx,.xls"
              v-if="!uploadTypeObj.isUpload"
            >
              <!-- <i class="el-icon-upload"></i>  -->
              <img
                src="../../assets/image/upload-icon.png"
                class="el-icon-upload"
                style="width: 43px; height: 37px"
                alt=""
              />
              <div class="el-upload__text">点击或将文件拖拽到这里上传</div>
            </el-upload>
            <!-- 确定导入最后一步   -->
            <div
              style="width: 100%"
              class="upload-container"
              v-if="uploadTypeObj.isUpload && uploadTypeObj.succeeded"
            >
              <div class="upload-success-content">
                <div class="success-text">
                  共<em>{{ uploadTypeObj.verificationSumCount }}</em
                  >条数据可导入
                </div>
              </div>
              <div class="flex flex-box">
                <!--  class="upload-demo" -->
                <div style="margin-right: 10px">
                  <el-button
                    type="primary"
                    :loading="definiteLoading"
                    @click.stop="definiteImport"
                    >确定导入</el-button
                  >
                </div>
                <el-upload
                  class="upload-demo2"
                  :headers="header"
                  :action="uploadUrl"
                  multiple
                  :file-list="tempFileList"
                  :on-success="handleTempSuccess"
                  :on-error="handleError"
                  :before-upload="beforeupload"
                  element-loading-text="数据转换中，请耐心等待"
                  element-loading-spinner="el-icon-loading"
                  accept=".xlsx,.xls"
                  v-if="uploadTypeObj.isUpload"
                  :class="['upload-temp', loading ? 'mt-30' : '']"
                >
                  <el-button class="upload-min-btn">重新上传</el-button>
                </el-upload>
              </div>
            </div>

            <!-- 一些成功一些失败  uploadTypeObj.errorCount !=
                uploadTypeObj.verificationSumCount &&
                uploadTypeObj.sucessCount !=
                uploadTypeObj.verificationSumCount &&
                uploadTypeObj.errorCount -->
            <div
              style="width: 100%"
              class="upload-container"
              v-if="
                (uploadTypeObj.isUpload &&
                  uploadTypeObj.verificationSumCount ==
                    uploadTypeObj.errorCount) ||
                (uploadTypeObj.isUpload &&
                  uploadTypeObj.errorCount !=
                    uploadTypeObj.verificationSumCount &&
                  uploadTypeObj.sucessCount !=
                    uploadTypeObj.verificationSumCount &&
                  uploadTypeObj.errorCount)
              "
            >
              <div
                class="upload-content flex flex-box"
                v-if="uploadTypeObj.sucessCount"
              >
                <div style="padding-right: 10px">
                  <img src="../../assets/image/success-icon.png" />
                </div>
                <div class="success-text">
                  导入成功，共<em style="color: #2bbb9a">{{
                    uploadTypeObj.sucessCount
                  }}</em
                  >条
                </div>
              </div>
              <div
                class="flex upload-content-error flex-box"
                v-if="uploadTypeObj.errorCount"
              >
                <div class="flex">
                  <div style="padding-right: 10px">
                    <img src="../../assets/image/error-icon.png" />
                  </div>
                  <div>
                    <div class="verify-text">
                      校验不通过和导入失败，共<em style="color: #fa3333">{{
                        uploadTypeObj.errorCount
                      }}</em
                      >条
                    </div>
                    <div class="verify-desc">
                      您可以下载错误表格查看原因修改后再次上传
                    </div>
                    <div class="flex flex-box upload-error">
                      <img src="../../assets/image/download-error.png" alt="" />
                      <div
                        @click.stop="DownloadErrorResult"
                        style="padding-left: 3px; cursor: pointer"
                      >
                        下载错误结果
                      </div>
                    </div>
                  </div>
                </div>
                <!--  class="upload-demo" -->
                <el-upload
                  class="upload-demo2"
                  :headers="header"
                  :action="uploadUrl"
                  multiple
                  :file-list="tempFileList"
                  :on-success="handleTempSuccess"
                  :on-error="handleError"
                  :before-upload="beforeupload"
                  element-loading-text="数据转换中，请耐心等待"
                  element-loading-spinner="el-icon-loading"
                  accept=".xlsx,.xls"
                  v-if="uploadTypeObj.isUpload"
                  :class="['upload-temp', loading ? 'mt-30' : '']"
                >
                  <el-button class="upload-min-btn">重新上传</el-button>
                </el-upload>
              </div>
            </div>
            <!-- 导入成功 -->
            <div
              v-if="
                uploadTypeObj.isUpload &&
                uploadTypeObj.sucessCount == uploadTypeObj.verificationSumCount
              "
            >
              <div class="upload-content flex flex-box">
                <div style="padding-right: 10px">
                  <img src="../../assets/image/success-icon.png" />
                </div>
                <div class="success-text">
                  导入成功，共<em style="color: #2bbb9a">{{
                    uploadTypeObj.sucessCount
                  }}</em
                  >条
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 批量分配线索 -->
    <el-dialog
      title="批量分配"
      :visible.sync="batchDistributionDialog"
      :close-on-click-modal="false"
      width="424px"
    >
      <div class="dialog-cont">
        <div class="dialog-title">
          已选择<em style="color: #1890ff">{{ selectionItem.length }}</em
          >条线索，请选择负责人
        </div>
        <el-select
          v-model="batchForm.principalId"
          filterable
          placeholder="请选择或输入搜索"
          style="width: 434px"
        >
          <el-option
            v-for="item in principalList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 线索自动分配设置 -->
    <el-dialog
      title="线索自动分配设置"
      :visible.sync="distributionDialog"
      :close-on-click-modal="false"
      width="624px"
    >
      <div class="dialog-cont2 defineSwitch">
        <el-form
          :model="batchForm2"
          ref="batchForm"
          :inline="true"
          :rules="batchRules"
          class="batchForm3"
          style="padding-left: 44px"
        >
          <el-form-item
            label="功能开关"
            style="display: block"
            prop="functionSwitch"
          >
            <el-switch
              v-model="batchForm2.functionSwitch"
              :width="55"
              class="amend-switch"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            label="分配部门"
            prop=""
            v-for="(item, index) in batchForm2.list"
            :key="index"
          >
            <div class="flex flex-box">
              <div>
                <el-cascader
                  :props="{
                    checkStrictly: true,
                    emitPath: false,
                    value: 'id',
                    label: 'departmentName',
                  }"
                  v-model="item.departmentId"
                  :options="roleid"
                  :key="countindex"
                  @change="optionlist"
                  style="width: 180px"
                  size="small"
                  :show-all-levels="false"
                  placeholder="请选择部门"
                ></el-cascader>
                <el-input
                  type="tel"
                  size="small"
                  v-model="item.allocationNum"
                  placeholder="请输入分配数量"
                  style="margin-left: 8px; width: 180px"
                ></el-input>
              </div>
              <div class="add-btn-icon" @click.stop="augment" v-if="index == 0">
                <img src="../../assets/image/evet.png" alt="" />
              </div>
              <div class="add-btn-icon" @click.stop="reduce(index)" v-else>
                <img src="../../assets/image/add.png" alt="" />
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="distributionDialog = false">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirm('demandForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import pageTitleBar from "@/components/page-title-bar.vue";
import addTask from "@/components/AddTask.vue";
import {
  importAssetsClueList,
  exportAssetsClueVerification,
  exportAssetsClue,
  assetsClueDepManagerList,
  assetsClueBatchllocation,
  assetsClueExportCluelist,
  departmentList,
  assetsClueSetting,
  assetsClueSettingList,
} from "@/api/report";
import AddTask from "@/components/AddTask.vue";
export default {
  inject: ["reload"],
  components: { pageTitleBar, AddTask },
  data() {
    return {
      title: "线索导入/分配",
      tabStatus: "1",
      page: 1,
      perPage: 10,
      tableloading: false,
      tabLoading: false,
      batchDialog: false,
      isApply: false,
      principalList: [],
      batchDistributionDialog: false,
      distributionDialog: false,
      definiteLoading: false,
      count: 0,
      batchForm: {
        principalId: "",
      },
      batchForm2: {
        functionSwitch: false,
        list: [
          {
            departmentId: "",
            allocationNum: null,
          },
        ],
      },
      batchRules: {},
      roleid: [],
      countindex: 0,
      form: {
        industryInvolved: "",
        annualSalesVolume: "",
        companyName: "",
        gatherer: "",
        createName: "",
        type: "1",
        auditDate: [],
      },
      advanceDataList: [],
      selectionItem: [],
      tempFileList: [],
      uploadTypeObj: {
        sucessCount: "",
        errorCount: "",
        groupId: "",
        verificationSumCount: "",
        isUpload: false,
      },
      loading: false,
      assetsClue: [],
    };
  },
  mounted() {
    this.getData();
    this.getPrincipal();
    this.getDeparmentList();
    this.assetsClueList();
  },
  computed: {
    header: function () {
      let that = this;
      return {
        Authorization: "Bearer " + localStorage.getItem("Authorization"),
      };
    },
    uploadUrl: function () {
      return (
        this.globalData.orgHost + "assets-clue/import-assets-clue-verification"
      );
    },
  },
  methods: {
    optionlist() {},

    // 获取部门
    async getDeparmentList() {
      let _this = this;
      let params = {
        pageIndex: 1,
        pageSize: 100000,
      };
      _this.loading = true;
      let response = await departmentList(params);
      if (response.statusCode == 200) {
        // _this.newDatalist(response.data);
        let new_data = [];
        for (let item of response.data) {
          item.value = item.id;
          item.label = item.departmentName;
          item.children = item.children;
          if (item.children) {
            for (let it of item.children) {
              it.value = it.id;
              it.label = it.departmentName;
              it.children = it.children;
              if (it.children) {
                for (let qu of it.children) {
                  qu.value = qu.id;
                  qu.label = qu.departmentName;
                  qu.children = "";
                }
              }
            }
          }
          new_data.push(item);
        }
        _this.roleid = new_data;
      } else {
        _this.$message({
          type: "error",
          message: response.errors,
        });
      }
      setTimeout(() => {
        _this.loading = false;
      }, 1000);
    },
    // 自动分配设置
    allocationSetting() {
      let _this = this;
      _this.distributionDialog = true;
    },
    downloadTemplate() {
      let _this = this;
      _this.$ajax({
        url: _this.globalData.qiniu + "Templatexecl/线索批量导入模板.xls",
        method: "get",
        header: {
          dowload: true,
        },
        data: {},
        success: function (res) {
          _this.$message({
            type: "success",
            message: "已下载",
          });
        },
      });
    },
    // 模版文件上传成功
    handleTempSuccess(file, fileList) {
      let _this = this;
      console.log(file);
      if (file.statusCode == 200) {
        _this.loading = false;
        _this.tempFileList = [fileList];
        _this.$set(_this.uploadTypeObj, "groupId", file.data.groupId);
        _this.$set(_this.uploadTypeObj, "sucessCount", file.data.sucessCount);
        _this.$set(_this.uploadTypeObj, "errorCount", file.data.errorCount);
        _this.$set(
          _this.uploadTypeObj,
          "verificationSumCount",
          file.data.verificationSumCount
        );
        _this.$set(_this.uploadTypeObj, "isUpload", true);
        _this.$set(_this.uploadTypeObj, "succeeded", true);

        console.log(_this.uploadTypeObj);
      } else {
        _this.loading = false;
        _this.tempFileList = [fileList];
        _this.$message({
          type: "error",
          message: file.errors,
        });
      }
      _this.loading = false;
    },
    // 确定导入
    async definiteImport() {
      let _this = this;
      _this.definiteLoading = true;
      let params = {
        groupId: _this.uploadTypeObj.groupId,
      };
      try {
        let response = await exportAssetsClue(params);
        console.log(response);
        if (response.statusCode == 200) {
          _this.$set(
            _this.uploadTypeObj,
            "sucessCount",
            response.data.sucessCount
          );
          _this.$set(
            _this.uploadTypeObj,
            "errorCount",
            response.data.errorCount
          );
          _this.$set(
            _this.uploadTypeObj,
            "verificationSumCount",
            response.data.verificationSumCount
          );
          _this.$set(_this.uploadTypeObj, "isUpload", true);
          _this.$set(_this.uploadTypeObj, "succeeded", false);
          _this.definiteLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 导出列表
    async exportpost(page, perPage) {
      let _this = this;
      let access_token = localStorage.getItem("Authorization");
      if (page == 1) {
        _this.page = 1;
      }
      try {
        let index = page ? page : _this.page;
        let size = perPage ? perPage : _this.perPage;
        let params = {
          pageIndex: index,
          pageSize: size,
          Industry: _this.form.industryInvolved,
          Income: _this.form.annualSalesVolume,
          CompanyName: _this.form.companyName,
          Gatherer: _this.form.gatherer,
          CreateName: _this.form.createName,
          CreateTimeStart: _this.form.auditDate[0] || "",
          CreateTimeEnd: _this.form.auditDate[1] || "",
        };
        // let response = await assetsClueExportCluelist(params);
        // console.log(_this.form.auditDate.length);

        window.location.href = `${
          this.globalData.orgHost
        }assets-clue/export-assets-clue-list?pageIndex=${index}&pageSize=${size}&Industry=${
          _this.form.industryInvolved
        }&Income=${_this.form.annualSalesVolume}&CompanyName=${
          _this.form.companyName
        }&Gatherer=${_this.form.gatherer}&CreateName=${
          _this.form.createName
        }&CreateTimeStart=${
          _this.form.auditDate[0] ? _this.form.auditDate[0] : ""
        }&CreateTimeEnd=${
          _this.form.auditDate[1] ? _this.form.auditDate[1] : ""
        }&Authorization=${access_token}`;
      } catch (error) {
        console.log(error);
      }
    },
    // 批量分配
    batchDistribution() {
      const { selectionItem } = this;
      if (selectionItem.length > 0) {
        this.batchDistributionDialog = true;
      } else {
        this.$message({
          type: "error",
          message: "请勾选数据!",
        });
      }
    },
    // 下载错误结果
    async DownloadErrorResult(type) {
      let _this = this;
      let params = {
        GroupId: this.uploadTypeObj.groupId,
        type: 1,
      };
      // try {
        // let res = await exportAssetsClueVerification(params);
        _this.$ajax({
        url: this.globalData.orgHost + "assets-clue/export-assets-clue-verification",
        method: "get",
        header: {
          dowload: true,
        },
        data: params,
        success: function (res) {
          _this.$message({
            type: "success",
            message: "已下载",
          });
        },
      });
      //   window.location.href = `${this.globalData.orgHost}assets-clue/export-assets-clue-verification?GroupId=${this.uploadTypeObj.groupId}&type=1`;
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 线索自动分配
    augment() {
      let obj = {
        departmentId: "",
        allocationNum: null,
      };
      this.batchForm2.list.push(obj);
    },
    // 线索减少
    reduce(index) {
      this.batchForm2.list.splice(index, 1);
    },
    // 自动分配设置确认
    async confirm() {
      let _this = this;
      try {
        let params = {
          autoAllocation: _this.batchForm2.functionSwitch ? 1 : 2,
          assetsAllocationsList: _this.batchForm2.list,
        };
        let response = await assetsClueSetting(params);
        if (response.statusCode == 200) {
          _this.$message({
            type: "success",
            message: "设置成功",
          });
          _this.reload();
          _this.getData(1);
          _this.distributionDialog = false;
        } else {
          _this.$message({
            type: "error",
            message: response.errors,
          });
        }
        console.log(params);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取分配自动设置
    async assetsClueList() {
      let _this = this;
      try {
        let params = {};
        let response = await assetsClueSettingList(params);
        if (response.statusCode == 200) {
          _this.assetsClue = response.data;
          _this.batchForm2.functionSwitch =
            response.data[0].autoAllocation == 1 ? true : false;
          _this.batchForm2.list[0].departmentId = response.data[0].departmentId;
          _this.batchForm2.list[0].allocationNum =
            response.data[0].allocationNum;
          if (_this.assetsClue.length >= 2) {
            console.log(response.data[response.data.length - 1]);
            response.data.splice(0, 1);
            _this.batchForm2.list.push(...response.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleError() {},
    beforeupload() {
      this.loading = true;
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1500);
    },
    // 重置
    reset(reform) {
      this.$refs[reform].resetFields();
      this.getData();
    },
    // 批量分配取消
    cancel(form) {
      this.batchDistributionDialog = false;
    },
    leadInCue() {
      this.batchDialog = true;
    },
    MenuMore(flag) {
      console.log(flag);
    },
    handleClick() {},
    // 获取线索列表数据
    async getData(page, perPage) {
      let _this = this;
      if (page == 1) {
        _this.page = 1;
      }
      // try {
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      let params = {
        pageIndex: index,
        pageSize: size,
        Industry: _this.form.industryInvolved,
        Income: _this.form.annualSalesVolume,
        CompanyName: _this.form.companyName,
        Gatherer: _this.form.gatherer,
        CreateName: _this.form.createName,
        CreateTimeStart: _this.form.auditDate[0] || "",
        CreateTimeEnd: _this.form.auditDate[1] || "",
      };
      _this.tableloading = true;
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/import-assets-clue-list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data, totalCount } = res.data;
            _this.advanceDataList = data;
            _this.count = totalCount;
            console.log(data);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tableloading = false;
        },
      });

      //   let response = await importAssetsClueList(params);
      //   if (response.statusCode == 200) {
      //     let { data, totalCount } = response.data;
      //     _this.advanceDataList = data;
      //     _this.count = totalCount;
      //     _this.tableloading = false;
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 获取负责人列表 assetsClueDepManagerList
    async getPrincipal() {
      let _this = this;
      let params = {};
      try {
        let response = await assetsClueDepManagerList(params);
        console.log(response);
        if (response.statusCode == 200) {
          let data = response.data;
          data.map((item, index) => {
            _this.principalList.push({
              label: item.userName,
              value: item.userId,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeCheck() {
      this.getData();
      this.reload();
      this.$set(_this.uploadTypeObj, "isUpload", false);
    },
    async applyBatch() {
      let _this = this;

      console.log(this.selectionItem, _this.batchForm.principalId);
      if (!_this.batchForm.principalId) {
        _this.$message({
          type: "error",
          message: "请选择负责人",
        });
        return;
      }
      let list = this.selectionItem.map((item, index) => {
        return item.id;
      });
      let params = {
        chargeId: _this.batchForm.principalId,
        assetClueIds: list,
      };
      try {
        _this.isApply = true;
        let response = await assetsClueBatchllocation(params);
        _this.isApply = false;
        if (response.statusCode == 200) {
          _this.$message({
            type: "success",
            message: "操作成功",
          });
          _this.batchDistributionDialog = false;
          _this.getData();
        } else {
          _this.$message({
            type: "success",
            message: response.errors,
          });
        }
      } catch (error) {
        console.log(error);
      }
      console.log(list);
    },
    // 查询数据
    inquire() {},
    handleSizeChange(val) {
      this.perPage = val;
      this.page = 1;
      this.advanceDataList = [];
      this.getData(this.page, this.perPage);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.advanceDataList = [];
      this.getData(this.page, this.perPage);
    },
    // 多选项目
    handleSelectionChange(val) {
      this.selectionItem = val;
      console.log(val);
    },
  },
};
</script>

<style scoped>
.add-btn-icon img {
  padding-left: 15px;
  vertical-align: middle;
}
.dialog-title {
  padding: 2px 0 20px;
}
.upload-success-content {
  padding-bottom: 8px;
}
.success-text {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
}
.flex-box {
  align-items: center;
}
.upload-container {
  padding: 44px 24px 15px;
  width: 100%;
  box-sizing: border-box;
  background: rgba(24, 144, 255, 0.1);
  border-radius: 8px;
  opacity: 1;
  border: 1px dashed #1890ff;
}
.dialog-upload {
  padding: 40px 0 99px;
}
.upload-content {
  padding: 8px 12px;
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #2bbb9a;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: 500;
  color: #333333;
}
.upload-content-error {
  margin-top: 13px;
  justify-content: space-between;
  padding: 10px 12px;
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #fa3333;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: 500;
  color: #333333;
}
.verify-text {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
}
.verify-desc {
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
.upload-content img,
.upload-error img {
  vertical-align: middle;
}
.upload-error {
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #1890ff;
  line-height: 30px;
}
.dialog-desc {
  cursor: pointer;
  color: #1890ff;
  text-decoration: underline;
}
.dialog-text {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.dialog-cont {
  padding: 21px 26px 40px;
}
.dialog-cont2 {
  height: 200px;
  overflow-y: auto;
  padding: 21px 26px 40px;
}
.bd-bar {
  padding: 0 20px;
  background-color: #fff;
}
.table_page .page {
  padding-bottom: 10px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.search-bar_n {
  padding: 20px 0;
  margin: 0;
}
.upload2-temp {
  width: 100%;
}

.upload2-temp .upload-ico {
  display: block;
  margin: 32px auto 8px;
}
</style>
<style>
.defineSwitch .el-form-item {
  margin-bottom: 12px;
}
.bd-bar2 .el-form-item {
  margin-bottom: 0;
}
.page_box .el-table th.el-table__cell {
  background: #f5f7fa;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-table .cell {
  white-space: nowrap !important;
  width: fit-content !important;
}
.dotted-line {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  margin: 0 auto;
  background: url("../../assets/image/dotted-line.png") no-repeat center;
  background-size: 100%;
  /* margin: -40px 20px 22.5px 2px; */
}
.el-form-item {
  margin-bottom: 10px;
}
.upload-demo .el-upload {
  width: 100%;
}
.upload-demo .el-upload-dragger {
  width: 100%;
  background: #e7f4ff;
  border-color: #1890ff !important;
}
.upload2-temp .el-upload-list,
.upload-temp .el-upload-list {
  /* display: none; */
  top: 0px;
  left: 20px;
  position: absolute;
}
.defineSwitch .el-switch__core::before,
.defineSwitch2 .el-switch__core::before {
  content: "关闭";
  position: absolute;
  top: 0;
  right: 5px;
  color: #fff;
}
.defineSwitch .is-checked .el-switch__core::before,
.defineSwitch2 .is-checked .el-switch__core::before {
  content: "开启";
  position: absolute;
  top: 0;
  left: 5px;
  color: #fff;
}
.amend-switch .el-switch__core {
  height: 22px;
}
</style>