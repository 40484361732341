<template>
  <div class="page_box">
    <page-title-bar :title="title"></page-title-bar>
    <div class="bd-bar">
      <el-form
        ref="SearchForm"
        :inline="true"
        :model="form"
        label-width="80px"
        class="search-bar_n"
      >
        <el-tabs v-model="tabStatus" @tab-click="handleClick">
          <el-tab-pane
            :label="`${item.label}`"
            :name="item.value"
            v-for="(item, index) in statusType"
            :key="index"
          >
          </el-tab-pane>
        </el-tabs>
        <div style="padding-bottom: 20px" v-if="tabStatus == 1">
          <el-button
            style="margin-right: 15px"
            size="small"
            :plain="number == item.value ? false : true"
            type="primary"
            v-for="(item, index) in cumulativeList"
            :key="index"
            @click="cumulativeClick(item.value)"
            >{{ item.lable }} {{ item.num }}</el-button
          >
        </div>
        <div class="bd-bar2">
          <span class="bd-name">搜索：</span>
          <el-form-item label="" prop="followType" v-if="tabStatus == 4">
            <el-select
              v-model="form.followType"
              style="width: 150px"
              placeholder="跟进状态"
              size="small"
            >
              <el-option
                v-for="(item, index) in Category"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="clientGrade" v-if="tabStatus == 4">
            <el-select
              v-model="form.clientGrade"
              style="width: 150px"
              placeholder="客户等级"
              size="small"
            >
              <el-option
                v-for="(item, index) in Grade"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="product" v-if="tabStatus == 4">
            <el-select
              v-model="form.product"
              style="width: 150px"
              placeholder="需求产品"
              size="small"
            >
              <el-option
                v-for="(item, index) in Product"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="belonger" v-if="tabStatus == 4">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.belonger"
              placeholder="归属人"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="department" v-if="tabStatus == 1">
            <el-cascader
              :props="{ checkStrictly: true }"
              v-model="form.department"
              :options="roleid2"
              :key="countindex2"
              style="width: 150px"
              size="small"
              :show-all-levels="false"
              placeholder="负责部门"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="" prop="keyword">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.keyword"
              placeholder="公司名称/联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="principal" v-if="tabStatus == 1">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.principal"
              placeholder="负责人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="involved" v-if="tabStatus == 1">
            <el-input
              size="small"
              style="width: 150px"
              v-model="form.involved"
              placeholder="所属行业"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="120px"
            label="转客户时间段"
            prop="clientDate"
            v-if="tabStatus == 4"
          >
            <el-date-picker
              v-model="form.clientDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 220px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label-width="120px"
            label="领取/分配时间段"
            prop="auditDate"
            v-if="tabStatus == 1"
          >
            <el-date-picker
              v-model="form.auditDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 220px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getData(1)"
              >查询</el-button
            >
            <el-button size="small" @click="reset('SearchForm')"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item style="padding-left: 15px" v-if="tabStatus == 1">
            <el-checkbox v-model="checked">仅看我负责的</el-checkbox>
          </el-form-item>
        </div>
      </el-form>
      <!-- <div class="dotted-line"></div>
        <div
            class="left batch-option"
            style="min-width: 315px;padding:12px 0 12px"
          >
            <div>
              <el-button type="text" style="padding-bottom: 0; color: #333"
                >已选 {{selectionItem.length}} 条数据</el-button
              >
              <el-button
                size="small"
                type="primary"
                >批量更改状态</el-button
              >
              <el-button size="small">批量退回线索池</el-button>
              <el-button size="small">批量转给他人</el-button>
            </div>
          </div> -->
      <div class="table_page" v-loading="tabLoading" v-if="tabStatus == 1">
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-if="advanceDataList.length > 0"
          @selection-change="handleSelectionChange"
        >
          <ex-table-column
            key="1"
            prop="companyName"
            label="公司名称"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="client-name link" @click="details(scope.row)">
                {{ scope.row.companyName }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="2"
            prop="contactName"
            label="联系人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.contactName }} {{ scope.row.contactPhone }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="3"
            prop="industry"
            label="所属行业"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="fllowup-content">{{ scope.row.industry }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="4"
            prop="annualRevenue"
            label="年营收"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="5"
            prop="chargeName"
            label="负责人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.departmentName }}-{{ scope.row.chargeName }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="6"
            prop="followupStatus"
            label="跟进状态"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{
                  selectDictlabel(clueFollowupStatus, scope.row.followupStatus)
                }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="7"
            prop=""
            label="最近跟进"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            width="180px"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.followTime }}
                {{ selectDictlabel(followupMethod, scope.row.followupMethod) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="8"
            prop="followupContent"
            label="最近跟进内容"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="fllowup-content">{{ scope.row.followupContent }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="9"
            prop="followupDateStr"
            label="下次跟进日期"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="">{{ scope.row.followupDateStr }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="10"
            prop="remark"
            label="备注"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="fllowup-content">{{ scope.row.remark }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="11"
            prop="assignerDate"
            label="领取/分配时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="12"
            label="操作"
            align="left"
            fixed="right"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div class="flex-box table-fixed f-center">
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="getAssetsClueassetsClue(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="followUp(scope.row)"
                  >写跟进</el-button
                >
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="transferClient(scope.row)"
                  >转客户</el-button
                >
                <el-dropdown
                  style="padding-left: 10px"
                  trigger="click"
                  @command="MenuMore"
                >
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="beforeHandleCommand('redact', scope.row)"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('transferTohim', scope.row)"
                      >转给他人</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="
                        beforeHandleCommand('returnCluePool', scope.row)
                      "
                      >退回线索池</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </ex-table-column>
        </el-table>
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-else
        >
          <el-table-column
            type="selection"
            key="15"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
          </el-table-column>
          <el-table-column
            key="16"
            prop=""
            label="公司姓名"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="17"
            prop="title"
            label="联系人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="type"
            label="所属行业"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="reward"
            label="年营收"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="20"
            label="负责人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="orderNum"
            label="跟进状态"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="22"
            prop="checkNum"
            label="最近跟进"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="23"
            prop="followupContent"
            label="最近跟进内容"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="24"
            prop="create_id.username"
            label="下次跟进日期"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="25"
            prop=""
            label="备注"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="26"
            prop=""
            label="领取/分配时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column key="27" label="操作" align="left" fixed="right">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="this.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count"
          >
          </el-pagination>
        </div>
      </div>
      <div class="table_page" v-loading="tabLoading" v-if="tabStatus == 4">
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-if="advanceDataList.length > 0"
          @selection-change="handleSelectionChange"
        >
          <ex-table-column
            key="2"
            prop="companyName"
            label="公司名称"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="client-name link" @click="details(scope.row)">
                {{ scope.row.companyName }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="3"
            prop="contactName"
            label="联系人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.contactName }} {{ scope.row.contactPhone }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="4"
            prop="ownership"
            label="线索归属"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            v-if="tabStatus == 4"
          >
          </ex-table-column>
          <ex-table-column
            key="5"
            prop="createTime"
            label="转客户时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="6"
            prop=""
            label="需求产品"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            v-if="tabStatus == 4"
          >
            <template slot-scope="scope">
              <div>
                {{ selectCirclation(Product, scope.row.productDemand) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="7"
            prop="followStatus"
            label="跟进状态"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div>
                {{ selectDictlabel(Category, scope.row.followStatus) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="8"
            prop="annualRevenue"
            label="客户等级"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ selectDictlabel(Grade, scope.row.grade) }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="9"
            prop="chargeName"
            label="客户当前负责人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.departmentName }}-{{ scope.row.chargeName }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="10"
            prop="followupStatus"
            label="最近跟进"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.followTime }}
                {{ selectDictlabel(followupMethod, scope.row.followupMethod) }}
              </div>
            </template>
          </ex-table-column>
          <ex-table-column
            key="11"
            prop="followupContent"
            label="跟进内容"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="12"
            label="操作"
            align="left"
            fixed="right"
            :autoFit="true"
            :fitHeader="true"
          >
            <template slot-scope="scope">
              <div class="flex-box table-fixed f-center">
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="getAssetsClueassetsClue(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  v-preventReClick="3000"
                  @click.stop="followUp(scope.row)"
                  >写跟进</el-button
                >
                <el-button
                  type="text"
                  v-preventReClick="1000"
                  @click.stop="clientDetails(scope.row)"
                  v-if="tabStatus == 4"
                  >客户详情</el-button
                >
              </div>
            </template>
          </ex-table-column>
        </el-table>
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-else
        >
          <el-table-column
            type="selection"
            key="15"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
          </el-table-column>
          <el-table-column
            key="16"
            prop=""
            label="公司姓名"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="17"
            prop="title"
            label="联系人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="type"
            label="所属行业"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="reward"
            label="年营收"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="20"
            label="负责人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="orderNum"
            label="跟进状态"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="22"
            prop="checkNum"
            label="最近跟进"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="23"
            prop="followupContent"
            label="最近跟进内容"
            align="left"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="fllowup-content">{{ scope.row.followupContent }}</div>
            </template>
          </el-table-column>
          <el-table-column
            key="24"
            prop="create_id.username"
            label="下次跟进日期"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="25"
            prop=""
            label="备注"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="26"
            prop=""
            label="领取/分配时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column key="27" label="操作" align="left" fixed="right">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="this.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <glueDetails
      :dialogDetail="dialogDetail"
      @closeDetails="closeDetails"
      :cluelist="cluelist"
      :clueLoading="clueLoading"
      :detailType="detailType"
      @followUp="followUp"
      @editRedact="editRedact"
      @transferClient="transferClient"
      @returnCluePool="returnCluePool"
      @transferTohim="transferTohim"
      ref="child"
    ></glueDetails>
    <!-- 转给他人 -->
    <el-dialog
      title="转给他人"
      :visible.sync="demandTransferDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('demandForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="demandForm"
          :inline="true"
          :model="demandForm"
          :rules="demandRules"
          class="demandForm"
        >
          <el-form-item label="新负责人" prop="principal" label-width="100px">
            <el-select
              v-model="demandForm.principal"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="转移说明"
            prop="transferInstruction"
            label-width="100px"
          >
            <el-input
              type="text"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="demandForm.transferInstruction"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel2('demandForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirm('demandForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 退回线索池 -->
    <el-dialog
      title="退回线索池"
      :visible.sync="returnCluePoolDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheckPool('returnClueForm')"
    >
      <div class="dialog-cont2">
        <el-form
          ref="returnClueForm"
          :model="returnClueForm"
          :rules="returnClueRules"
          class="returnClueForm"
          label-position="top"
        >
          <el-form-item label="请选择退回原因" prop="" label-width="100px">
            <el-button
              style="margin-right: 15px"
              size="small"
              :plain="returnActive == item.value ? false : true"
              type="primary"
              v-for="(item, index) in reasonForReturn"
              :key="index"
              @click="labelactiveClick(item.label, item.value)"
              >{{ item.label }}</el-button
            >
          </el-form-item>
          <el-form-item label="" prop="followupContent" label-width="100px">
            <el-input
              type="text"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="returnClueForm.followupContent"
              placeholder="自定义输入退回原因"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="returnCluePoolDialog = false">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="returnConfirm('returnClueForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 写跟进 -->
    <el-dialog
      class="batch-dialog n3"
      title="写跟进"
      :visible.sync="writeFollowUpDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('batchForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="batchForm"
          :inline="true"
          :model="batchForm"
          :rules="batchRules"
          class="batchForm3"
        >
          <el-form-item label="跟进线索" prop="" label-width="130px" required>
            <div>{{ commonlist.companyName }}</div>
          </el-form-item>
          <el-form-item
            label="跟进方式"
            prop="followupValue"
            label-width="130px"
          >
            <el-select
              v-model="batchForm.followupValue"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in followupMethod"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="跟进内容"
            prop="followupContent"
            label-width="130px"
          >
            <el-input
              type="textarea"
              :rows="3"
              :maxlength="200"
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.followupContent"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="跟进状态"
            prop="clueFollowup"
            label-width="130px"
          >
            <el-select
              v-model="batchForm.clueFollowup"
              filterable
              placeholder="请选择"
              :loading="loading"
              style="width: 434px"
            >
              <el-option
                v-for="item in clueFollowupStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="下次跟进日期"
            prop="entryDate"
            label-width="130px"
          >
            <el-date-picker
              v-model="batchForm.entryDate"
              align="center"
              placeholder="请选择"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 434px"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 线索转客户 -->
    <el-dialog
      title="线索转客户"
      :visible.sync="leadToDialog"
      :close-on-click-modal="false"
      width="900px"
      @close="closeCheckleadTo('batchForm')"
      class="leadto"
    >
      <div style="height: 716px; overflow: scroll">
        <div class="leadTo-content">
          <div class="leadTo-title">
            <div class="leadTo-desc">客户基础信息</div>
          </div>
          <div>
            <el-form
              ref="batchForm"
              :inline="true"
              :model="clientForm"
              :rules="clientRules"
              class="batchForm3"
              label-width="88px"
            >
              <div class="flex flex-space-bewent">
                <el-form-item label="公司名称" prop="companyName">
                  <el-input
                    v-model="clientForm.companyName"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contactName">
                  <el-input
                    v-model="clientForm.contactName"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    v-model="clientForm.contactPhone"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系人职务" prop="contactJob">
                  <el-input
                    v-model="clientForm.contactJob"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="客户归属" prop="ownership">
                  <el-input
                    v-model="clientForm.ownership"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="客户等级" prop="clientGrade">
                  <el-select
                    v-model="clientForm.clientGrade"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in Grade"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <!-- <el-form-item label="跟进状态" prop="category">
                  <el-select
                    v-model="clientForm.category"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in clueFollowupStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item> -->

                <el-form-item label="所在地区">
                  <el-cascader
                    :props="{ checkStrictly: false }"
                    v-model="clientForm.region"
                    :options="roleid"
                    :key="countindex"
                    style="width: 260px"
                    size="small"
                    :show-all-levels="true"
                    placeholder="请选择省/市/区"
                    @change="provinceChange"
                  ></el-cascader>
                </el-form-item>

                <el-form-item label="所属行业" prop="industry">
                  <!-- <el-select
                    v-model="clientForm.industry"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                    :remote-method="(val) => remoteSupplierDropList(val, 2)"
                  >
                    <el-option
                      v-for="item in Industry"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->
                  <el-autocomplete
                    size="small"
                    clearable
                    class="blsupplier"
                    v-model="clientForm.industry"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入内容或选择内容"
                    @select="handleAddSupplier3"
                    style="width: 260px"
                  ></el-autocomplete>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="年营收">
                  <el-select
                    v-model="clientForm.income"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in AnnualTax"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="年税收">
                  <el-select
                    v-model="clientForm.annualTax"
                    filterable
                    placeholder="请选择"
                    :loading="loading"
                    size="small"
                    style="width: 260px"
                  >
                    <el-option
                      v-for="item in AnnualTax"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex flex-space-bewent">
                <el-form-item label="详细地址">
                  <el-input
                    v-model="clientForm.address"
                    size="small"
                    placeholder="请输入"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input
                    style="width: 260px"
                    size="small"
                    v-model="clientForm.remark"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <div class="leadTo-content2">
          <div class="leadTo-title">
            <div class="leadTo-desc">客户需求</div>
          </div>
          <div class="leadTo-box">
            <div
              class="leadTo-card"
              v-for="(item, index) in clientList"
              :key="index"
            >
              <div class="flex flex-space-bewent">
                <div>需求{{ toChineseNumber(index + 1, index) }}</div>
                <el-button type="text" @click.stop="leadToDelete(index)"
                  >删除</el-button
                >
              </div>
              <div>
                <el-form
                  ref="customerform"
                  :model="item"
                  label-width="80px"
                  :inline="true"
                  :rules="customerRules"
                >
                  <div
                    class="flex flex-space-bewent"
                    style="justify-content: flex-start"
                  >
                    <el-form-item label="需求产品" prop="productId">
                      <el-select
                        v-model="item.productId"
                        filterable
                        placeholder="请选择"
                        :loading="loading"
                        size="small"
                        style="width: 122px"
                      >
                        <el-option
                          v-for="truee in Product"
                          :key="truee.value"
                          :label="truee.label"
                          :value="truee.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="紧急程度" prop="intentionlevel">
                      <el-select
                        v-model="item.intentionlevel"
                        filterable
                        placeholder="请选择"
                        :loading="loading"
                        size="small"
                        style="width: 122px"
                      >
                        <el-option
                          v-for="truee in intentionlevelList"
                          :key="truee.value"
                          :label="truee.label"
                          :value="truee.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="跟进状态">
                      <el-select
                        v-model="item.followupStatus"
                        filterable
                        placeholder="请选择"
                        :loading="loading"
                        size="small"
                        style="width: 122px"
                      >
                        <el-option
                          v-for="truee in clueFollowupStatus"
                          :key="truee.value"
                          :label="truee.label"
                          :value="truee.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item> -->
                  </div>
                  <el-form-item label="" prop="details">
                    <el-input
                      type="textarea"
                      :rows="3"
                      :maxlength="500"
                      style="width: 766px"
                      autocomplete="new-password"
                      v-model="item.details"
                      placeholder="请输入需求详情"
                      show-word-limit
                      ref="leadtoContent"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div style="padding: 15px 0">
            <el-button size="small" type="primary" @click.stop="addToContinue"
              >继续添加</el-button
            >
          </div>
        </div>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancelkehu('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="confirmTransfer('batchForm', index)"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确认转客户
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 编辑线索 -->
    <el-dialog
      title="编辑线索"
      :visible.sync="editLeadToDialog"
      :close-on-click-modal="false"
      width="900px"
      @close="closeCheck('batchForm')"
      class="leadto"
    >
      <div
        class="leadTo-content"
        style="padding-top: 30px; padding-bottom: 30px"
      >
        <div>
          <el-form
            ref="batchForm"
            :inline="true"
            :model="clientForm2"
            :rules="clientRules"
            class="batchForm3"
            label-width="88px"
          >
            <div class="flex flex-space-bewent">
              <el-form-item label="公司名称" prop="companyName">
                <el-input
                  v-model="clientForm2.companyName"
                  size="small"
                  placeholder="请输入"
                  style="width: 260px"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系人" prop="contactName">
                <el-input
                  v-model="clientForm2.contactName"
                  size="small"
                  placeholder="请输入"
                  style="width: 260px"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex flex-space-bewent">
              <el-form-item label="联系电话" prop="contactPhone">
                <!--  @input="handleInput" -->
                <el-input
                  v-model="clientForm2.contactPhone"
                  size="small"
                  placeholder="请输入"
                  style="width: 260px"
                  :maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系人职务" prop="contactJob">
                <el-input
                  v-model="clientForm2.contactJob"
                  size="small"
                  placeholder="请输入"
                  style="width: 260px"
                ></el-input>
              </el-form-item>
            </div>

            <div class="flex flex-space-bewent">
              <el-form-item label="所属行业">
                <!-- <el-select
                  v-model="clientForm2.industry"
                  filterable
                  placeholder="请选择"
                  :loading="loading"
                  size="small"
                  style="width: 260px"
                >
                  <el-option
                    v-for="item in Industry"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
                <el-input
                  v-model="clientForm2.industry"
                  size="small"
                  placeholder="请输入"
                  style="width: 260px"
                ></el-input>
              </el-form-item>
              <el-form-item label="年营收" prop="income">
                <el-input
                  v-model="clientForm2.income"
                  size="small"
                  placeholder="请输入"
                  style="width: 260px"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex flex-space-bewent">
              <el-form-item label="所在地区">
                <el-cascader
                  :props="{ checkStrictly: false }"
                  v-model="clientForm2.region"
                  :options="roleid"
                  :key="countindex"
                  style="width: 260px"
                  size="small"
                  :show-all-levels="true"
                  placeholder="请选择省/市/区"
                  @change="provinceChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="年税收">
                <el-select
                  v-model="clientForm2.annualTax"
                  filterable
                  placeholder="请选择"
                  :loading="loading"
                  size="small"
                  style="width: 260px"
                >
                  <el-option
                    v-for="item in AnnualTax"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex flex-space-bewent">
              <el-form-item label="详细地址" prop="address">
                <el-input
                  v-model="clientForm2.address"
                  size="small"
                  placeholder="请输入"
                  style="width: 260px"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input
                  style="width: 260px"
                  size="small"
                  v-model="clientForm2.remark"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="editLeadToDialog = false">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="editleadTo('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 客户详情 -->
    <el-dialog
      title="客户详情"
      :visible.sync="dialogDetail2"
      :close-on-click-modal="false"
      width="980px"
    >
      <div class="page_box2">
        <div class="glue-details">
          <div class="flex flex-center">
            <div class="flex flex-center">
              <div class="company-name">{{ cluelist2.clientName }}</div>
              <div class="company-but" v-if="cluelist2.grade">
                {{ selectDictlabel(Grade, cluelist2.grade) }}类
              </div>
              <div
                class="company-but"
                v-if="cluelist2.category"
                style="margin-left: 10px"
              >
                {{ selectDictlabel(Category, cluelist2.category) }}
              </div>
            </div>
          </div>
          <div class="flex flex-center information">
            <div style="margin-right: 8px">
              <div class="flex info-list">
                <div>来源：</div>
                <div>
                  {{ selectDictlabel(Source, cluelist2.source) }}
                </div>
              </div>
              <div class="flex info-list">
                <div>需求产品：</div>
                <div>
                  {{ selectCirclation(Product, cluelist2.productDemand) }}
                </div>
              </div>
            </div>
            <div>
              <div class="flex info-list">
                <div>归属：</div>
                <div>{{ cluelist2.ownership }}</div>
              </div>
              <div class="flex info-list">
                <div>下次跟进：</div>
                <div>{{ cluelist2.nextFollowTimeStr }}</div>
              </div>
            </div>
            <div>
              <div class="flex info-list">
                <div>负责人：</div>
                <div>
                  {{ cluelist2.departmentName }}-{{ cluelist2.chargeName }}
                </div>
              </div>
              <div class="flex info-list">
                <div>最近更新：</div>
                <div>{{ cluelist2.updateTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <el-radio-group
            v-model="radio"
            style="margin-bottom: 12px"
            size="small"
          >
            <el-radio-button :label="1">跟进动态</el-radio-button>
            <el-radio-button :label="2">详细</el-radio-button>
          </el-radio-group>
          <div class="label-button" v-if="radio == 1">
            <button :class="{ active: number == -1 }" @click="labelBut2(-1)">
              全部
            </button>
            <button :class="{ active: number == 2 }" @click="labelBut2(2)">
              跟进记录
            </button>
            <button :class="{ active: number == 1 }" @click="labelBut2(1)">
              系统动态
            </button>
            <!-- <button style="float: right" class="write-follow-up">写跟进</button> -->
          </div>
        </div>
        <div
          class="followUp-dynamics"
          v-if="detalist2.length != 0 && radio == 1"
        >
          <div v-for="(item, index) in detalist2" :key="index">
            <div>
              {{ item.createTime }} {{ item.departmentName }} -
              {{ item.createdName }}
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 1 && item.followAs"
                >【{{ selectDictlabel(FollowAs, item.followAs) }}】</em
              >
              <em
                style="color: #f9a91e"
                v-if="item.followupType == 2 && item.followupMethod"
                >【{{
                  selectDictlabel(followupMethod, item.followupMethod)
                }}】</em
              >
            </div>
            <div v-if="item.followupContent">
              跟进内容：{{ item.followupContent }}
            </div>
            <div v-if="item.followupStatusRecord">
              跟进状态：{{ item.followupStatusRecord }}
            </div>
            <div v-if="item.chargeRecord">负责人：{{ item.chargeRecord }}</div>
          </div>
        </div>
        <el-empty
          description="没有数据了~"
          v-if="detalist2.length == 0 && radio == 1"
        ></el-empty>
        <el-descriptions title="基础信息" v-if="radio == 2">
          <el-descriptions-item label="公司名称">{{
            cluelist2.companyName
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人姓名">{{
            cluelist2.contactName
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            cluelist2.contactPhone
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人职务">{{
            cluelist2.contactJob
          }}</el-descriptions-item>
          <el-descriptions-item label="所属行业">{{
            selectDictlabel(Industry, cluelist2.industry)
          }}</el-descriptions-item>
          <el-descriptions-item label="年营收">{{
            cluelist2.annualRevenue
          }}</el-descriptions-item>
          <el-descriptions-item label="所在地区"></el-descriptions-item>
          <el-descriptions-item label="详细地址">{{
            cluelist2.address
          }}</el-descriptions-item>
          <el-descriptions-item label="备注">{{
            cluelist2.remark
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="基础信息" v-if="radio == 2">
          <el-descriptions-item label="领取/分配人">{{
            cluelist2.assignerName
          }}</el-descriptions-item>
          <el-descriptions-item label="领取/分配时间">{{
            cluelist2.assignerDate
          }}</el-descriptions-item>
          <el-descriptions-item label="最近更新人">{{
            cluelist2.updatedName
          }}</el-descriptions-item>
          <el-descriptions-item label="最近更新时间">{{
            cluelist2.updateTime
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import pageTitleBar from "@/components/page-title-bar.vue";
import glueDetails from "@/components/glueDetails";
import {
  assetsClueList,
  assetsClueassetsClue,
  commonType,
  assetsClueFollow,
  clueClientList,
  clueClient,
  departmentDepManagerList,
  assetsClueCharge,
  assetsClueReturn,
  commonProvices,
  editAssetsClue,
  departmentList,
} from "@/api/report";
export default {
  inject: ["reload"],
  components: { pageTitleBar, glueDetails },
  data() {
    var checkPhone = (rule, value, callback) => {
      console.log(value);
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      //

      title: "线索",
      tabStatus: "1",
      detailType: false,
      loading: false,
      radio: 1,
      page: 1,
      perPage: 10,
      demandTransferDialog: false,
      tabLoading: false,
      dialogDetail: false,
      dialogDetail2: false,
      cluelist2: "",
      detalist2: [],
      count: 0,
      checked: false,
      clueLoading: true,
      writeFollowUpDialog: false,
      returnCluePoolDialog: false,
      returnClueForm: {
        principal: "",
        followupContent: "",
      },
      returnClueRules: {
        followupContent: [
          {
            required: true,
            message: "请输入退回原因",
            trigger: "blur",
          },
        ],
      },
      roleid: [],
      countindex: 0,
      roleid2: [],
      countindex2: 0,
      leadToDialog: false,
      editLeadToDialog: false,
      batchForm: {
        followupContent: "",
        followupValue: 1092,
        clueFollowup: "",
        entryDate: "",
      },
      clientForm: {
        clientGrade: "",
        companyName: "",
        contactName: "",
        contactPhone: "",
        ownership: "",
        category: "",
        industry: "", //所属行业
        industryId:"",
        income: "", //年营收
        annualTax: "", //年税收
        region: [],
        address: "",
        remark: "",
      },
      clientForm2: {
        clientGrade: "",
        companyName: "",
        contactName: "",
        contactPhone: "",
        ownership: "",
        category: "",
        industry: "", //所属行业
        industryId:"",
        income: "", //年营收
        annualTax: "", //年税收
        region: [],
        address: "",
        remark: "",
        contactJob:""
      },
      demandForm: {
        principal: "",
        transferInstruction: "",
      },
      reasonForReturn: [
        {
          label: "客户联系不上",
          value: "1",
        },
        {
          label: "客户明显拒绝",
          value: "2",
        },
      ],
      demandRules: {
        principal: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
      },
      // customerform: {},
      clientRules: {
        companyName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        //  validator: checkPhone,
        contactPhone: [
          {
            required: true,

            message: "请输入联系人电话",
            trigger: "blur",
          },
        ],
        ownership: [
          {
            required: true,
            message: "请输入客户归属",
            trigger: "blur",
          },
        ],
        clientGrade: [
          {
            required: true,
            message: "请选择客户等级",
            trigger: "change",
          },
        ],
        industry: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change",
          },
        ],
        category: [
          {
            required: true,
            message: "请选择跟进状态",
            trigger: "change",
          },
        ],
      },
      batchRules: {
        followupValue: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        clueFollowup: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
      statusType: [
        {
          label: "跟进中",
          value: "1",
        },
        {
          label: "已转客户",
          value: "4",
        },
      ],
      options: [],
      form: {
        principal: "",
        keyword: "",
        followType: "", //跟进状态
        auditDate: [],
        involved: "",
        clientGrade: "", // 客户等级
        product: "", //需求产品
        belonger: "", //归属人
        clientDate: [], //转客户时间段
        income: "", //年营收
        annualTax: "", //年税收
        address: "", //详细地址
        remark: "", //备注
        department: "",
      },
      isApply: false,
      advanceDataList: [],
      selectionItem: [],
      cumulativeList: [],
      cluelist: {},
      clueFollowupStatus: "",
      followupMethod: "",
      Grade: "",
      Category: "",
      commonlist: "",
      Industry: [],
      Product: "",
      AnnualTax: "",
      FollowupStatus: "",
      clueId: "",
      intentionlevelList: [],
      clientList: [
        {
          productId: "",
          intentionlevel: "",
          followupStatus: 1018,
          details: "",
        },
      ],
      customerRules: {
        productId: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        intentionlevel: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        details: [
          {
            required: true,
            message: "请输入需求详情",
            trigger: "blur",
          },
        ],
      },
      number: null,
      demandId: "",
      returnActive: "",
      indexs: 0,
      provice: "",
      city: "",
      borough: "",
      clueFollowupStatus: "",
      followupMethod: "",
      Grade: "",
      Category: "",
      commonlist: "",
      Product: "",
      AnnualTax: "",
      FollowupStatus: "",
      Source: "",
      FollowAs: "",
      clientId: "",
      number: null,
      clueIndex: 0,
    };
  },
  watch: {
    checked(val) {
      this.getData(1);
    },
    cluelist2(val) {
      if (val.id) {
        this.getFollow2();
      }
    },
  },
  mounted() {
    this.getData();
    this.getCommon();
    this.getCommonProvices();
    this.getDeparmentList();
  },
  activated() {
    this.getData();
    this.getCommon();
    this.getDepartmentList();
  },
  methods: {
    querySearch(queryString, cb) {
      let _this = this;
     let restaurants = _this.Industry;
      let arr = [];
     _this.Industry.forEach((item, index) => {
        if (item.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0) {
        let obj = {
            value: item.label,
            id: item.value,
          };
           arr.push(obj); 
        }
        
      });

       console.log(arr);
      return cb && cb(arr);
    },

    handleAddSupplier3(item) {
        console.log(item);
      this.clientForm.industry = item.value;
      this.clientForm.industryId = item.id;
    },
    // 跟进动态
    labelBut2(val) {
      console.log(1);
      this.number = val;
      this.getFollow2();
    },
    // 客户详情
    async clientDetails(row) {
      let _this = this;
      console.log(row);
      _this.dialogDetail2 = true;
      _this.applytype == 2;
      _this.clientId = row.clientId;
      let params = {
        id: row.clientId,
      };
      _this.$ajax({
        url: _this.globalData.orgHost + "client/client",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data } = res;
            console.log(data);
            _this.cluelist2 = data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    // 公共类型
    async getCommon() {
      let _this = this;
      let params = {};
      _this.$ajax({
        url: _this.globalData.orgCommonHost + "system/common-type",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let {
              FollowupMethod,
              ClueFollowupStatus,
              Grade,
              Industry,
              Product,
              AnnualTax,
              FollowupStatus,
              Source,
              FollowAs,
              Intentionlevel,
              Category,
            } = res.data;
            _this.clueFollowupStatus = ClueFollowupStatus;
            _this.followupMethod = FollowupMethod;
            _this.Grade = Grade;
            _this.Industry = Industry;
            _this.Category = Category;
            _this.Product = Product;
            _this.AnnualTax = AnnualTax;
            _this.FollowupStatus = FollowupStatus;
            _this.Source = Source;
            console.log(_this.Industry, "skhgkshdgkhskdjghksdhgk");
            _this.FollowAs = FollowAs;
            _this.intentionlevelList = Intentionlevel;
          }
        },
      });
    },
    // 跟进记录
    async getFollow2() {
      let _this = this;
      // try {
      console.log(_this.cluelist2);
      let params = {
        Id: _this.clientId,
      };
      if (_this.number != null) {
        params.FollowupType = _this.number;
      }
      // client/asset-follow
      _this.$ajax({
        url: _this.globalData.orgHost + "client/asset-follow",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data } = res.data;
            _this.detalist2 = data;
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 获取部门
    async getDeparmentList() {
      let _this = this;
      let params = {
        pageIndex: 1,
        pageSize: 100000,
      };
      _this.loading = true;
      let response = await departmentList(params);
      if (response.statusCode == 200) {
        // _this.newDatalist(response.data);
        let new_data = [];
        for (let item of response.data) {
          item.value = item.id;
          item.label = item.departmentName;
          item.children = item.children;
          if (item.children) {
            for (let it of item.children) {
              it.value = it.id;
              it.label = it.departmentName;
              it.children = it.children;
              if (it.children) {
                for (let qu of it.children) {
                  qu.value = qu.id;
                  qu.label = qu.departmentName;
                  qu.children = "";
                }
              }
            }
          }
          new_data.push(item);
        }
        _this.roleid2 = new_data;
      } else {
        _this.$message({
          type: "error",
          message: response.errors,
        });
      }
      setTimeout(() => {
        _this.loading = false;
      }, 1000);
    },
    details(row) {
      this.getAssetsClueassetsClue(row);
    },
    handleInput(value) {
      const reg = /^[\d\.]+$/;
      if (value && !reg.test(value)) {
        this.clientForm2.contactPhone = value.replace(/[^\d\.]/g, "");
      }
    },
    cumulativeClick(index) {
      let _this = this;
      console.log(index);
      _this.number = index;
      _this.getData(1);
    },
    labelactiveClick(label, index) {
      this.returnActive = index;
      this.returnClueForm.followupContent = label;
    },
    toChineseNumber(num, cont) {
      let _this = this;
      let index = this.ll.toChineseNumber(num);
      _this.indexs = cont;
      return index;
    },
    // 循环请求产品
    selectCirclation(lab, value) {
      let _this = this;
      let arr = [];
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          console.log(item);
          let lable = _this.selectDictlabel(lab, item);
          arr.push(lable);
        });
      }
      return arr.join(",");
    },
    // 选择省市区
    provinceChange(val) {
      console.log(val, "val", this.roleid);
      let provcode;
      if (this.roleid.length > 1) {
        provcode = this.roleid.filter((item, index) => {
          return item.provCode == val[0];
        });
      }
      console.log(provcode);
      for (let item of provcode) {
        if (val[0] == item.provCode) {
          this.provice = item.label;
        }
        if (item.children) {
          for (let it of item.children) {
            if (val[1] == it.provCode) {
              this.city = it.label;
            }
            if (it.children) {
              for (let qu of it.children) {
                if (val[2] == qu.provCode) {
                  this.borough = qu.label;
                }
              }
            }
          }
        }
      }
      console.log("地区", this.provice, this.city, this.borough);
    },
    selectDictlabel(lab, value) {
      let _this = this;
      console.log(value);
      let label = this.ll.selectDictLabel(lab, value);
      return label;
    },
    cancel(form) {
      this.writeFollowUpDialog = false;
    },
    cancelkehu(form) {
      this.leadToDialog = false;
    },
    cancel2() {
      this.demandTransferDialog = false;
    },
    // 编辑线索   editAssetsClue
    editleadTo(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            id: _this.clueId,
            companyName: _this.clientForm2.companyName,
            contactName: _this.clientForm2.contactName,
            contactPhone: _this.clientForm2.contactPhone,
            contactJob: _this.clientForm2.contactJob,
            industry: _this.clientForm2.industry,
            annualTax: _this.clientForm2.annualTax,
            address: _this.clientForm2.address,
            remark: _this.clientForm2.remark,
          };
          if (_this.clientForm2.income) {
            params.annualRevenue = String(_this.clientForm2.income);
          } else {
            params.annualRevenue = "";
          }
          if (_this.clientForm2.region.length) {
            params.proviceId = _this.clientForm2.region[0] || "";
            params.provice = _this.provice;
            params.cityId = _this.clientForm2.region[1] || "";
            params.city = _this.city;
            params.boroughId = _this.clientForm2.region[2] || "";
            params.borough = _this.borough;
          }
          //
          try {
            let response = await editAssetsClue(params);
            if (response.statusCode == 200) {
              _this.editLeadToDialog = false;
              _this.advanceDataList = [];
              _this.getData();
              _this.$message({
                type: "success",
                message: "线索编辑成功",
              });
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 确定跟进记录
    applyBatch(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            ClueId: _this.commonlist.id,
            followupMethod: _this.batchForm.followupValue,
            followupContent: _this.batchForm.followupContent,
            followupStatus: _this.batchForm.clueFollowup,
            followupDate: _this.batchForm.entryDate,
          };
          _this.isApply = true;
          try {
            let response = await assetsClueFollow(params);
            if (response.statusCode == 200) {
              _this.writeFollowUpDialog = false;
              _this.advanceDataList = [];
              _this.isApply = false;
              _this.getData();
              if (_this.commonlist.active == 1) {
                _this.$refs.child.getData();
              }

              _this.$message({
                type: "success",
                message: "写跟进成功",
              });
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 退回线索池 returnConfirm
    returnConfirm(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            id: _this.demandId,
            followupContent: _this.returnClueForm.followupContent,
          };
          try {
            let response = await assetsClueReturn(params);
            if (response.statusCode == 200) {
              _this.getData();
              _this.$message({
                type: "success",
                message: "退回线索池成功",
              });
              _this.returnCluePoolDialog = false;
              if (_this.clueIndex == 2) {
                _this.closeDetails();
              }
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 获取地区
    // commonProvices
    async getCommonProvices() {
      let _this = this;
      let params = {};
      try {
        let response = await commonProvices();
        console.log(response);
        if (response.statusCode == 200) {
          let new_data = [];
          for (let item of response.data) {
            item.value = item.provCode;
            item.label = item.provName;
            item.children = item.child;
            if (item.children) {
              for (let it of item.children) {
                it.value = it.provCode;
                it.label = it.provName;
                it.children = it.child;
                if (it.children) {
                  for (let qu of it.children) {
                    qu.value = qu.provCode;
                    qu.label = qu.provName;
                    qu.children = "";
                  }
                }
              }
            }
            new_data.push(item);
          }
          _this.roleid = new_data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 确认转客户 confirmTransfer
    confirmTransfer(form) {
      let _this = this;
      console.log(_this.clientForm.industry,_this.clientForm.industryId);
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            clueId: _this.clueId,
            companyName: _this.clientForm.companyName,
            contactPhone: _this.clientForm.contactPhone,
            ownership: _this.clientForm.ownership,
            grade: _this.clientForm.clientGrade,
            category: _this.clientForm.category,
            address: _this.clientForm.address,
            remark: _this.clientForm.remark,
          };
          if (_this.clientForm.contactName) {
            params.contactName = _this.clientForm.contactName;
          }
          if (_this.clientForm.contactJob) {
            params.contactJob = _this.clientForm.contactJob;
          }
          if (_this.clientForm.industryId) {
            params.industry = _this.clientForm.industryId;
          }else{
             params.industry = null;
             params.IndustryName = _this.clientForm.industry;
          }
          if (_this.clientForm.income) {
            params.income = _this.clientForm.income;
          }
          if (_this.clientForm.annualTax) {
            params.annualTax = _this.clientForm.annualTax;
          }
          if (_this.clientForm.region.length) {
            params.proviceId = _this.clientForm.region[0] || "";
            params.provice = _this.provice;
            params.cityId = _this.clientForm.region[1] || "";
            params.city = _this.city;
            params.boroughId = _this.clientForm.region[2] || "";
            params.borough = _this.borough;
          }
          console.log(_this.clientList.productId);
          _this.$refs["customerform"][_this.indexs].validate(async (valid) => {
            if (valid) {
              if (_this.clientList[0].productId) {
                params.demandList = _this.clientList;
              }
              _this.isApply = true;
              try {
                let response = await clueClient(params);
                if (response.statusCode == 200) {
                  _this.leadToDialog = false;
                  _this.advanceDataList = [];
                  _this.isApply = false;
                  _this.getData();
                  _this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                } else {
                  _this.$message({
                    type: "error",
                    message: response.errors,
                  });
                }
                console.log(response);
              } catch (error) {
                console.log(error);
              }
            }
          });
        }
      });
    },
    // 确认转给他人
    async confirm(form) {
      let _this = this;
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            id: _this.demandId,
            chargeId: _this.demandForm.principal,
            followupContent: _this.demandForm.transferInstruction,
          };
          try {
            let response = await assetsClueCharge(params);
            if (response.statusCode == 200) {
              _this.getData();
              _this.$message({
                type: "success",
                message: "转移成功",
              });
              _this.demandTransferDialog = false;
              // if (_this.applytype == 2) {
              //   _this.demandDetails(_this.cluelist);
              // }
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 获取部门负责人
    async getDepartmentList() {
      let _this = this;
      let params = {};
      try {
        let response = await departmentDepManagerList(params);
        console.log(response.data, "获取");
        if (response.statusCode == 200) {
          let list = response.data;
          list.map((item, index) => {
            let obj = {};
            obj.label = item.userName;
            obj.value = item.userId;
            _this.options.push(obj);
          });
        }
      } catch (error) {
        new Error(error);
      }
    },
    beforeHandleCommand(flag, command) {
      return {
        flag: flag,
        command: command,
      };
    },
    closeCheck(formName) {
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
    },
    closeCheckPool(formName) {
      console.log("skdghsk");
      this.$refs[formName].resetFields();
      this.returnClueForm.followupContent = "";
      this.returnActive = "";
    },
    closeCheckleadTo(formName) {
      this.$refs[formName].resetFields();
      this.clientList = [
        {
          productId: "",
          intentionlevel: "",
          followupStatus: 1018,
          details: "",
        },
      ];
    },
    transferClient(row) {
      let _this = this;
      _this.leadToDialog = true;
      _this.clueId = row.id;
      _this.clientForm.companyName = row.companyName;
      _this.clientForm.contactName = row.contactName;
      _this.clientForm.contactPhone = row.contactPhone;
      _this.clientForm.contactJob = row.contactJob;
      _this.clientForm.ownership = row.departmentName + " " + row.chargeName;
      _this.clientForm.clientGrade = row.grade;
      _this.clientForm.category = row.followupStatus;
      // _this.clientForm.industry = row.industry;
      // _this.clientForm.income = row.annualRevenue;
      _this.clientForm.annualTax = row.annualTax;
      _this.clientForm.remark = row.remark;
      console.log(row);
    },
    addToContinue() {
      let _this = this;
      if (this.clientList.length >= 5) {
        this.$message({
          type: "error",
          message: "客户需求最多不能超过5个",
        });
        return;
      }
      let obj = {
        productId: "",
        intentionlevel: "",
        followupStatus: 1018,
        details: "",
      };
      this.$message({
        type: "success",
        message: "添加一个需求成功",
      });
      this.clientList.push(obj);
      console.log(_this.$refs.leadtoContent.length);
      this.$nextTick(() => {
        _this.$refs.leadtoContent[_this.clientList.length - 1].focus();
      });
    },
    leadToDelete(index) {
      if (this.clientList.length > 1) {
        this.clientList.splice(index, 1);
      } else {
        this.$message({
          type: "error",
          message: "至少添加一个需求",
        });
      }
    },
    MenuMore(flag) {
      console.log(flag);
      if (flag.flag == "transferTohim") {
        this.demandTransferDialog = true;
        this.demandId = flag.command.id;
      } else if (flag.flag == "returnCluePool") {
        this.returnCluePoolDialog = true;
        this.demandId = flag.command.id;
      } else if (flag.flag == "redact") {
        this.editLeadToDialog = true;
        console.log(flag.command);
        this.clueId = flag.command.id;
        this.clientForm2.companyName = flag.command.companyName;
        this.clientForm2.contactName = flag.command.contactName;
        this.clientForm2.contactPhone = flag.command.contactPhone;
        this.clientForm2.contactJob = flag.command.contactJob;
        this.clientForm2.industry = flag.command.industry;
        this.clientForm2.income = flag.command.annualRevenue;
        this.clientForm2.annualTax = flag.command.annualTax;
        this.clientForm2.address = flag.command.address;
        this.clientForm2.remark = flag.command.remark;
        this.clientForm2.region = [
          flag.command.proviceId,
          flag.command.cityId,
          flag.command.boroughId,
        ];
      }
    },
    // 退回线索池
    returnCluePool(col) {
      console.log(col);
      this.clueIndex = col.index;
      this.returnCluePoolDialog = true;
      this.demandId = col.id;
    },
    // 转他人
    transferTohim(col) {
      this.demandTransferDialog = true;
      this.demandId = col.id;
      console.log(this.demandId);
    },

    // 编辑
    editRedact(col) {
      let _this = this;
      this.editLeadToDialog = true;
      this.clueId = col.id;
      this.clientForm2.companyName = col.companyName;
      this.clientForm2.contactName = col.contactName;
      this.clientForm2.contactPhone = col.contactPhone;
      this.clientForm2.contactJob = col.contactJob;
      this.clientForm2.industry = col.industry;
      this.clientForm2.income = col.annualRevenue;
      this.clientForm2.annualTax = col.annualTax;
      this.clientForm2.address = col.address;
      this.clientForm2.remark = col.remark;
      this.clientForm2.region = [col.proviceId, col.cityId, col.boroughId];
    },
    handleClick(val, tab) {
      let _this = this;
      _this.advanceDataList = [];
      if (_this.tabStatus == "4") {
        _this.detailType = true;
      } else {
        _this.detailType = false;
      }
      _this.getData();
      console.log(val, tab, _this.tabStatus);
    },
    // 查询数据
    async getData(page, perPage) {
      let _this = this;
      if (page == 1) {
        _this.page = 1;
      }
      // try {
      let index = page ? page : _this.page;
      let size = perPage ? perPage : _this.perPage;
      let params;

      _this.tabLoading = true;
      if (_this.tabStatus == 4) {
          params = {
          pageIndex: index,
          pageSize: size,
          AdminName: _this.form.belonger,
          keyword: _this.form.keyword,
          CreateTimeStart: _this.form.clientDate[0] || "",
          CreateTimeEnd: _this.form.clientDate[1] || "",
        };
        if (_this.form.followType) {
          params.Category = _this.form.followType;
        }
        if (_this.form.clientGrade) {
          params.Grade = _this.form.clientGrade;
        }
        if (_this.form.product) {
          params.ProductDemand = _this.form.product;
        }
      } else {
        params = {
          pageIndex: index,
          pageSize: size,
          keyword: _this.form.keyword,
          ChargeName: _this.form.principal,
          Industry: _this.form.involved,
          CreateTimeStart: _this.form.auditDate[0] || "",
          CreateTimeEnd: _this.form.auditDate[1] || "",
          FollowupStatus: _this.number == null ? "" : _this.number,
          OnlySelf: _this.checked,
          DepartmentId:
            _this.form.department[_this.form.department.length - 1] || "",
        };
      }
      let response;
      if (_this.tabStatus == 1) {
        response = "assets-clue/list"; //await assetsClueList(params);
      } else {
        response = "assets-clue/clue-client-list"; //await clueClientList(params);
      }

      _this.$ajax({
        url: _this.globalData.orgHost + response,
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            let { data, totalCount, cumulativeData } = res.data;
            console.log(data);
            _this.advanceDataList = data;
            _this.count = totalCount;
            if (cumulativeData) {
              _this.cumulativeList = cumulativeData.cumulative;
            }
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.tabLoading = false;
        },
      });

      // if (response.statusCode == 200) {
      //   let { data, totalCount, cumulativeData } = response.data;
      //   console.log(data);
      //   _this.advanceDataList = data;
      //   _this.count = totalCount;
      //   let m;
      //   let s;
      //   if (cumulativeData) {
      //     _this.cumulativeList = cumulativeData.cumulative;
      //   }
      //   _this.tabLoading = false;
      // }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 已转客户  clueClientList
    getDataClueClient() {
      let _this = this;
    },
    followUp(row) {
      console.log(row);
      this.commonlist = row;
      this.batchForm.clueFollowup = row.followupStatus;
      this.writeFollowUpDialog = true;
    },
    // 公共类型
    // async getCommon() {
    //   let _this = this;
    //   let params = {};
    //   try {
    //     let response = await commonType();
    //     console.log(response);
    //     if (response.statusCode == 200) {
    //       let {
    //         FollowupMethod,
    //         ClueFollowupStatus,
    //         Grade,
    //         Industry,
    //         Product,
    //         AnnualTax,
    //         FollowupStatus,
    //         Intentionlevel,
    //         Category,
    //       } = response.data;
    //       _this.clueFollowupStatus = ClueFollowupStatus;
    //       _this.followupMethod = FollowupMethod;
    //       _this.Grade = Grade;
    //       _this.Category = Category;
    //       _this.Industry = Industry;
    //       _this.Product = Product;
    //       _this.AnnualTax = AnnualTax;
    //       _this.FollowupStatus = FollowupStatus;
    //       _this.intentionlevelList = Intentionlevel;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // 线索详情
    async getAssetsClueassetsClue(row) {
      let _this = this;
      console.log(row);
      this.dialogDetail = true;
      let params = {
        id: row.id,
      };
      // try {
      //   let response = await assetsClueassetsClue(params);
      //   if (response.statusCode == 200) {
      //     _this.cluelist = response.data;
      //     console.log(_this.clueDetails);
      //   } else {
      //     _this.$message({
      //       type: "error",
      //       message: response.errors,
      //     });
      //   }
      //   console.log(response);
      // } catch (error) {
      //   console.log(error);
      // }
      _this.$ajax({
        url: _this.globalData.orgHost + "assets-clue/assets-clue",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.cluelist = res.data;
            console.log(_this.clueDetails);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
        },
      });
    },
    // 关闭详情弹层
    // 重置
    reset(reform) {
      this.$refs[reform].resetFields();

      this.form.auditDate = [];
      this.form.clientDate = [];
      this.getData();
    },
    handleSizeChange(val) {
      this.perPage = val;
      this.page = 1;
      this.advanceDataList = [];
      this.getData(this.page, this.perPage);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.advanceDataList = [];
      this.getData(this.page, this.perPage);
    },
    // 多选项目
    handleSelectionChange(val) {
      this.selectionItem = val;
      console.log(val);
    },
    closeDetails() {
      this.dialogDetail = false;
    },
  },
};
</script>

<style scoped>
.followUp-dynamics {
  margin-top: 12px;

  width: 884px;
  height: 400px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #f1f1f1;
  overflow-y: auto;
}
.followUp-dynamics > div {
  padding: 16px 24px;
  line-height: 22px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #333333;
  border-bottom: 1px solid #f1f1f1;
}
.company-name,
.client-name {
  width: 202px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.client-name {
  width: 200px;
}
.company-but {
  margin-left: 20px;
  background: #f9a91e;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  text-align: center;
}
.client-name {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fllowup-content {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info-list > div {
  line-height: 20px;
  padding-bottom: 8px;
}
.info-list div:first-child {
  width: 85px;
  text-align: left;
  color: #999999;
}
.info-list div:last-child {
  width: 160px;
}
.information {
  padding: 10px 0;
  width: 65%;
}
.company-name {
  font-size: 20px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
  line-height: 35px;
}
.flex-center {
  justify-content: space-between;
  align-items: center;
}
.leadTo-card {
  width: 100%;
  box-sizing: border-box;
  padding: 11px 15px 0px;
  margin-bottom: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #f1f1f1;
}
.flex-space-bewent {
  justify-content: space-between;
  align-content: center;
}
.leadTo-desc {
  padding-left: 6px;
  border-left: 3px solid #1890ff;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: bold;
  color: #333333;
}
.leadTo-title {
  width: 100%;
  padding: 10px 0 10px 4px;
  /* height: 40px;
  line-height: 40px; */
  margin-bottom: 12px;
  background: #f3f4f7;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  box-sizing: border-box;
}
.leadTo-content,
.leadTo-content2 {
  padding: 16px 48px 0;
}
.leadTo-content2 {
  padding-top: 0;
}
/* .leadTo-box {
  height: 214px;
  overflow-y: scroll;
} */
.dialog-cont {
  padding: 30px 21px;
}
.dialog-cont2 {
  padding: 10px 30px;
}
.bd-bar {
  padding: 0 20px;
  background-color: #fff;
}
.table_page .page {
  padding-bottom: 10px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.search-bar_n {
  padding: 20px 0;
}
.page_box2 {
  padding: 26px 48px !important;
  background: #fff;
}
.label-button button {
  margin-right: 10px;
  width: 70px;
  height: 24px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #666666;
  border: none;
  outline: none;
  background: #f5f5f5;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  cursor: pointer;
}
.label-button .active {
  background: #e5f3ff;
  border-radius: 2px 2px 2px 2px;
  color: #1890ff;
  opacity: 1;
  border: 1px solid #1890ff !important;
}
</style>
<style>
.returnClueForm .el-form-item {
  margin-bottom: 12px !important;
}
.leadTo-content .el-form-item__label {
  text-align: left;
}
.leadTo-content .el-form--inline .el-form-item {
  margin-right: 0;
  margin-bottom: 12px;
}
.page_box .el-table th.el-table__cell {
  background: #f5f7fa;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-table .cell {
  white-space: nowrap !important;
  width: fit-content !important;
}
.dotted-line {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  margin: 0 auto;
  background: url("../../assets/image/dotted-line.png") no-repeat center;
  background-size: 100%;
  /* margin: -40px 20px 22.5px 2px; */
}
.el-form-item {
  margin-bottom: 10px;
}
.leadto .el-dialog {
  margin-top: 2vh !important;
}
</style>