import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/HomePage'; 
import Register from '@/components/Register';
// import ContentBody from '@/components/ContentBody';
import Thread from '@/view/CRM/Thread' //线索
import ThreadTotal from '@/view/CRM/ThreadTotal' //线索统计
import ClientTotal from '@/view/CRM/ClientTotal' //客户统计
import DemandTotal from '@/view/CRM/DemandTotal' //需求统计
import ThreadedTree from '@/view/CRM/ThreadedTree' //线索池
import MenberManagement from '@/view/system/MenberManagement' //成员管理
import Divisional from '@/view/system/Divisional' //部门管理
import Rolemanagement from '@/view/system/Rolemanagement' //角色管理
import Management from '@/view/system/Management' //菜单管理
import followUpRecord from '@/view/CRM/followUpRecord' //跟进记录
import EntryLog from '@/view/system/EntryLog' // 登录日志
import LoperationLog from '@/view/system/LoperationLog' // 操作日志
import LeadImportAssignment from '@/view/CRM/LeadImportAssignment' // 线索导入/分配
import Client from '@/view/CRM/Client' // 客户
import SubmissionReview from '@/view/CRM/SubmissionReview' //报备审核
import permissionSetting from '@/view/system/permissionSetting' // 权限设置
import AddTask from '@/components/AddTask'
// 路由导航
Vue.use(Router);

const router = new Router({
      routes:[
          {
              path:'/',
              name:'登录',
              component: Register
          },
          {
              path:'/home',
              name:'首页',
              component:Home,
              children:[
                  {
                      path:'/thread',
                      name:'线索',
                      component:Thread
                  },
                  {
                      path: '/threadtotal',
                      name: '线索统计',
                      component: ThreadTotal
                  },
                  {
                      path: '/clenttotal',
                      name: '客户统计',
                      component: ClientTotal
                  },
                  {
                      path: '/demandtotal',
                      name: '需求统计',
                      component: DemandTotal
                  },
                  {   path:'/threadedtree',
                      name:"线索池",
                      component:ThreadedTree,
                  },
                  {
                      path:'/menbermanagement',
                      name:'成员管理',
                      component:MenberManagement
                  },
                  {
                       path: '/divisional',
                       name:'部门管理',
                       component: Divisional
                  },
                  {
                       path:'/rolemanagement',
                       name:'角色管理',
                       component:Rolemanagement
                  },
                  {
                      path:'/management',
                      name:"菜单管理",
                      component: Management
                  },
                  {
                      path:'/entrylog',
                      name:'登录日志',
                      component: EntryLog
                  },
                  {
                      path:'/loperationLog',
                      name:'操作日志',
                      component: LoperationLog
                  },
                  {
                      path:'/followUpRecord',
                      name:'跟进记录',
                      component: followUpRecord
                  },
                  {
                      path:'/leadImportAssignment',
                      name:'线索导入/分配',
                      component: LeadImportAssignment
                  },
                  {
                      path: '/client',
                      name:'客户',
                      component: Client
                  },
                  {
                      path: '/submissionReview',
                      name: '需求',
                      component: SubmissionReview
                  },
                  {
                      path: '/permissionSetting',
                      name: '权限设置',
                      component: permissionSetting
                  }
                 
              ]
          }
      ]
})

export default router;
