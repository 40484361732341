<template>
  <div class="page_box">
    <page-title-bar :title="title"></page-title-bar>
    <div class="bd-bar">
      <div class="menu-add">
        <el-button type="primary" @click.stop="newMenu">新增菜单</el-button>
      </div>
      <div class="table_page" v-loading="loading">
        <el-table
          :data="tableData"
          style="width: 100%"
          row-key="id"
          :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
          v-if="tableData.length > 0"
        >
          <ex-table-column
            prop="menuName"
            label="菜单名称"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          />
          <ex-table-column
            label="类型"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag type="success">
                {{
                  scope.row.type == 1
                    ? "目录"
                    : scope.row.type == 2
                    ? "菜单"
                    : "功能"
                }}
              </el-tag>
            </template>
          </ex-table-column>
          <ex-table-column
            label="排序"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="">{{ scope.row.sort }}</div>
            </template>
          </ex-table-column>
          <ex-table-column
            label="所属终端"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="" v-if="scope.row.source == 1">小程序+PC</div>
              <div style="" v-else-if="scope.row.source == 2">小程序</div>
              <div style="" v-else>PC</div>
            </template>
          </ex-table-column>
          <ex-table-column
            label="菜单是否可见"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div style="" v-if="scope.row.status == 1">是</div>
              <div style="" v-else>否</div>
            </template>
          </ex-table-column>
          <ex-table-column
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
            fixed="right"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleuserEdit(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="handleuserDelete(scope.row)"
              >
                删除
              </el-button>
            </template>
          </ex-table-column>
        </el-table>
        <el-table
          :data="tableData"
          style="width: 100%"
          row-key="id"
          :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
          v-loading="loading"
          v-else
        >
          <el-table-column prop="menu_name" label="菜单名称" min-width="200" />
          <el-table-column label="类型" min-width="300">
            <template slot-scope="scope">
              <el-tag type="success">
                {{
                  scope.row.type == 1
                    ? "页面"
                    : scope.row.type == 2
                    ? "按钮"
                    : "接口"
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="300">
            <template slot-scope="scope">
              <div style="color: #e64547" v-if="scope.row.status == 1">
                开启
              </div>
              <div style="color: #23345f" v-else>关闭</div>
            </template>
          </el-table-column>
          <el-table-column label="所属终端" min-width="300">
            <template slot-scope="scope">
              <div style="color: #e64547" v-if="scope.row.status == 1">
                开启
              </div>
              <div style="color: #23345f" v-else>关闭</div>
            </template>
          </el-table-column>
          <el-table-column label="菜单是否可见" min-width="300">
            <template slot-scope="scope">
              <div style="color: #e64547" v-if="scope.row.status == 1">
                开启
              </div>
              <div style="color: #23345f" v-else>关闭</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="200">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleuserEdit(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click="handleuserDelete(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 修改密码 -->
    <el-dialog
      class="batch-dialog n3"
      :title="batchForm.typeNumber ? '编辑菜单' : '新增菜单'"
      :visible.sync="batchDialog"
      :close-on-click-modal="false"
      width="624px"
      @close="closeCheck('batchForm')"
    >
      <div class="dialog-cont">
        <el-form
          ref="batchForm"
          :inline="true"
          :model="batchForm"
          :rules="batchRules"
          class="batchForm3"
        >
          <el-form-item
            label="菜单类型"
            prop="menuType"
            label-width="130px"
            style="margin-top: 30px"
          >
            <template>
              <el-radio-group v-model="batchForm.menuType">
                <el-radio
                  :label="item.value"
                  v-for="item in menuType_list"
                  :key="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </template>
          </el-form-item>
          <el-form-item label="上级菜单" prop="newPwd" label-width="130px">
            <!-- <el-input
              style="width: 434px"
              show-password
              autocomplete="new-password"
              v-model="batchForm.newPwd"
              placeholder="请选择"
              show-word-limit
            ></el-input> -->
            <el-cascader
              :props="{ checkStrictly: true }"
              v-model="batchForm.newPwd"
              :options="roleid"
              :key="countindex"
              @change="optionlist"
              style="width: 434px"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="菜单名称" prop="menuname" label-width="130px">
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.menuname"
              placeholder="请输入内容"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="所属终端"
            prop="terminalType"
            label-width="130px"
            v-if="batchForm.menuType != 3"
          >
            <template>
              <el-radio-group v-model="batchForm.terminalType">
                <el-radio
                  :label="item.value"
                  v-for="item in terminal_list"
                  :key="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </template>
          </el-form-item>
          <el-form-item
            label="icon地址"
            prop="icon"
            label-width="130px"
            v-if="batchForm.menuType == 1 || batchForm.menuType == 2"
          >
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.icon"
              placeholder="请输入icon图片地址"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="小程序端路径"
            prop="icon"
            label-width="130px"
            v-if="
              (batchForm.menuType == 2 && batchForm.terminalType == 1) ||
              (batchForm.menuType == 2 && batchForm.terminalType == 2)
            "
          >
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.appletsEndPath"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="PC端路径"
            prop="icon"
            label-width="130px"
            v-if="
              (batchForm.menuType == 2 && batchForm.terminalType == 3) ||
              (batchForm.menuType == 2 && batchForm.terminalType == 1)
            "
          >
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.PcPath"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="接口地址"
            prop="icon"
            label-width="130px"
            v-if="batchForm.menuType != 1"
          >
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.InterfaceAddress"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort" label-width="130px">
            <el-input
              style="width: 434px"
              autocomplete="new-password"
              v-model="batchForm.sort"
              placeholder="请输入"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单是否可见" prop="" label-width="130px">
            <template>
              <el-radio-group v-model="batchForm.status">
                <el-radio
                  :label="item.value"
                  v-for="item in menubar_list"
                  :key="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </template>
          </el-form-item>
        </el-form>
      </div>
      <div :class="`btn-group flex-box flex-box-end flex-v-ce`">
        <div class="right">
          <el-button @click="cancel('batchForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="applyBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            v-if="batchForm.typeNumber != 1"
            >确定
          </el-button>
          <el-button
            type="primary"
            :loading="isApply"
            @click="editBatch('batchForm')"
            style="margin-left: 20px"
            v-preventReClick="3000"
            v-if="batchForm.typeNumber == 1"
            >确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTitleBar from "@/components/page-title-bar.vue";
import {
  permissionList,
  addPermission,
  munetDelete,
  editPermission,
} from "@/api/report";
let options = [];
for (var i = 0; i < 10; i++) {
  let obj = {};
  options.push(obj);
}
export default {
  inject: ["getUsermenu"],
  components: { pageTitleBar },
  data() {
    return {
      countindex: 0,
      roleid: [],
      title: "菜单管理",
      loading: false,
      tableData: [
        // {
        //   id: 10533,
        //   menu_name: "线索",
        //   parent_id: 10001,
        //   path: "member",
        //   status: 1,
        //   type: 1,
        //   children: [
        //     {
        //       id: 1061 + i,
        //       menu_name: "线索管理",
        //       parent_id: 10001,
        //       path: "member",
        //       status: 1,
        //       type: 1,
        //       children: [
        //         {
        //           id: 1054 + i,
        //           menu_name: "新增",
        //           parent_id: 10001,
        //           path: "member",
        //           status: 1,
        //           type: 1,
        //         },
        //         {
        //           id: 1054 + i,
        //           menu_name: "编辑",
        //           parent_id: 10001,
        //           path: "member",
        //           status: 1,
        //           type: 1,
        //         },
        //         {
        //           id: 1054 + i,
        //           menu_name: "删除",
        //           parent_id: 10001,
        //           path: "member",
        //           status: 1,
        //           type: 1,
        //         },
        //       ],
        //     },
        //     {
        //       id: 1064 + i,
        //       menu_name: "线索池",
        //       parent_id: 10001,
        //       path: "member",
        //       status: 1,
        //       type: 1,
        //     },
        //   ],
        //   id: 1000 + i,
        //   menu_name: "客户管理",
        //   parent_id: 0,
        //   path: "/member",
        //   status: 1,
        //   type: 1,
        // },
        // {
        //   id: 10533,
        //   menu_name: "成员管理",
        //   parent_id: 10001,
        //   path: "member",
        //   status: 1,
        //   type: 1,
        //   children: [
        //     {
        //       id: 10534,
        //       menu_name: "新增成员",
        //       parent_id: 10001,
        //       path: "member",
        //       status: 1,
        //       type: 1,
        //     },
        //   ],
        // },
      ],
      batchDialog: false,
      isApply: false,
      batchForm: {
        menuType: "",
        newPwd: [],
        menuname: "",
        terminalType: "",
        menuType: 1,
        menubarType: "",
        status: "",
        sort: "",
        icon: "",
        appletsEndPath: "",
        InterfaceAddress: "",
        PcPath: "",
        typeNumber: 0,
        Id: "",
      },
      menuType_list: [
        { value: 1, label: "目录" },
        { value: 2, label: "菜单" },
        { value: 3, label: "功能" },
      ],
      terminal_list: [
        { value: 1, label: "小程序+PC" },
        { value: 2, label: "小程序" },
        { value: 3, label: "PC" },
      ],
      menubar_list: [
        { value: 1, label: "是" },
        { value: 2, label: "否" },
      ],
      batchRules: {
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    let data = [
      {
        children: [
          {
            id: 10619,
            menu_name: "用户列表",
            parent_id: 10001,
            path: "member",
            status: 1,
            type: 1,
          },
        ],
        id: 10001,
        menu_name: "会员管理",
        parent_id: 0,
        path: "/member",
        status: 1,
        type: 1,
      },
    ];

    this.getData();
  },
  methods: {
    // 菜单管理
    closeCheck(formName) {
      this.$refs[formName].resetFields();
      this.batchForm = {
        menuType: "",
        newPwd: [],
        menuname: "",
        terminalType: "",
        menuType: 1,
        menubarType: "",
        status: "",
        sort: "",
        icon: "",
        appletsEndPath: "",
        InterfaceAddress: "",
        PcPath: "",
        typeNumber: 0,
        Id: "",
      };
    },
    cancel(form) {
      this.batchDialog = false;
    },
    // 新增菜单
    async applyBatch(form) {
      let _this = this;
      _this.isApply = true;
      console.log(_this.batchForm.newPwd);
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            menuName: _this.batchForm.menuname,
            type: _this.batchForm.menuType,
            status: _this.batchForm.status,
            sort: _this.batchForm.sort,
            parentId: _this.batchForm.newPwd[_this.batchForm.newPwd.length - 1],
            miniUrl: _this.batchForm.appletsEndPath,
            pcUrl: _this.batchForm.PcPath,
            methodPath: _this.batchForm.InterfaceAddress,
            icon: _this.batchForm.icon,
          };
          if (_this.batchForm.terminalType) {
            params.source = _this.batchForm.terminalType;
          }
          try {
            let response = await addPermission(params);
            if (response.statusCode == 200) {
              _this.getData();
              _this.batchDialog = false;
              _this.getUsermenu();
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
            _this.isApply = false;
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 编辑菜单
    async editBatch(form) {
      let _this = this;
      _this.isApply = true;
      console.log(_this.batchForm.newPwd);
      _this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {
            menuName: _this.batchForm.menuname,
            type: _this.batchForm.menuType,
            status: _this.batchForm.status,
            sort: _this.batchForm.sort,
            source: _this.batchForm.terminalType,
            parentId: _this.batchForm.newPwd[_this.batchForm.newPwd.length - 1],
            miniUrl: _this.batchForm.appletsEndPath,
            pcUrl: _this.batchForm.PcPath,
            methodPath: _this.batchForm.InterfaceAddress,
            icon: _this.batchForm.icon,
          };
          if (_this.batchForm.typeNumber && _this.batchForm.Id) {
            params.id = _this.batchForm.Id;
          }
          try {
            let response = await editPermission(params);
            if (response.statusCode == 200) {
              _this.getData();
              _this.batchDialog = false;
              _this.getUsermenu();
            } else {
              _this.$message({
                type: "error",
                message: response.errors,
              });
            }
            _this.isApply = false;
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    newMenu() {
      this.batchDialog = true;
      this.batchForm.typeNumber = 0;
    },
    // 获取菜单管理数据
    async getData() {
      let _this = this;
      let params = {};
      // try {
      _this.loading = true;
      // let response = await permissionList(params);
      _this.$ajax({
        url: _this.globalData.orgHost + "permission/list",
        method: "get",
        data: params,
        success(res) {
          if (res.statusCode == 200) {
            _this.tableData = res.data;
            let new_data = [];
            for (let item of res.data) {
              item.value = item.id;
              item.label = item.menuName;
              item.children = item.child;
              if (item.children) {
                for (let it of item.children) {
                  it.value = it.id;
                  it.label = it.menuName;
                  it.children = "";
                }
              }
              new_data.push(item);
            }
            _this.roleid = new_data;
            console.log("获取上级菜单", _this.roleid);
          } else {
            _this.$message({
              type: "error",
              message: res.errors,
            });
          }
          _this.loading = false;
          _this.selectionItem = [];
        },
      });
      //   if (response.statusCode == 200) {
      //     _this.tableData = response.data;
      //     let new_data = [];
      //     for (let item of response.data) {
      //       item.value = item.id;
      //       item.label = item.menuName;
      //       item.children = item.child;
      //       if (item.children) {
      //         for (let it of item.children) {
      //           it.value = it.id;
      //           it.label = it.menuName;
      //           it.children = "";
      //         }
      //       }
      //       new_data.push(item);
      //     }
      //     setTimeout(() => {
      //       _this.loading = false;
      //     }, 1000);

      //     _this.roleid = new_data;
      //     console.log("获取上级菜单", _this.roleid);
      //   } else {
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 编辑菜单
    handleuserEdit(row) {
      console.log(row);
      this.batchDialog = true;
      this.batchForm.menuname = row.menuName;
      this.batchForm.menuType = row.type;
      this.batchForm.sort = row.sort;
      this.batchForm.terminalType = row.source;
      this.batchForm.typeNumber = 1;
      this.batchForm.Id = row.id;
      this.batchForm.PcPath = row.pcUrl;
      this.batchForm.newPwd = [row.parentId];
      this.batchForm.icon = row.icon;
      this.batchForm.InterfaceAddress = row.methodPath;
      this.batchForm.appletsEndPath = row.miniUrl;
      this.batchForm.status = row.status;
    },
    // 上级菜单
    optionlist(row) {
      console.log(row);
    },
    // 删除菜单
    handleuserDelete(row) {
      console.log(row);
      let _this = this;
      let params = {
        id: row.id,
      };
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let response = await munetDelete(params);

          if (response.statusCode == 200) {
            _this.$message({
              type: "success",
              message: "删除成功",
            });
            _this.getData();
            _this.getUsermenu();
          } else {
            _this.$message({
              type: "error",
              message: response.errors,
            });
          }
          console.log(response);
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.menu-add {
  padding: 20px 0px;
}
.bd-bar {
  padding: 20px;
}
.btn-group {
  padding: 12px 30px;
  -webkit-box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  box-shadow: 0 0 20px 0 RGB(51 51 51/20%);
  border-radius: 0 0 4px 4px;
}
</style>