import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
      state: {
            Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
            database: localStorage.getItem('database') ? localStorage.getItem('database') : '',
            tab: {
                  currentMenu: null,
                  tabsList: []
                  // tabsList: [{
                  //       active: "",
                  //       bList: Array[0],
                  //       icoUrl: "null",
                  //       isVisible: true,
                  //       name: "首页",
                  //       pList: "null",
                  //       pageUrl: "/home",
                  //       parentIndex: 0,
                  //       path: "/home",
                  //       sort: 0
                  // }],
            },
            table_height() {
                  return innerHeight - 410 + 'px';
              },

      },
      mutations: {
            changeLogin(state, user) {
                  state.Authorization = user.Authorization;
                  localStorage.setItem('Authorization', user.Authorization);
            },
            changeUserName(state, user) {
                  state.database = user.userName;;
                  localStorage.setItem('database', user.userName);
                  console.log(user);
            },
            //选择标签
            selectMenu(state, val) {
                  if (val.name === 'home') {
                        state.tab.currentMenu = null
                  } else {
                        state.tab.currentMenu = val;
                        //如果等于-1说明tabsList不存在那么插入，否则什么都不做
                        console.log('state.tabsList:', state.tab.tabsList)
                        let result = state.tab.tabsList.findIndex(item => item.menuName === val.menuName)
                        result === -1 ? state.tab.tabsList.push(val) : ''

                  }
            },
            //关闭标签
            closeTab(state, val) {
                  let result = state.tab.tabsList.findIndex(item => item.menuName === val.menuName)
                  state.tab.tabsList.splice(result, 1)
            },
      },
      getters: {

      },
      actions: {

      }
})

export default store