<template>
  <div class="page_box">
    <page-title-bar :title="title"></page-title-bar>
    <div class="bd-bar">
      <el-form
        ref="SearchForm"
        :inline="true"
        :model="form"
        label-width="80px"
        class="search-bar_n"
      >
        <div class="bd-bar2">
          <span class="bd-name">搜索：</span>
          <el-form-item label="" prop="type">
            <el-select
              v-model="form.type"
              style="width: 120px"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="operateType">
            <el-select
              v-model="form.operateType"
              style="width: 120px"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="(item, index) in options2"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="taskTitle">
            <el-input
              size="small"
              style="width: 200px"
              v-model="form.taskTitle"
              placeholder="操作人"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="85px" label="操作时间段" prop="auditDate">
            <el-date-picker
              v-model="form.auditDate"
              size="small"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="inquire"
              >查询</el-button
            >
            <el-button size="small" @click="reset('SearchForm')"
              >重置</el-button
            >
          </el-form-item>
          <!-- <el-form-item style="float: right" v-if="tabStatus == 1">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click.stop="addTask"
              >新增任务</el-button
            >
          </el-form-item> -->
        </div>
      </el-form>
      <div class="table_page">
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-loading="tabLoading"
          v-if="advanceDataList.length > 0"
        >
          <ex-table-column
            key="2"
            prop="name"
            label="操作人"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="3"
            prop="mobile"
            label="操作时间"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="4"
            prop="companyName"
            label="所属模块"
            align="left"
            :autoFit="true"
            :fitHeader="true"
          >
          </ex-table-column>
          <ex-table-column
            key="5"
            prop="reward"
            label="操作行为"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="6"
            prop="loginLocation"
            label="操作对象"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
          <ex-table-column
            key="7"
            prop="upStatus"
            label="操作详情"
            align="left"
            :autoFit="true"
            :fitHeader="true"
            show-overflow-tooltip
          >
          </ex-table-column>
        </el-table>
        <el-table
          id="table"
          :data="advanceDataList"
          style="width: 100%"
          ref="advanceDataList"
          tooltip-effect="dark"
          v-loading="tabLoading"
          v-else
        >
          <el-table-column
            key="16"
            prop=""
            label="操作人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="17"
            prop="title"
            label="操作时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="type"
            label="所属模块"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="reward"
            label="操作行为"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="20"
            label="操作对象"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="orderNum"
            label="操作详情"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="this.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import pageTitleBar from "@/components/page-title-bar.vue";
let optionsall = [];
for (let i = 0; i < 10; i++) {
  optionsall.push({
    name: "张三",
    mobile: "2023-06-19 13:42:11",
    companyName: "线索",
    reward: "新增",
    upStatus:
      "客户名称由“XX”修改为“XX”；联系人手机号由“18215458448”修改为“2548”",
    orderNum: "成功",
    checkNum: "电话跟进",
    loginLocation: "花样百出",
    checkContent: "未联系上",
    order_sum_reward: "2022-06-09 14:50:55",
    follow_up_time: "2022-06-11",
    remark: "花样百出",
    allocation_time: "2022-06-07 14:50:55",
  });
}
export default {
  inject: ["reload"],
  components: { pageTitleBar },
  data() {
    return {
      title: "操作日志",
      tabStatus: "1",
      page: 1,
      perPage: 10,
      tabLoading: false,
      count: 0,
      statusType: [
        {
          label: "待跟进的",
          value: "1",
        },
        {
          label: "已转客户",
          value: "2",
        },
      ],
      options: [
        {
          label: "所属模块",
          value: "1",
        },
        {
          label: "线索",
          value: "2",
        },
        {
          label: "客户",
          value: "3",
        },
        {
          label: "商机",
          value: "3",
        },
      ],
      options2: [
        {
          label: "操作行为",
          value: "1",
        },
        {
          label: "新增",
          value: "2",
        },
        {
          label: "编辑",
          value: "3",
        },
        {
          label: "删除",
          value: "3",
        },
      ],
      form: {
        taskTitle: "",
        type: "1",
        operateType: "1",
        auditDate: [],
      },
      advanceDataList: optionsall,
      selectionItem: [],
    };
  },
  methods: {
    MenuMore(flag) {
      console.log(flag);
    },
    handleClick() {},
    // 查询数据
    inquire() {},
    handleSizeChange() {},
    handleCurrentChange() {},
    // 多选项目
    handleSelectionChange(val) {
      this.selectionItem = val;
      console.log(val);
    },
  },
};
</script>

<style scoped>
.bd-bar {
  padding: 20px 20px 0;
  background-color: #fff;
}
.table_page .page {
  padding-bottom: 10px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.search-bar_n {
  padding: 20px 0;
  margin-bottom: 0;
}
</style>
<style>
.page_box .el-table th.el-table__cell {
  background: #f5f7fa;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-table .cell {
  white-space: nowrap !important;
  width: fit-content !important;
}
.dotted-line {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  margin: 0 auto;
  background: url("../../assets/image/dotted-line.png") no-repeat center;
  background-size: 100%;
  /* margin: -40px 20px 22.5px 2px; */
}
.el-form-item {
  margin-bottom: 10px;
}
</style>